import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import PageLoader from 'components/PageLoader';
import useCurrentUser from 'hooks/useCurrentUser';
import { composeHierarchy } from './utils';
import { roleToText } from 'helpers/role';
import { useGetClientAccountRoles } from 'actions/roles';
import { useParams, useNavigate } from 'react-router-dom';
import { ActivitySection } from './ActivitySection';
import { SubscriptionsTable } from './Subscriptions';
import { CertificationsTable } from './Certifications';
import { TrainingsTable } from './Trainings';
import { useQueryClient } from '@tanstack/react-query';
import { AccountMember } from 'types/api/AccountMember';
import { Input } from 'components/Organization/Input';
import { IconButton } from 'components/Organization/IconButton';
import { Select, SelectOption } from 'components/Organization/Select';
import { EditableField } from 'components/Organization/EditableField';
import { updateAccountMember, useGetAccountMember } from 'actions/accounts';
import useTimestamp from 'hooks/useTimestamp';

import {
  HierarchyInput,
  useAccountHierarchy
} from 'components/Organization/Hierarchy';

interface ViewMemberProps {
  /** Current account ID. */
  accountId: number;

  /** Account member data. */
  user: AccountMember;

  /** List of roles formatted for select component. */
  roles: SelectOption[];
}

function ViewMember({ accountId, user, roles }: ViewMemberProps) {
  const queryClient = useQueryClient();
  const hierarchy = useAccountHierarchy(accountId);
  const [timestamp, refreshTimestamp] = useTimestamp();
  const navigate = useNavigate();

  const handleFieldSave = async (field: string, value: string | number[]) => {
    const response = await updateAccountMember(accountId, user.user_id, {
      account_user: { [field]: value }
    });

    queryClient.setQueryData(
      ['ca', 'accounts', accountId, 'users', user.user_id],
      response.data
    );

    refreshTimestamp();
  };

  return (
    <div className="px-4 md:px-6 xl:px-10 max-w-7xl mx-auto text-black">
      <div className="font-sans pt-12 pb-4 flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        <div className="flex items-center gap-2">
          <IconButton
            label={i18n.ft(messages.goBack)}
            icon="fa-solid fa-arrow-left"
            onClick={() => navigate(-1)}
          />

          <h2 className="font-sans text-2xl sm:text-3xl">{user.user}</h2>
        </div>

        {user.last_login && (
          <div>
            <span className="font-semibold">
              {i18n.ft(messages.lastLogin) + ': '}
            </span>
            <span>{moment(user.last_login).format('MM/DD/YYYY')}</span>
          </div>
        )}
      </div>

      <div className="font-sans bg-white p-6 rounded-2xl">
        <div className="font-bold text-xl md:text-2xl">
          {i18n.ft(messages.basicInformation)}
        </div>

        <div className="mt-4 grid sm:grid-cols-[_1fr_auto_1fr]">
          <div>
            <EditableField
              border={false}
              label={i18n.ft(messages.email)}
              value={user.email}
            />

            <EditableField
              value={user.external_id}
              label={i18n.ft(messages.externalId)}
              initialValue={user.external_id ?? ''}
              onSave={value => handleFieldSave('external_id', value)}
              control={({ value, setValue }) => (
                <Input
                  value={value}
                  label={i18n.ft(messages.externalId)}
                  onChange={event => setValue(event.target.value)}
                />
              )}
            />

            <EditableField
              label={i18n.ft(messages.role)}
              value={roleToText(user.role)}
              initialValue={user.role_id}
              onSave={value => handleFieldSave('role_id', value)}
              control={({ value, setValue }) => (
                <Select
                  value={value}
                  options={roles}
                  onChange={setValue}
                  label={i18n.ft(messages.role)}
                  placeholder={i18n.ft(messages.rolePlaceholder)}
                />
              )}
            />
          </div>

          <div className="hidden sm:block my-6 mx-8 border-l border-[#D7DFE8]" />

          <div>
            <EditableField
              className="sm:border-0"
              label={i18n.ft(messages.addedOn)}
              value={moment(user.added_on).format('MM/DD/YYYY')}
            />

            <EditableField
              className={user.hierarchy_present ? '' : 'border-b'}
              label={i18n.ft(messages.addedBy)}
              value={user.added_by || i18n.ft(messages.unknown)}
            />

            {user.hierarchy_present && (
              <EditableField
                key={timestamp}
                label={i18n.ft(messages.hierarchy)}
                value={composeHierarchy(user.hierarchy)}
                initialValue={user.node_ids}
                onSave={value => handleFieldSave('node_ids', value)}
                control={
                  user.role === 'ca_admin'
                    ? undefined
                    : ({ value, setValue }) => (
                        <HierarchyInput
                          {...hierarchy}
                          value={value}
                          onChange={setValue}
                          label={i18n.ft(messages.hierarchy)}
                          placeholder={i18n.ft(messages.hierarchyPlaceholder)}
                        />
                      )
                }
              />
            )}
          </div>
        </div>
      </div>

      <SubscriptionsTable userId={user.user_id} />
      <CertificationsTable userId={user.user_id} />
      <TrainingsTable userId={user.user_id} />
      <ActivitySection key={timestamp} accountId={accountId} user={user} />
    </div>
  );
}

function ViewMemberController() {
  const params = useParams();
  const memberId = Number(params.memberId);
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;

  const { data: memberData, isSuccess: memberIsSuccess } = useGetAccountMember(
    accountId,
    memberId
  );

  const { data, isSuccess: rolesIsSuccess } =
    useGetClientAccountRoles(accountId);

  if (!memberIsSuccess || !rolesIsSuccess) {
    return <PageLoader />;
  }

  const roles = data.map(role => ({
    value: role.id,
    text: roleToText(role.name)
  }));

  return <ViewMember accountId={accountId} user={memberData} roles={roles} />;
}

export default ViewMemberController;
