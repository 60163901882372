const PATH = 'fe.components.organization.status_badge';

const messages = {
  accepted: {
    key: `${PATH}.accepted`,
    defaultValue: 'Accepted'
  },
  invited: {
    key: `${PATH}.invited`,
    defaultValue: 'Invited'
  },
  noInvitation: {
    key: `${PATH}.no_invitation`,
    defaultValue: 'No Invitation'
  }
};

export default messages;
