import i18n from 'helpers/i18n';
import messages from './messages';
import { clsx } from 'clsx';
import { Field, Label } from '@headlessui/react';
import { Checkbox } from 'components/Organization/Checkbox';
import { Popover, PopoverTrigger, PopoverContent } from './Popover';

type Option = {
  value: any;
  label: string;
};

interface ColumnFilterProps {
  /** Column name. */
  name: string;

  /** Selected values. */
  values: any[];

  /** Callback fired when the values change. */
  onChange: (values: any[]) => void;

  /** Options to display. */
  options: Option[];
}

export function ColumnFilter({
  name,
  values,
  onChange,
  options
}: ColumnFilterProps) {
  const handleCheckboxChange = (value: string) => {
    if (values.includes(value)) {
      onChange(values.filter(v => v !== value));
    } else {
      onChange([...values, value]);
    }
  };

  const isActive = values.length > 0;

  const buttonClasses = clsx(
    'font-sans font-semibold flex items-center gap-2 -ml-2 p-2 hover:bg-action-tint rounded-sm',
    isActive && 'bg-action-tint text-action'
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className={buttonClasses}>
          <span>{name}</span>
          <span className="w-4 h-4 flex items-center justify-center">
            <i className="fa-solid fa-bars-filter" />
          </span>
          {isActive && <span>{`(${values.length})`}</span>}
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex flex-col gap-0.5">
          <div className="font-bold text-base p-2">
            {i18n.ft(messages.seeOnly) + ':'}
          </div>

          {options.map(option => {
            const isOptionActive = values.includes(option.value);

            const fieldClasses = clsx(
              'relative flex items-center gap-2 p-2 rounded-sm hover:bg-action-tint',
              isOptionActive && 'bg-action-tint font-semibold'
            );

            return (
              <Field key={option.value} className={fieldClasses}>
                <Checkbox
                  checked={isOptionActive}
                  onChange={() => handleCheckboxChange(option.value)}
                />
                <Label className="absolute inset-0 flex items-center pl-8">
                  {option.label}
                </Label>
              </Field>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
}
