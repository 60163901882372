import { forwardRef, useImperativeHandle } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { Icon } from 'semantic-ui-react';
import * as R from 'ramda';
import { useState, useEffect } from 'react';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { TextArea, StatusWrapper, TextAreaWrapper, StyledIcon } from './Styled';
import { SectionCollapse } from 'components/SectionCollapse';

import {
  ClickableArea,
  SectionWithBorder
} from 'components/Measure/View/Styled';

import {
  GetObservationFeedbackRes,
  getObservationFeedback,
  answerQuestion
} from 'actions/envScales/observationFeedback';

interface FeedbackSectionProps {
  observationId: number;
  targetType: TargetType;
  attached?: boolean;
}

type TargetType = 'dimensions' | 'domains';

export const FeedbackSection = forwardRef(
  ({ attached, observationId, targetType }: FeedbackSectionProps, ref) => {
    const { run, isPending } = useAsync();
    const { run: runAnswer } = useAsync();
    const [questionsExpanded, setQuestionsExpanded] = useState<boolean[]>([]);
    const [questions, setQuestions] = useState<GetObservationFeedbackRes>({
      questions_with_answers: []
    });
    const [sectionWithErrors, setSectionWithErrors] = useState<boolean | null>(
      null
    );
    const allAnswered = questions.questions_with_answers.every(
      q => q.answer_notes?.length
    );
    const payloadType = targetType === 'dimensions' ? 'Dimension' : 'Domain';

    useImperativeHandle(ref, () => ({
      valid() {
        return valid();
      }
    }));

    useEffect(() => {
      run(getObservationFeedback(observationId, payloadType), {
        onSuccess: data => {
          setQuestions(data);
          setQuestionsExpanded(
            new Array(data.questions_with_answers.length).fill(false)
          );
        }
      });
    }, [run, observationId, payloadType]);

    function updateQuestionsState(questionId: number, answer: string) {
      setQuestions(prevState => {
        const newState = prevState.questions_with_answers.map(obj =>
          obj.question_id === questionId
            ? { ...obj, answer_notes: answer }
            : obj
        );
        return { questions_with_answers: newState };
      });
    }

    function handleAnswer(event: any, questionId: number) {
      const answer = event.currentTarget.value;
      const payload = { notes: answer, target_type: payloadType };
      runAnswer(answerQuestion(observationId, questionId, payload));
    }

    function toggleExpandQuestion(index: number) {
      let newArr = [...questionsExpanded];
      newArr[index] = !questionsExpanded[index];
      setQuestionsExpanded(newArr);
    }

    function valid() {
      setSectionWithErrors(!allAnswered);
      return allAnswered;
    }

    if (R.isEmpty(questions.questions_with_answers)) {
      return null;
    }

    function getSectionStatus() {
      if (allAnswered) {
        return 'completed';
      }

      if (!allAnswered && sectionWithErrors !== null) {
        return 'error';
      }

      return 'pending';
    }

    return (
      <SectionCollapse
        title={i18n.ft(messages.titleFeedback)}
        subtitle={i18n.ft(messages.subtitle[targetType])}
        status={getSectionStatus()}
        attached={attached}
        closeOthersOnOpen
      >
        <div className="sm:px-14 sm:pb-5">
          {!isPending &&
            questions.questions_with_answers.map((record, index: number) => (
              <SectionWithBorder mb={'8px'} key={index}>
                <ClickableArea onClick={() => toggleExpandQuestion(index)}>
                  <Box mt="8px">
                    <Flex justify="space-between" align="center">
                      <div>{record.question}</div>
                      <StatusWrapper hasErrors={false}>
                        {i18n.ft(messages.answered)}
                        <Icon.Group size="small">
                          <StyledIcon
                            circular
                            color="green"
                            size="small"
                            name={record.answer_notes?.length ? 'check' : ''}
                            inverted={Boolean(record.answer_notes?.length)}
                          />
                        </Icon.Group>
                      </StatusWrapper>
                    </Flex>
                  </Box>
                </ClickableArea>

                <Box mt={questionsExpanded[index] ? '24px' : '8px'}>
                  <TextAreaWrapper expanded={questionsExpanded[index]}>
                    <TextArea
                      rows={4}
                      data-question-id={record.question_id}
                      onBlur={(event: any) =>
                        handleAnswer(event, record.question_id)
                      }
                      onChange={(event: any) =>
                        updateQuestionsState(
                          record.question_id,
                          event.currentTarget.value
                        )
                      }
                      defaultValue={record.answer_notes || ''}
                    />
                  </TextAreaWrapper>
                </Box>
              </SectionWithBorder>
            ))}
        </div>
      </SectionCollapse>
    );
  }
);
