import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import {
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE
} from 'pages/LearningResources/utils';

import {
  LearningResource,
  PaginatedLearningResources,
  PaginatedRecommentations,
  PaginatedLearningResourceWithPlaylists,
  InviteUserToAccountParams,
  LearningResourceSubscriptions,
  CompletedResources
} from 'types/api/LearningResource';

export function getLearningResources(params = {}) {
  return axios.get<PaginatedLearningResources>(`/api/v2/learning_resources`, {
    params: params
  });
}

export function getAllLearningResources(params = {}) {
  return axios.get<PaginatedLearningResourceWithPlaylists>(
    `/api/v2/learning_resources/search`,
    {
      params: params
    }
  );
}

export function getLearningResource(id: number) {
  return axios.get<LearningResource>(`/api/v2/learning_resources/${id}`);
}

export function getLastViewedLearningResources(
  perPage: number = DEFAULT_PER_PAGE,
  currentPage: number = DEFAULT_CURRENT_PAGE
) {
  return axios.get<PaginatedLearningResources>(
    `/api/v2/learning_resources/latest_viewed?per_page=${perPage}&page=${currentPage}`
  );
}

export function getLearningResourcesByCategory(params = {}, category: string) {
  return axios.get<PaginatedLearningResources>(
    `/api/v2/learning_resources/by_category?category_id=${category}`,
    {
      params: params
    }
  );
}

export function getLearningResourceCategories() {
  return axios.get(`/api/v2/tags/learning_resource_categories`);
}

export function getRecommendedLearningResources(params = {}) {
  return axios.get<PaginatedRecommentations>(`/api/v2/recommendations`, {
    params: params
  });
}

export function getPlaylistItems(playlistId: number) {
  return axios.get(`/api/v2/playlists/${playlistId}`);
}

export function completePlaylistTrack(
  playlistId: number,
  learningTrack: number
) {
  return axios.put(
    `/api/v2/playlists/${playlistId}/learning_tracks/${learningTrack}`
  );
}

export function startPlaylistTrack(playlistId: number) {
  return axios.post(`/api/v2/playlists/${playlistId}/start`);
}

export function getClassroomScores(
  educatorId: number | undefined,
  ageLevel: string | undefined,
  currentPage: number = DEFAULT_CURRENT_PAGE,
  perPage: number = DEFAULT_PER_PAGE
) {
  return axios.get(
    `/api/v2/learning_resources/improvements?q=&page=${currentPage}&per_page=${perPage}&age_level=${ageLevel}&educator_id=${educatorId}`
  );
}

export function getLearningResourceSubscription(accountId: number) {
  return axios.get<LearningResourceSubscriptions[]>(
    `/api/v2/learning_resources/subscriptions_by_account?account_id=${accountId}`
  );
}

export function useGetLearningResourceSubscription(accountId: number) {
  return useQuery({
    queryKey: ['subscriptions', accountId, 'subscriptions_by_account'],
    queryFn: () =>
      getLearningResourceSubscription(accountId).then(response => response.data)
  });
}

export function inviteUserToAccount({
  subscriptionId,
  params
}: InviteUserToAccountParams) {
  return axios.post(`/api/v2/memberships/invite`, {
    subscription_id: subscriptionId,
    ...params
  });
}

export function getLearningResourceMyWatchedResources() {
  return axios.get<CompletedResources>(
    `/api/v2/learning_resources/my_watched_resources`
  );
}
