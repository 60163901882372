import i18n from 'helpers/i18n';
import messages from './messages';

interface StatusBadgeProps {
  status: 'accepted' | 'invited' | 'no_invitation';
}

export function StatusBadge({ status }: StatusBadgeProps) {
  function getBGColor() {
    switch (status) {
      case 'accepted':
        return 'bg-lime-200';
      case 'invited':
        return 'bg-gold-200';
      default:
        return 'bg-orange-200';
    }
  }

  function getStatusText() {
    switch (status) {
      case 'accepted':
        return i18n.ft(messages.accepted);
      case 'invited':
        return i18n.ft(messages.invited);
      default:
        return i18n.ft(messages.noInvitation);
    }
  }

  return (
    <div
      className={`inline-flex items-center space-x-2 px-3 py-2 text-black text-sm rounded-full font-semibold ${getBGColor()}`}
    >
      <span className="w-5 h-5 flex items-center justify-center text-base">
        {status === 'accepted' ? (
          <i className="fa-solid fa-check" />
        ) : (
          <i className="fa-regular fa-envelope" />
        )}
      </span>
      <span>{getStatusText()}</span>
    </div>
  );
}
