const PATH = 'fe.pages.organization.subscriptions.subscription_card';

const messages = {
  expiresOn: {
    key: `${PATH}.expires_on`,
    defaultValue: 'Expires On'
  },
  seats: {
    key: `${PATH}.seats`,
    defaultValue: 'Seats'
  },
  available: {
    key: `${PATH}.available`,
    defaultValue: 'Available'
  },
  used: {
    key: `${PATH}.used`,
    defaultValue: 'Used'
  },
  total: {
    key: `${PATH}.total`,
    defaultValue: 'Total'
  },
  classrooms: {
    key: `${PATH}.classrooms`,
    defaultValue: 'Classrooms'
  }
};

export default messages;
