import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { Link } from 'react-router-dom';
import { roleToText } from 'helpers/role';
import { toTitleCase } from 'helpers/text';
import { Table } from 'components/Organization/Table';
import { StatusBadge } from 'components/Organization/StatusBadge';
import { useGetUserMembershipTrackers } from 'actions/membershipTrackers';

interface SubscriptionsTableProps {
  userId: number;
}

export function SubscriptionsTable({ userId }: SubscriptionsTableProps) {
  const { data, isLoading } = useGetUserMembershipTrackers(userId);
  const trackers = data?.membership_trackers ?? [];

  function getSubscriptionLink(subscriptionId: number) {
    return `/organization/subscriptions/${subscriptionId}/users`;
  }

  function formatDate(date?: string) {
    if (!date) {
      return '--';
    }

    return moment(date).format('L');
  }

  const rows = trackers.map(tracker => ({
    product_name: (
      <Link
        className="font-bold text-black underline hover:underline hover:text-purple-600"
        to={getSubscriptionLink(tracker.subscription_id)}
      >
        {toTitleCase(tracker.product_name)}
      </Link>
    ),
    role: roleToText(tracker.role),
    status: <StatusBadge status={tracker.status} />,
    enrolled_on: formatDate(tracker.enrolled_on),
    invited_on: formatDate(tracker.invited_on)
  }));

  return (
    <div className="font-sans bg-white p-6 rounded-2xl mt-6">
      <div className="font-bold text-xl md:text-2xl">
        {i18n.ft(messages.header)}
      </div>

      <div className="mt-10">
        <Table
          isLoading={isLoading}
          headers={[
            {
              accessor: 'product_name',
              content: i18n.ft(messages.productName)
            },
            { accessor: 'role', content: i18n.ft(messages.role) },
            { accessor: 'status', content: i18n.ft(messages.status) },
            { accessor: 'invited_on', content: i18n.ft(messages.invitedOn) },
            { accessor: 'enrolled_on', content: i18n.ft(messages.enrolledOn) }
          ]}
          data={rows}
        />
      </div>
    </div>
  );
}
