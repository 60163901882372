import i18n from 'helpers/i18n';
import messages from './messages';
import RichTextEditor from 'components/RichTextEditor';

interface NotesSectionProps {
  notes: string;
  title?: string;
}

function NotesSection({ notes, title }: NotesSectionProps) {
  if (!notes) {
    return <></>;
  }
  return (
    <>
      <div className="pt-4 text-xl mb-4">
        <span className="font-bold text-black">
          {title ? title : i18n.ft(messages.noticed)}
        </span>
      </div>
      <div className="text-black">
        <RichTextEditor readOnly value={notes} />
      </div>
    </>
  );
}

export default NotesSection;
