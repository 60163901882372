const PATH = 'fe.components.certifications.test_card';

const messages = {
  attempts: {
    key: `${PATH}.attempts`,
    defaultValue: 'Up to 3 attempts'
  },
  subtitleHeader: {
    key: `${PATH}.subtitle_header`,
    defaultValue: 'Available until'
  },
  availableUntil: {
    key: `${PATH}.available_until`,
    defaultValue: '{{available_until}}'
  },
  goToAttempt: {
    key: `${PATH}.go_to_attempt`,
    defaultValue: 'Go to Attempt {{number}}'
  },
  passed: {
    key: `${PATH}.passed`,
    defaultValue: 'Passed'
  },
  failed: {
    key: `${PATH}.failed`,
    defaultValue: 'Failed'
  },
  expired: {
    key: `${PATH}.expired`,
    defaultValue: 'Expired'
  },
  reportNumber: {
    key: `${PATH}.report_number`,
    defaultValue: 'Report {{number}}'
  },
  noAttempts: {
    key: `${PATH}.no_attempts`,
    defaultValue: 'No attempts completed'
  },
  viewInstructions: {
    key: `${PATH}.view_instructions`,
    defaultValue: 'View Test Instructions'
  }
};

export default messages;
