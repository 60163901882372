import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import moment from 'moment';

export function getTableColumns(
  isAgeLevelActive: boolean,
  columnsAvailable: any[]
) {
  const baseColumns = [{ header: i18n.ft(messages.name), accessor: 'name' }];
  let dynamicColumns: { header: string; accessor: string }[] = [];

  const filteredColumns = columnsAvailable.filter(column => {
    if (!isAgeLevelActive) {
      return (
        (column.checked && column.key === 'email') ||
        (column.checked && column.key === 'hierarchy')
      );
    } else {
      return column.checked;
    }
  });

  dynamicColumns = filteredColumns.map(column => ({
    header: column.name,
    accessor: column.key
  }));

  if (!isAgeLevelActive) {
    dynamicColumns.push({
      header: i18n.ft(messages.class2008),
      accessor: 'class_2008'
    });

    dynamicColumns.push({
      header: i18n.ft(messages.class2ndEdition),
      accessor: 'class_2nd_edition'
    });
  }

  return [...baseColumns, ...dynamicColumns];
}

export function formatAgeLevels(ageLevels: string[]) {
  return R.isEmpty(ageLevels) ? '--' : ageLevels.join(', ');
}

export function formatDate(date?: string | null) {
  if (!date) {
    return '--';
  }

  return moment.utc(date).format('MM/DD/YY');
}
