import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import { useMeasure } from 'context/measure';
import { Loader } from 'semantic-ui-react';
import {
  getCustomQuestions,
  GetCustomQuestionsRes
} from 'actions/envScales/observationFeedback';
import * as Styled from './Styled';

function CustomQuestions() {
  const { assessment } = useMeasure();
  const { run, data: questions, isPending } = useAsync<GetCustomQuestionsRes>();

  useEffect(() => {
    run(getCustomQuestions(assessment.id));
  }, [run, assessment.id]);

  if (isPending) {
    return <Loader active inline="centered" />;
  }

  const hasQuestions = questions?.questions_with_answers?.length > 0;

  return (
    <div>
      <div className="mt-2 pb-8 rounded-t-xl bg-white px-5 md:px-14">
        <div className="flex justify-between py-11 items-center">
          <Styled.SectionHeader>{i18n.ft(messages.title)}</Styled.SectionHeader>
        </div>
        {hasQuestions &&
          questions.questions_with_answers.map(question => (
            <Styled.Section mt="2px" key={question.question_id}>
              <Styled.Wrapper>
                <dl>
                  <dt>
                    <Styled.CustomQuestion>
                      {question.question}
                    </Styled.CustomQuestion>
                  </dt>
                  <dd>
                    <Styled.CustomQuestionAnswer>
                      {question.question_type === 'free_text'
                        ? question.answer_notes
                        : question.options.find(
                            opt => opt.id === question.answer_id
                          )?.option}
                    </Styled.CustomQuestionAnswer>
                  </dd>
                </dl>
              </Styled.Wrapper>
            </Styled.Section>
          ))}
      </div>
    </div>
  );
}

export default CustomQuestions;
