const PATH = 'fe.helpers.role';

const messages = {
  primaryAdmin: {
    key: `${PATH}.primary_admin`,
    defaultValue: 'Primary Admin'
  },
  administrator: {
    key: `${PATH}.administrator`,
    defaultValue: 'Administrator'
  },
  educator: {
    key: `${PATH}.educator`,
    defaultValue: 'Educator'
  },
  coach: {
    key: `${PATH}.coach`,
    defaultValue: 'Coach'
  }
};

export default messages;
