import i18n from 'helpers/i18n';
import messages from './messages';
import { useState, useEffect, useCallback } from 'react';
import ResourcesSection from '../ResourcesSection';
import useCurrentUser from 'hooks/useCurrentUser';
import DownArrow from 'images/arrow_drop_down.svg';
import QuestionHelp from 'images/questionHelp.svg';
import { Popup } from 'semantic-ui-react';
import { EducatorSettingsResponse } from '../types';
import PageLoader from 'components/PageLoader';
import {
  getEducatorSettings,
  updateEducatorSettings
} from 'actions/educatorSettings';
import { useNavigate } from 'react-router-dom';
import RouteHelpers from 'helpers/routes';
import SearchResults from '../SearchResults';
import { hasLearningResourcesSubscription } from 'components/LearningResources/utils';

export const SECTION = {
  RESOURCES: 'resources',
  RECOMMENDATIONS: 'recommendations',
  STARTED: 'get_started',
  IMPORTANT: 'import',
  CLASSROOM: 'based_on_classroom_score',
  HISTORY: 'based_on_history',
  COMPLETED: 'completed_resources'
};

interface DropdownMenuProps {
  query: string;
  setQuery: (query: string) => void;
}

function EducatorResourcesMenu({ query, setQuery }: DropdownMenuProps) {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [settings, setSettings] = useState<EducatorSettingsResponse>({
    language: '',
    age_levels: [],
    learning_resources_menus: ''
  });
  const [sectionSelected, setSectionSelected] = useState('get_started');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (isOpen && !target.closest('.dropdown')) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  const isCoachOnly = useCallback(() => {
    return (
      currentUser.roles.includes('lr_coach') &&
      !currentUser.roles.includes('lr_educator')
    );
  }, [currentUser.roles]);

  // NOTE: Users that don't have a learning resource subscription
  //       should be redirected to the home page
  useEffect(() => {
    if (!hasLearningResourcesSubscription(currentUser)) {
      navigate(RouteHelpers.getPath('app'));
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (isCoachOnly()) {
      return;
    }
    setIsFetching(true);
    getEducatorSettings()
      .then(response => {
        setSettings(response.data);
        const initialMenu = response.data.learning_resources_menus.find(
          (item: any) => item.selected
        )?.key;
        if (initialMenu) {
          setSectionSelected(initialMenu);
        } else {
          setSectionSelected(SECTION.STARTED);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsFetching(false);
      });
  }, [isCoachOnly]);

  const SelectedSection = useCallback(() => {
    setQuery('');
    switch (sectionSelected) {
      case SECTION.RESOURCES:
        return <ResourcesSection action={'myResources'} showTitle={false} />;
      case SECTION.RECOMMENDATIONS:
        return <ResourcesSection action={'recommended'} showTitle={false} />;
      case SECTION.STARTED:
        return <ResourcesSection action={'gettingStarted'} showTitle={false} />;
      case SECTION.IMPORTANT:
        return <ResourcesSection action={'important'} showTitle={false} />;
      case SECTION.HISTORY:
        return <ResourcesSection action={'viewingHistory'} showTitle={false} />;
      case SECTION.CLASSROOM:
        return (
          <ResourcesSection
            action={'classroomScores'}
            educatorId={currentUser.id}
            ageLevel="Pre-K"
            showTitle={false}
          />
        );
      case SECTION.COMPLETED:
        return (
          <ResourcesSection
            action={'completedResources'}
            educatorId={currentUser.id}
            showTitle={false}
          />
        );
      default:
        return <ResourcesSection action={'gettingStarted'} showTitle={false} />;
    }
  }, [currentUser.id, sectionSelected, setQuery]);

  const getSectionTitle = useCallback(() => {
    if (query) {
      return i18n.ft(messages.searchText, { query });
    }

    switch (sectionSelected) {
      case SECTION.RESOURCES:
        return i18n.ft(messages.resources);
      case SECTION.RECOMMENDATIONS:
        return i18n.ft(messages.recommendations);
      case SECTION.STARTED:
        return i18n.ft(messages.started);
      case SECTION.IMPORTANT:
        return i18n.ft(messages.important);
      case SECTION.HISTORY:
        return i18n.ft(messages.history);
      case SECTION.CLASSROOM:
        return i18n.ft(messages.classroom);
      case SECTION.COMPLETED:
        return i18n.ft(messages.completedResources);
      default:
        return i18n.ft(messages.started);
    }
  }, [query, sectionSelected]);

  function handleOnClick(key: string) {
    setQuery('');
    setIsOpen(false);
    setSectionSelected(key);
    if (!isCoachOnly()) {
      updateEducatorSettings({
        ...settings,
        learning_resources_menu: key
      });
    }
  }

  if (isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="dropdown relative inline-block text-left">
        <button
          onClick={toggleMenu}
          className="inline-flex pr-4 py-2 hover:bg-gray-50 focus:outline-none"
        >
          <h2 className="text-black">{getSectionTitle()}</h2>
          <span className="ml-2 self-center">
            <img src={DownArrow} alt="" />
          </span>
        </button>
        <span className="ml-2 self-center inline-block">
          <Popup
            content={
              <span>
                <p className="text-sm">{i18n.ft(messages.help)}</p>
              </span>
            }
            trigger={<img src={QuestionHelp} alt="" />}
            position="bottom left"
            flowing
          />
        </span>

        {isOpen && (
          <div className="min-w-[341px] absolute mt-2 rounded-md shadow-lg bg-white border-[#0A9CCA] border-2 ring-1 ring-black ring-opacity-5 z-20">
            <ul
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.RESOURCES ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.RESOURCES);
                }}
              >
                {i18n.ft(messages.resources)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.resourcesDescription)}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.RECOMMENDATIONS ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.RECOMMENDATIONS);
                }}
              >
                {i18n.ft(messages.recommendations)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.recommendationsDescription)}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.STARTED ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.STARTED);
                }}
              >
                {i18n.ft(messages.started)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.startedDescription)}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.IMPORTANT ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.IMPORTANT);
                }}
              >
                {i18n.ft(messages.important)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.importantDescription)}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.CLASSROOM ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.CLASSROOM);
                }}
              >
                {i18n.ft(messages.classroom)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.classroomDescription)}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.HISTORY ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.HISTORY);
                }}
              >
                {i18n.ft(messages.history)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.historyDescription)}
                </div>
              </li>
              <li
                className={`px-4 py-2 text-base text-gray-700 hover:bg-[#E6F5FA] hover:text-gray-900 cursor-pointer ${sectionSelected === SECTION.COMPLETED ? 'font-bold' : ''}`}
                onClick={() => {
                  handleOnClick(SECTION.COMPLETED);
                }}
              >
                {i18n.ft(messages.completedResources)}
                <div className="font-normal text-xs">
                  {i18n.ft(messages.completedDescription)}
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
      {query === '' ? (
        <SelectedSection />
      ) : (
        <SearchResults
          query={query}
          searchTitle={i18n.ft(messages.searchText, { query })}
        />
      )}
    </>
  );
}

export default EducatorResourcesMenu;
