import clsx from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useGetCoaches } from 'actions/coaches';
import { Label, Field } from '@headlessui/react';
import { useState, useRef, useEffect } from 'react';
import downArrow from 'images/arrow_drop_down.svg';
import closeIcon from 'images/learningResources/close_x_icon_black.svg';
import { useParams } from 'react-router-dom';

interface Coach {
  id: number;
  user_id?: number;
  name?: string;
  email: string;
}

interface CoachAutocompleteProps {
  value: string;
  handleChange: ({ email, id }: Coach) => void;
  placeholder?: string;
  onSelect?: (customer: Coach) => void;
}

function CoachAutocomplete(props: CoachAutocompleteProps) {
  const {
    value,
    handleChange,
    placeholder = i18n.ft(messages.placeholder),
    onSelect
  } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { id: subscriptionId } = useParams();

  const { data, isLoading } = useGetCoaches(
    Number(subscriptionId),
    'lr_coach',
    value
  );

  useEffect(() => {
    if (!isDropdownVisible) setSelectedIndex(-1);
  }, [isDropdownVisible]);

  const handleSelect = (user: Coach) => {
    handleChange(user);
    setIsDropdownVisible(false);
    setSelectedIndex(-1);
    onSelect?.(user);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isDropdownVisible || data?.subscription_users?.length === 0) return;
    e.preventDefault();

    switch (e.key) {
      case 'ArrowDown':
        setSelectedIndex(prevIndex => {
          const nextIndex =
            (prevIndex + 1) % (data?.subscription_users?.length || 1);
          scrollIntoView(nextIndex);
          return nextIndex;
        });
        break;
      case 'ArrowUp':
        setSelectedIndex(prevIndex => {
          const prev =
            prevIndex <= 0
              ? (data?.subscription_users?.length ?? 1) - 1
              : prevIndex - 1;
          scrollIntoView(prev);
          return prev;
        });
        break;
      case 'Enter':
        if (selectedIndex >= 0 && data?.subscription_users[selectedIndex]) {
          handleSelect(data.subscription_users[selectedIndex]);
        }
        break;
      case 'Escape':
        setIsDropdownVisible(false);
        break;
    }
  };

  const scrollIntoView = (index: number) => {
    if (dropdownRef.current) {
      const item = dropdownRef.current.children[index] as HTMLElement;
      item?.scrollIntoView({ block: 'nearest' });
    }
  };

  const handleClear = () => {
    handleChange({ email: '', id: 0 });
    setIsDropdownVisible(false);
    setSelectedIndex(-1);
  };

  const renderDropdownContent = () => {
    if (isLoading) {
      return (
        <div className="p-4 text-gray-500">{i18n.ft(messages.loading)}</div>
      );
    }

    if ((data?.subscription_users?.length ?? 0) > 0) {
      return data?.subscription_users.map((coach: Coach, index: number) => (
        <div
          key={coach.email}
          onClick={() => handleSelect(coach)}
          className={clsx('px-4 py-2 hover:bg-blue-100 cursor-pointer', {
            'bg-blue-100': index === selectedIndex
          })}
        >
          <div className="font-semibold">{coach.email}</div>
          <div className="text-sm font-normal">{coach.name}</div>
        </div>
      ));
    }

    return (
      <div className="p-4 text-gray-500">{i18n.ft(messages.noResults)}</div>
    );
  };

  return (
    <div className="w-full">
      <Field className="flex flex-col gap-1 font-sans">
        <Label className="font-semibold text-base text-black">
          {i18n.ft(messages.coach)}
        </Label>
        <div className="relative">
          <input
            type="text"
            readOnly
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            onChange={e => handleChange({ email: e.target.value, id: 0 })}
            onFocus={() => setIsDropdownVisible(true)}
            onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)}
            onKeyDown={handleKeyDown}
            className="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-action border-[#889EBB] text-black font-sans text-base cursor-pointer"
          />
          {value && (
            <img
              src={closeIcon}
              alt=""
              className="absolute right-8 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => handleClear()}
            />
          )}
          <img
            src={downArrow}
            alt=""
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          />
        </div>
      </Field>
      {isDropdownVisible && (
        <div
          ref={dropdownRef}
          className="relative z-20 bg-white border-2 rounded-md w-full shadow-lg max-h-48 overflow-auto border-action"
        >
          {renderDropdownContent()}
        </div>
      )}
    </div>
  );
}

export default CoachAutocomplete;
