const PATH = 'fe.pages.organization.subscriptions';

const messages = {
  noSubscriptions: {
    key: `${PATH}.no_subscriptions`,
    defaultValue: 'No subscriptions available'
  }
};

export default messages;
