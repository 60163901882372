const PATH =
  'fe.pages.organization.view_subscription_users.tables.unsubscribed_users_table';

const messages = {
  columns: {
    nameAndEmail: {
      key: `${PATH}.columns.name_and_email`,
      defaultValue: 'Name & Email'
    },
    role: {
      key: `${PATH}.columns.role`,
      defaultValue: 'Role'
    },
    invitedOn: {
      key: `${PATH}.columns.invited_on`,
      defaultValue: 'Invited On'
    },
    unsubscribedOn: {
      key: `${PATH}.columns.unsubscribed_on`,
      defaultValue: 'Unsubscribed On'
    }
  },
  actions: {
    resubscribe: {
      key: `${PATH}.actions.resubscribe`,
      defaultValue: 'Resubscribe Users ({{count}})'
    },
    deselect: {
      key: `${PATH}.actions.deselect`,
      defaultValue: 'Deselect All'
    }
  }
};

export default messages;
