import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import useCurrentUser from 'hooks/useCurrentUser';
import { clsx } from 'clsx';
import { useState } from 'react';
import { roleToText } from 'helpers/role';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { addUserToAccount } from 'actions/accounts';
import { Input } from 'components/Organization/Input';
import { useGetClientAccountRoles } from 'actions/roles';
import { toastSuccess } from 'components/Organization/Toast';
import { IconButton } from 'components/Organization/IconButton';
import { Select, SelectOption } from 'components/Organization/Select';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { HierarchyBuilder } from 'components/Organization/Hierarchy/utils';
import PageLoader from 'components/PageLoader';

import {
  useForm,
  Controller,
  useFieldArray,
  FormProvider,
  useFormContext
} from 'react-hook-form';

import {
  HierarchyInput,
  useAccountHierarchy
} from 'components/Organization/Hierarchy';

const schema = yup.object({
  members: yup.array().of(
    yup.object({
      email: yup
        .string()
        .email(i18n.ft(messages.errors.email))
        .required(i18n.ft(messages.errors.required)),
      first_name: yup.string().required(i18n.ft(messages.errors.required)),
      last_name: yup.string().required(i18n.ft(messages.errors.required)),
      role_id: yup.number().nullable(),
      node_ids: yup.array(yup.number().required()).required()
    })
  )
});

function parseError(error: any) {
  return error?.response?.data ?? [{}];
}

type RoleSelectionOption = SelectOption & { name: string };

interface MemberFormProps {
  index: number;
  roles: RoleSelectionOption[];
  remove?: () => void;
  hierarchy: HierarchyBuilder;
}

function MemberForm({ index, roles, remove, hierarchy }: MemberFormProps) {
  const caAdminRoleId = roles.find(role => role.name === 'ca_admin')?.value;
  const [isHierarchyDisabled, setIsHierarchyDisabled] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    setValue
  } = useFormContext();

  function setMemberRole(roleId: number) {
    setValue(`members.${index}.role_id`, roleId);

    if (roleId === caAdminRoleId) {
      setValue(`members.${index}.node_ids`, []);
      setIsHierarchyDisabled(true);
    } else {
      setIsHierarchyDisabled(false);
    }
  }

  return (
    <section>
      {errors.root?.[index] && (
        <div className="font-sans font-semibold text-base text-[#3C3F42] p-4 bg-[#EED3D8] border-x border-t border-[#AC213A] rounded-t-2xl flex items-center gap-2">
          <i className="fa-solid fa-circle-xmark text-[#AC213A]" />
          <span>{errors?.root?.[index]?.message}</span>
        </div>
      )}
      <div
        className={clsx(
          'bg-white p-6 rounded-2xl',
          errors.root?.[index] &&
            'rounded-t-none border-b border-x border-[#AC213A]'
        )}
      >
        {remove && (
          <div className="flex justify-end">
            <button
              type="button"
              onClick={remove}
              className="text-action text-sm font-sans font-semibold"
            >
              {i18n.ft(messages.removeMember)}
              <span className="fa fa-trash-can fa-solid pl-2"></span>
            </button>
          </div>
        )}
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <Input
            {...register(`members.${index}.email`)}
            required
            label={i18n.ft(messages.fields.email)}
            placeholder={i18n.ft(messages.fields.emailPlaceholder)}
            error={(errors.members as any)?.[index]?.email?.message}
          />
          <Input
            {...register(`members.${index}.first_name`)}
            required
            label={i18n.ft(messages.fields.firstName)}
            placeholder={i18n.ft(messages.fields.firstNamePlaceholder)}
            error={(errors.members as any)?.[index]?.first_name?.message}
          />
          <Input
            {...register(`members.${index}.last_name`)}
            required
            label={i18n.ft(messages.fields.lastName)}
            placeholder={i18n.ft(messages.fields.lastNamePlaceholder)}
            error={(errors.members as any)?.[index]?.last_name?.message}
          />
          <Controller
            name={`members.${index}.role_id`}
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                label={i18n.ft(messages.fields.role)}
                placeholder={i18n.ft(messages.fields.rolePlaceholder)}
                onChange={setMemberRole}
                options={roles}
              />
            )}
          />
          <div className="sm:col-span-2">
            <Controller
              name={`members.${index}.node_ids`}
              control={control}
              render={({ field }) => (
                <HierarchyInput
                  {...hierarchy}
                  value={field.value}
                  onChange={value =>
                    setValue(`members.${index}.node_ids`, value)
                  }
                  label={i18n.ft(messages.fields.hierarchy)}
                  placeholder={i18n.ft(messages.fields.hierarchyPlaceholder)}
                  disabled={isHierarchyDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

interface AddMembersProps {
  /** Current account id. */
  accountId: number;

  /** List of roles formatted for select component. */
  roles: RoleSelectionOption[];
}

function AddMembers({ accountId, roles }: AddMembersProps) {
  const navigate = useNavigate();
  const hierarchy = useAccountHierarchy(accountId);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { members: [{ node_ids: [] }] }
  });

  const { control, handleSubmit, setError } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'members'
  });

  const { isPending, mutate: addUser } = useMutation({
    mutationFn: addUserToAccount,
    onSuccess: ({ data }) => {
      navigate('/organization');
      toastSuccess({
        message: i18n.ft(messages.usersAdded, { count: data.length })
      });
    },
    onError: error => {
      const errors = parseError(error);

      errors.forEach((userError: any, index: number) => {
        if (userError) {
          if (userError.email) {
            setError(`members.${index}.email`, { message: userError.email });
          }

          if (userError.first_name) {
            setError(`members.${index}.first_name`, {
              message: userError.first_name
            });
          }

          if (userError.last_name) {
            setError(`members.${index}.last_name`, {
              message: userError.last_name
            });
          }

          if (userError.account_user) {
            setError(`root.${index}`, { message: userError.account_user });
          }
        }
      });
    }
  });

  const handleFormSubmit = (data: any) => {
    if (isPending) {
      return;
    }
    addUser({ accountId, params: { users: data.members } });
  };

  return (
    <FormProvider {...methods}>
      <div className="px-4 md:px-6 xl:px-10 max-w-7xl mx-auto">
        <div className="flex items-center gap-2 pt-12 pb-4">
          <IconButton
            label={i18n.ft(messages.goBack)}
            icon="fa-solid fa-arrow-left"
            onClick={() => navigate(-1)}
          />

          <h2 className="font-sans text-2xl sm:text-3xl">
            {i18n.ft(messages.addMember)}
          </h2>
        </div>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-4"
        >
          {fields.map((item, index) => (
            <MemberForm
              key={item.id}
              index={index}
              roles={roles}
              remove={index !== 0 ? () => remove(index) : undefined}
              hierarchy={hierarchy}
            />
          ))}

          <div className="flex justify-between">
            <button
              className="cursor-pointer text-action font-sans text-sm font-semibold py-4"
              onClick={() =>
                append({
                  email: '',
                  first_name: '',
                  last_name: '',
                  role_id: null,
                  node_ids: []
                })
              }
            >
              <span className="fa fa-plus fa-solid pr-2"></span>
              {i18n.ft(messages.addAnotherMember)}
            </button>
            <div className="py-4 flex justify-end">
              <PrimaryButton type="submit">
                {i18n.ft(messages.addMember)}
              </PrimaryButton>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

function AddMembersController() {
  const currentUser = useCurrentUser();
  const accountId = currentUser.current_account_id ?? 0;

  const { data, isSuccess } = useGetClientAccountRoles(accountId);

  if (!isSuccess) {
    return <PageLoader />;
  }

  const roles = data.map(role => ({
    value: role.id,
    text: roleToText(role.name),
    name: role.name
  }));

  return <AddMembers accountId={accountId} roles={roles} />;
}

export default AddMembersController;
