import i18n from 'helpers/i18n';
import messages from './messages';
import { toTitleCase } from 'helpers/text';

export function roleToText(role: string) {
  if (!role) {
    return '';
  }

  switch (role) {
    case 'ca_admin':
      return i18n.ft(messages.primaryAdmin);
    case 'administrator':
    case 'lr_admin':
      return i18n.ft(messages.administrator);
    case 'educator':
    case 'lr_educator':
      return i18n.ft(messages.educator);
    case 'lr_coach':
      return i18n.ft(messages.coach);
    default:
      return toTitleCase(role);
  }
}
