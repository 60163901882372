const PATH = 'fe.components.organization.email_autocomplete';

const messages = {
  loading: {
    key: `${PATH}.loading`,
    defaultValue: 'Loading...'
  },
  noResults: {
    key: `${PATH}.no_results`,
    defaultValue: 'No results found'
  },
  email: {
    key: `${PATH}.email`,
    defaultValue: 'Email*'
  },
  placeholder: {
    key: `${PATH}.placeholder`,
    defaultValue: 'Enter email address'
  }
};

export default messages;
