import { clsx } from 'clsx';
import { useNode } from './useNode';

export function NodeName() {
  const { depth, node } = useNode();

  const classes = clsx(
    'flex flex-col',
    !node.node_level_name && 'py-2',
    depth === 1 && 'font-semibold'
  );

  return node.type === 'Classroom' ? (
    <div className="flex flex-col">
      <span className="text-xs font-semibold">CLASSROOM</span>
      <span className="text-base">{node.name}</span>
    </div>
  ) : (
    <div className={classes}>
      {node.node_level_name && (
        <span className="text-xs font-semibold uppercase">
          {node.node_level_name}
        </span>
      )}

      <span className="text-base">{node.name}</span>
    </div>
  );
}
