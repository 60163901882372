import {
  Reducer,
  useRef,
  useReducer,
  useCallback,
  useLayoutEffect
} from 'react';

function useSafeDispatch(dispatch: any) {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(
    (...args: any[]) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch]
  );
}

enum ActionType {
  Update = 'UPDATE',
  UpdatePage = 'UPDATE_PAGE'
}

interface PaginatedState {
  page: number;
  per_page?: number;
}

type Action<State extends PaginatedState> =
  | { type: ActionType.Update; payload: Partial<State> }
  | { type: ActionType.UpdatePage; page: number };

function reducer<State extends PaginatedState>(
  state: State,
  action: Action<State>
) {
  switch (action.type) {
    case ActionType.Update:
      return { ...state, ...action.payload, page: 1 };
    case ActionType.UpdatePage:
      return { ...state, page: action.page };
    default:
      return state;
  }
}

export function usePaginatedSearch<SearchState extends PaginatedState>(
  initialValues: SearchState
) {
  const [state, dispatch] = useReducer<
    Reducer<SearchState, Action<SearchState>>
  >(reducer, initialValues);

  const safeDispatch = useSafeDispatch(dispatch);

  const setState = useCallback(
    (newState: Partial<SearchState>) => {
      safeDispatch({ type: ActionType.Update, payload: newState });
    },
    [safeDispatch]
  );

  const setPage = useCallback(
    (newPage: number) => {
      safeDispatch({ type: ActionType.UpdatePage, page: newPage });
    },
    [safeDispatch]
  );

  return { state, setState, setPage };
}
