import { forwardRef, useImperativeHandle } from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { Icon, Select } from 'semantic-ui-react';
import * as R from 'ramda';
import { useState, useEffect } from 'react';
import { Box } from 'components/Box';
import { Flex } from 'components/Flex';
import { TextArea, StatusWrapper, TextAreaWrapper, StyledIcon } from './Styled';
import { SectionCollapse } from 'components/SectionCollapse';

import {
  ClickableArea,
  SectionWithBorder
} from 'components/Measure/View/Styled';

import {
  getCustomQuestions,
  GetCustomQuestionsRes,
  answerCustomQuestion
} from 'actions/envScales/observationFeedback';

type QuestionState = { answer_notes: string } | { answer_id: number };

const ANSWER_MAX_CHAR_LENGTH = 140;

interface CustomQuestionsSectionProps {
  assessmentId: number;
  attached?: boolean;
}

export const CustomQuestionsSection = forwardRef(
  ({ attached, assessmentId }: CustomQuestionsSectionProps, ref) => {
    const { run, isPending } = useAsync();
    const [questionsExpanded, setQuestionsExpanded] = useState<boolean[]>([]);
    const [questions, setQuestions] = useState<GetCustomQuestionsRes>({
      questions_with_answers: []
    });
    const [sectionWithErrors, setSectionWithErrors] = useState<boolean | null>(
      null
    );
    const allAnswered = questions.questions_with_answers.every(q =>
      q.question_type === 'free_text' ? q.answer_notes?.length : q.answer_id
    );

    useImperativeHandle(ref, () => ({
      valid() {
        return valid();
      }
    }));

    useEffect(() => {
      run(getCustomQuestions(assessmentId), {
        onSuccess: data => {
          setQuestions(data);
          setQuestionsExpanded(
            new Array(data.questions_with_answers.length).fill(false)
          );
        }
      });
    }, [run, assessmentId]);

    function updateQuestionsState(questionId: number, state: QuestionState) {
      setQuestions(prevState => {
        const newState = prevState.questions_with_answers.map(obj =>
          obj.question_id === questionId ? { ...obj, ...state } : obj
        );

        return { questions_with_answers: newState };
      });
    }

    function handleAnswer(event: any, questionId: number) {
      const answer = event.currentTarget.value;
      const payload = { answer };

      answerCustomQuestion(assessmentId, questionId, payload);
    }

    function answerQuestionWithOption(questionId: number, optionId: number) {
      updateQuestionsState(questionId, { answer_id: optionId });

      answerCustomQuestion(assessmentId, questionId, {
        account_custom_question_option_id: optionId
      });
    }

    function toggleExpandQuestion(index: number) {
      let newArr = [...questionsExpanded];
      newArr[index] = !questionsExpanded[index];
      setQuestionsExpanded(newArr);
    }

    function valid() {
      setSectionWithErrors(!allAnswered);
      return allAnswered;
    }

    if (R.isEmpty(questions.questions_with_answers)) {
      return null;
    }

    function getSectionStatus() {
      if (allAnswered) {
        return 'completed';
      }

      if (!allAnswered && sectionWithErrors !== null) {
        return 'error';
      }

      return 'pending';
    }

    return (
      <SectionCollapse
        title={i18n.ft(messages.title)}
        subtitle={i18n.ft(messages.subtitle.questions)}
        status={getSectionStatus()}
        attached={attached}
        closeOthersOnOpen
      >
        <div className="sm:px-14 sm:pb-5">
          {!isPending &&
            questions.questions_with_answers.map((record, index: number) => {
              const isAnswered =
                record.question_type === 'free_text'
                  ? record.answer_notes?.length > 0
                  : Boolean(record.answer_id);

              return (
                <SectionWithBorder mb={'8px'} key={index}>
                  <ClickableArea onClick={() => toggleExpandQuestion(index)}>
                    <Box mt="8px">
                      <Flex justify="space-between" align="center">
                        <div>{record.question}</div>
                        <StatusWrapper hasErrors={false}>
                          {i18n.ft(messages.answered)}
                          <Icon.Group size="small">
                            <StyledIcon
                              circular
                              color="green"
                              size="small"
                              name={isAnswered ? 'check' : ''}
                              inverted={isAnswered}
                            />
                          </Icon.Group>
                        </StatusWrapper>
                      </Flex>
                    </Box>
                  </ClickableArea>

                  <Box mt={questionsExpanded[index] ? '24px' : '8px'}>
                    <TextAreaWrapper expanded={questionsExpanded[index]}>
                      {record.question_type === 'free_text' ? (
                        <TextArea
                          placeholder={i18n.ft(messages.textAreaPlaceholder, {
                            length: ANSWER_MAX_CHAR_LENGTH
                          })}
                          maxLength={ANSWER_MAX_CHAR_LENGTH}
                          rows={4}
                          data-question-id={record.question_id}
                          onBlur={(event: any) =>
                            handleAnswer(event, record.question_id)
                          }
                          onChange={(event: any) =>
                            updateQuestionsState(record.question_id, {
                              answer_notes: event.currentTarget.value
                            })
                          }
                          defaultValue={record.answer_notes || ''}
                        />
                      ) : (
                        <Select
                          fluid
                          data-question-id={record.question_id}
                          placeholder={i18n.ft(messages.selectPlaceholder)}
                          value={record.answer_id}
                          onChange={(_, data) =>
                            answerQuestionWithOption(
                              record.question_id,
                              Number(data.value)
                            )
                          }
                          options={record.options.map(opt => ({
                            key: opt.id,
                            value: opt.id,
                            text: opt.option
                          }))}
                        />
                      )}
                    </TextAreaWrapper>
                  </Box>
                </SectionWithBorder>
              );
            })}
        </div>
      </SectionCollapse>
    );
  }
);
