import i18n from 'helpers/i18n';
import messages from './messages';
import useAsync from 'hooks/useAsync';
import { logout } from 'actions/users';
import React, { useEffect } from 'react';
import RouteHelpers from 'helpers/routes';
import { validateToken } from 'actions/userTokens';
import { Link, useNavigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
import { Loader, Icon, Button } from 'semantic-ui-react';
import Invite from 'components/Tokens/View/Invite';
import PaymentRequest from 'components/Tokens/View/PaymentRequest';
import ContainerWithLogo from 'components/ContainerWithLogo';
import { MessageContainer, Message, Container } from './Styled';
import { useDispatch } from 'react-redux';
import { destroy } from 'store/slices/user';
import useCurrentUser from 'hooks/useCurrentUser';
import useProductKey from 'components/ActivateProductKey/useProductKey';

type URLParams = {
  token: string;
};

const View: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const pKeyRequest = useProductKey();
  const dispatch = useDispatch();
  const { token } = useRouteParams<URLParams>();
  const { run, data: userToken, isPending, isSuccess, isError } = useAsync();
  const isInvite = userToken?.type_group === 'invite';
  const isProductKey = userToken?.type_group === 'product_key';

  useEffect(() => {
    run(validateToken(token), {
      onSuccess: data => {
        if (data?.type_group === 'product_key') {
          if (!currentUser?.id) {
            // when user is not logged in
            navigate(RouteHelpers.getPath('users-activate-pk'), {
              state: { token: data?.token }
            });
          }
        } else {
          logout().then(() => {
            dispatch(destroy());
          });
        }
      }
    });
  }, [run, token, dispatch, currentUser?.id, navigate]);

  return (
    <Container>
      {isPending && (
        <Loader
          active
          size="large"
          inline="centered"
          content={i18n.ft(messages.pleaseWait)}
        />
      )}

      {isError && (
        <ContainerWithLogo>
          <MessageContainer>
            <Icon name="warning circle" color="red" size="huge" />
            <Message
              dangerouslySetInnerHTML={{
                __html: i18n.ft(messages.expiredLink)
              }}
            />
            <Button as={Link} to="/" content={i18n.ft(messages.goBack)} />
          </MessageContainer>
        </ContainerWithLogo>
      )}

      {isSuccess && isInvite && <Invite userToken={userToken} />}

      {isSuccess && userToken.type_name === 'training_payment_request' && (
        <PaymentRequest token={userToken} />
      )}

      {isSuccess && isProductKey && currentUser?.id && (
        <Container>
          <ContainerWithLogo>
            <MessageContainer>
              <Message>
                <strong>{i18n.ft(messages.product)}:</strong>{' '}
                {userToken.friendly_name}
              </Message>
              <Message
                dangerouslySetInnerHTML={{
                  __html: i18n.ft(messages.confirmationText, {
                    email: currentUser.email
                  })
                }}
              />
              <Button
                fluid
                type="submit"
                color="green"
                loading={pKeyRequest.isLoading}
                disabled={pKeyRequest.isLoading || pKeyRequest.isError}
                onClick={() => pKeyRequest.submit(userToken.token)}
                content={i18n.ft(messages.confirmButton)}
              />
              {pKeyRequest.error && (
                <div className="flex column">
                  <Message className="text-red-600">
                    {pKeyRequest.error}
                  </Message>
                </div>
              )}
            </MessageContainer>
          </ContainerWithLogo>
        </Container>
      )}
    </Container>
  );
};

export default View;
