import i18n from 'helpers/i18n';
import messages from './messages';

export const lrRoles = [
  { value: 'lr_admin', label: 'Admin' },
  { value: 'lr_coach', label: 'Coach' },
  { value: 'lr_educator', label: 'Educator' }
];

export const dcRoles = [
  { value: 'admin', label: 'Admin' },
  { value: 'data_entry', label: 'Data Entry' },
  { value: 'observer', label: 'Observer' },
  { value: 'supervisor', label: 'Supervisor' }
];

export const statusList = [
  { value: 'accepted', label: 'Accepted' },
  { value: 'invited', label: 'Invited' },
  { value: 'no_invitation', label: 'Not Invited' }
];

export const TABLE_TYPES = {
  DC_ACTIVE_USERS: 'DC_ACTIVE_USERS',
  LR_ACTIVE_USERS: 'LR_ACTIVE_USERS',
  LR_COACH_GROUPS: 'LR_COACH_GROUPS',
  UNSUBSCRIBED_USERS: 'UNSUBSCRIBED_USERS'
};

export const DC_TABLE_TYPE_OPTIONS = [
  {
    value: TABLE_TYPES.DC_ACTIVE_USERS,
    text: i18n.ft(messages.activeUsers)
  },
  {
    value: TABLE_TYPES.UNSUBSCRIBED_USERS,
    text: i18n.ft(messages.unsubscribedUsers)
  }
];

export const LR_TABLE_TYPE_OPTIONS = [
  {
    value: TABLE_TYPES.LR_ACTIVE_USERS,
    text: i18n.ft(messages.activeUsers)
  },
  {
    value: TABLE_TYPES.UNSUBSCRIBED_USERS,
    text: i18n.ft(messages.unsubscribedUsers)
  },
  {
    value: TABLE_TYPES.LR_COACH_GROUPS,
    text: i18n.ft(messages.coachGroups)
  }
];

export function getTableTypeOptions(subscription: string) {
  if (subscription === 'data_collection') {
    return DC_TABLE_TYPE_OPTIONS;
  } else {
    return LR_TABLE_TYPE_OPTIONS;
  }
}

export function getTableFilterRoles(subscription: string) {
  if (subscription === 'data_collection') {
    return dcRoles;
  } else {
    return lrRoles;
  }
}
