const PATH = 'fe.pages.organization.subscriptions.coach_group_details';

const messages = {
  nameAndEmail: {
    key: `${PATH}.name_and_email`,
    defaultValue: 'Name & Email'
  },
  remove: {
    key: `${PATH}.remove`,
    defaultValue: 'Remove from Group ({{count}})'
  },
  deselectAll: {
    key: `${PATH}.deselect_all`,
    defaultValue: 'Deselect All'
  },
  usersCount: {
    key: `${PATH}.users_count`,
    defaultValue: 'Showing {{initial}}-{{end}} of {{total}}'
  },
  educators: {
    key: `${PATH}.educators`,
    defaultValue: 'Educators'
  },
  coach: {
    key: `${PATH}.coach`,
    defaultValue: 'Coach'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'Educator(s) have been removed from the coach group.'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue:
      'Error removing educator(s) from coach group. Please try again later.'
  }
};

export default messages;
