import i18n from 'helpers/i18n';
import messages from './messages';
import { toast } from 'react-toastify';
import { CheckboxInput } from './Styled';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { createUserAnswers, deleteUserAnswers } from 'actions/userAnswers';
import { useKnowledgeTest } from './KnowledgeTestContext';

type AnswerOptions = {
  user_answer_id?: number;
  checked: boolean;
  description: string;
  id: number;
};

interface MultipleAnswerProps {
  answer_options: AnswerOptions[];
  category: string | undefined;
  questionId: number;
}

type URLParams = {
  id: string;
};

function MultipleAnswer({
  answer_options: answerOptions,
  category,
  questionId
}: MultipleAnswerProps) {
  const { id } = useRouteParams<URLParams>();
  const { updater } = useKnowledgeTest();
  const [currentQuestionState, setCurrentQuestionState] =
    useState<AnswerOptions[]>(answerOptions);
  const pendingOperations = useRef<Map<number, Promise<any>>>(new Map());

  useEffect(() => {
    setCurrentQuestionState(answerOptions);
  }, [answerOptions]);

  const isChecked = useCallback(
    (answerId: number) => {
      const answer = currentQuestionState.find(el => el.id === answerId);
      return answer ? answer.checked : false;
    },
    [currentQuestionState]
  );

  const updateAnswerState = useCallback(
    async (answerId: number, isChecked: boolean) => {
      setCurrentQuestionState(prevState => {
        return prevState.map(answer =>
          answer.id === answerId ? { ...answer, checked: isChecked } : answer
        );
      });

      const answerToUpdate = currentQuestionState.find(
        answer => answer.id === answerId
      );

      if (!answerToUpdate) return;

      try {
        if (isChecked) {
          const userAnswer = {
            question_id: questionId,
            question_answer_id: answerId
          };

          const createRequest = createUserAnswers(id, userAnswer);
          pendingOperations.current.set(answerId, createRequest);

          const response = await createRequest;

          setCurrentQuestionState(prevState => {
            return prevState.map(answer =>
              answer.id === answerId
                ? { ...answer, user_answer_id: response.data.id }
                : answer
            );
          });
        } else if (answerToUpdate.user_answer_id) {
          const deleteRequest = deleteUserAnswers(
            id,
            answerToUpdate.user_answer_id
          );
          pendingOperations.current.set(answerId, deleteRequest);

          await deleteRequest;

          setCurrentQuestionState(prevState => {
            return prevState.map(answer =>
              answer.id === answerId
                ? { ...answer, user_answer_id: undefined }
                : answer
            );
          });
        }
      } catch (error) {
        toast.error(i18n.ft(messages.error));
        setCurrentQuestionState(prevState => {
          return prevState.map(answer =>
            answer.id === answerId ? { ...answer, checked: !isChecked } : answer
          );
        });
      } finally {
        pendingOperations.current.delete(answerId);
        updater();
      }
    },
    [currentQuestionState, id, questionId, updater]
  );

  const handleCheckboxInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.dataset.answerId === undefined) {
        return;
      }

      const answerId = parseInt(event.currentTarget.dataset.answerId);
      const isChecked = event.currentTarget.checked;

      if (pendingOperations.current.has(answerId)) {
        event.preventDefault();
        return;
      }

      updateAnswerState(answerId, isChecked);
    },
    [updateAnswerState]
  );

  return (
    <div className="pt-4 text-base">
      {currentQuestionState.map((answer: AnswerOptions) => (
        <div key={answer.id} className="grid grid-cols-12 items-center mb-8">
          <div className="pl-2 col-span-1">
            <CheckboxInput
              type="checkbox"
              id={`question_${answer.id}`}
              name={`question_${answer.id}`}
              className="align-middle disabled:opacity-50"
              onChange={handleCheckboxInputChange}
              data-answer-id={answer.id}
              checked={isChecked(answer.id)}
              disabled={pendingOperations.current.has(answer.id)}
            />
          </div>
          <div className="ml-4 col-span-11 pl-2 md:pl-0">
            <label htmlFor={`question_${answer.id}`} className="cursor-pointer">
              <span
                dangerouslySetInnerHTML={{
                  __html: answer.description
                }}
              ></span>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MultipleAnswer;
