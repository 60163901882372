import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type SubscriptionUsers = {
  subscription_users: {
    id: number;
    name: string;
    email: string;
  }[];
};

export function getGetCoaches({
  subscriptionId = 0,
  role = 'lr_admin'
}: {
  subscriptionId: number;
  role: string;
}) {
  return axios.get<SubscriptionUsers>(
    `/api/v2/subscriptions/${subscriptionId}/users/by_role/${role}`
  );
}

export function useGetCoaches(
  subscriptionId: number,
  role = 'lr_admin',
  query: string
) {
  return useQuery({
    queryKey: ['by_role', 'query', subscriptionId, role, query],
    queryFn: () =>
      getGetCoaches({ subscriptionId, role }).then(response => response.data)
  });
}

export function getCoachGroups(subscriptionId: number, params: any = {}) {
  return axios.get(
    `/api/v2/coachings/coaches?subscription_id=${subscriptionId}`,
    {
      params
    }
  );
}

export function useGetCoachGroups(subscriptionId: number, params: any = {}) {
  return useQuery({
    queryKey: ['coachings', subscriptionId, 'coaches', params],
    queryFn: () =>
      getCoachGroups(subscriptionId, params).then(response => response.data)
  });
}
