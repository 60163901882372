import { createContext } from 'react';

type ContextValue = {
  accountId: number;
  accountName: string;
  nodes: any[];
  nodesMap: Record<number, any>;
  openNodeIds: number[];
  setOpenNodeIds: (nodeIds: number[]) => void;
  refetch?: () => void;
};

const defaultValue = {
  accountId: 0,
  accountName: '',
  nodes: [],
  nodesMap: {},
  openNodeIds: [],
  setOpenNodeIds: () => {}
};

export const HierarchyContext = createContext<ContextValue>(defaultValue);
HierarchyContext.displayName = 'HierarchyContext';
