import SubscriptionCard from './SubscriptionCard';
import useCurrentUser from 'hooks/useCurrentUser';
import { getManageableAccountSubscriptions } from 'actions/subscriptions';
import { useGetAccountsByArea } from 'actions/accountManager/accounts';
import { getCurrentAccount } from 'components/Organization/AccountManager/utils';
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import i18n from 'helpers/i18n';
import messages from './messages';

const AREA = 'ManageOrganization';
const ALLOWED_SUBSCRIPTIONS = ['learning_resources', 'data_collection'];

interface Subscription {
  id: number;
  name: string;
  product: { display_name: string; name: string };
  end_date: string;
  max_seats: number;
  users: number;
}

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState(true);

  const currentUser = useCurrentUser();
  const { data: dataAccountByArea = [] } = useGetAccountsByArea(AREA);
  const accountId = getCurrentAccount(
    currentUser.current_account_id,
    dataAccountByArea
  );

  useEffect(() => {
    getManageableAccountSubscriptions().then(res => {
      const allowed_subscriptions = res.data.subscriptions.filter(
        (subscription: { product: { name: string } }) =>
          ALLOWED_SUBSCRIPTIONS.includes(subscription.product.name)
      );
      setSubscriptions(allowed_subscriptions);
      setLoading(false);
    });
  }, [accountId]);

  if (loading) {
    return <Loader active className="mt-8" />;
  }

  return (
    <div className="my-8">
      {subscriptions.length === 0 ? (
        <div>{i18n.ft(messages.noSubscriptions)}</div>
      ) : (
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-6">
          {subscriptions.map((subscription: Subscription) => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default Subscriptions;
