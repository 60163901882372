import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { roleToText } from 'helpers/role';
import { composeUser } from 'pages/Organization/Members/utils';
import { usePaginatedSearch } from 'hooks/usePaginatedSearch';
import { useGetUnsubscribedUsers } from 'actions/memberships';
import { Pagination } from 'components/Organization/Pagination';
import { Table, ColumnFilter } from 'components/Organization/Table';
import { TextButton } from 'components/Organization/TextButton';
import { Checkbox } from 'components/Organization/Checkbox';
import { useTableState } from '../utils';

type QueryParams = {
  page: number;
  per_page: number;
  query: string;
  role: string[];
  sort_by: string | null;
  sort_dir: 'asc' | 'desc' | null;
};

interface UnsubscribedUsersTableProps {
  searchQuery: string;
  subscriptionId: number;
  filterRoles: { value: string; label: string }[];
}

export function UnsubscribedUsersTable({
  searchQuery,
  subscriptionId,
  filterRoles
}: UnsubscribedUsersTableProps) {
  const {
    state,
    setState: setQueryState,
    setPage: setQueryPage
  } = usePaginatedSearch<QueryParams>({
    page: 1,
    per_page: 20,
    query: searchQuery,
    role: [],
    sort_by: null,
    sort_dir: null
  });

  const {
    selected,
    setSelected,
    toggleSelected,
    setState,
    setPage,
    setSortBy
  } = useTableState({
    state,
    search: searchQuery,
    setState: setQueryState,
    setPage: setQueryPage
  });

  const { data, isSuccess } = useGetUnsubscribedUsers(subscriptionId, {
    ...state,
    sort_by: mapSortBy(state.sort_by)
  });

  function mapSortBy(sortBy: string | null) {
    switch (sortBy) {
      case 'name':
        return 'user_name';
      case 'invited_at':
        return 'created_at';
      case 'unsubscribed_at':
        return 'deleted_at';
      default:
        return sortBy;
    }
  }

  const handleHeaderSelect = () => {
    if (!data || !data.unsubscribed) {
      return;
    }

    if (selected.length <= 0) {
      const allUserIdsOnPage = data.unsubscribed.map(
        membership => membership.user_id
      );
      setSelected(allUserIdsOnPage);
    } else {
      setSelected([]);
    }
  };

  const memberships = data?.unsubscribed ?? [];

  return (
    <>
      <Table
        isLoading={!isSuccess}
        sortBy={state.sort_by}
        sortDir={state.sort_dir}
        onSort={setSortBy}
        headers={[
          {
            content: (
              <Checkbox
                checked={selected.length > 0}
                indeterminate={
                  selected.length > 0 && selected.length !== memberships.length
                }
                onChange={handleHeaderSelect}
              />
            ),
            accessor: 'checkbox'
          },
          {
            content: i18n.ft(messages.columns.nameAndEmail),
            accessor: 'name',
            sortable: true
          },
          {
            content: (
              <ColumnFilter
                options={filterRoles}
                values={state.role}
                onChange={role => setState({ role })}
                name={i18n.ft(messages.columns.role)}
              />
            ),
            accessor: 'role'
          },
          {
            content: i18n.ft(messages.columns.invitedOn),
            accessor: 'invited_at',
            sortable: true
          },
          {
            content: i18n.ft(messages.columns.unsubscribedOn),
            accessor: 'unsubscribed_at',
            sortable: true
          }
        ]}
        data={memberships.map(membership => ({
          selected: selected.includes(membership.user_id),
          checkbox: (
            <Checkbox
              checked={selected.includes(membership.user_id)}
              onChange={() => toggleSelected(membership.user_id)}
            />
          ),
          name: composeUser(
            membership.user_id,
            membership.full_name,
            membership.email
          ),
          role: roleToText(membership.role),
          invited_at: moment(membership.invited_at).format('MM/DD/YYYY'),
          unsubscribed_at: moment(membership.unsubscribed_at).format(
            'MM/DD/YYYY'
          )
        }))}
        actions={
          selected.length > 0 && (
            <div className="flex gap-6">
              <TextButton inverted size="small">
                <i className="fa-solid fa-rotate-right mr-2" />
                <span>
                  {i18n.ft(messages.actions.resubscribe, {
                    count: selected.length
                  })}
                </span>
              </TextButton>
              <TextButton inverted size="small" onClick={() => setSelected([])}>
                <span>{i18n.ft(messages.actions.deselect)}</span>
              </TextButton>
            </div>
          )
        }
      />

      <div className="mt-6 flex justify-center">
        <Pagination
          page={state.page}
          onPageChange={setPage}
          total={data?.pagination.total_pages ?? 0}
        />
      </div>
    </>
  );
}
