import settings from 'helpers/settings';

export const COMMUNITY_URL = settings.lookup('REACT_APP_COMMUNITY_BASE_URL');
export const MY_CLASS_URL = settings.lookup('REACT_APP_MYCLASS_BASE_URL');
export const MY_TEACHSTONE_URL = settings.lookup('REACT_APP_MYTS_BASE_URL');
export const CANVAS_URL = settings.lookup('REACT_APP_CANVAS_PATH');
export const ENV_SCALES_URL = settings.lookup('REACT_APP_ENV_SCALES_BASE_URL');
export const MY_COACH_CONNECT_URL = settings.lookup(
  'REACT_APP_MYCOACH_CONNECT_BASE_URL'
);

const routes = [
  /* Internal Application Links */
  { name: 'app', path: '/' },
  { name: 'account', path: '/account' },
  { name: 'account-phone', path: '/account/phone' },
  { name: 'account-password', path: '/account/password' },
  { name: 'account-experience', path: '/account/experience' },
  { name: 'single-logout', path: '/single_logout' },
  { name: 'masquerade', path: `/api/v2/users/:id/masquerade` },
  { name: 'unMasquerade', path: `/api/v2/users/unmasquerade` },
  {
    name: 'expert-code-justification-pdf',
    path: `/api/v2/coding_videos/:id/justification`
  },

  /* Admin Panel */
  { name: 'admin-panel', path: '/admin' },

  // Manage Users
  { name: 'admin-users', path: '/admin/users' },
  { name: 'admin-create-user', path: '/admin/users/new' },
  { name: 'admin-user', path: '/admin/users/:id' },
  { name: 'admin-user-roles', path: '/admin/users/:id/roles' },

  // Manage Accounts
  { name: 'admin-accounts', path: '/admin/accounts' },
  { name: 'admin-view-account', path: '/admin/accounts/:id' },
  { name: 'admin-create-account', path: '/admin/accounts/new' },
  { name: 'admin-accounts-hierarchy', path: '/admin/accounts/:id/hierarchy' },
  {
    name: 'admin-accounts-product-settings',
    path: '/admin/accounts/:id/product-settings'
  },
  {
    name: 'admin-accounts-assessment-template',
    path: '/admin/accounts/:id/assessment-template/:account_guid'
  },
  {
    name: 'admin-accounts-pdf-settings',
    path: '/admin/accounts/:id/pdf-settings'
  },
  {
    name: 'admin-accounts-template-settings',
    path: '/admin/accounts/:id/template-settings'
  },
  {
    name: 'admin-accounts-reporting-settings',
    path: '/admin/accounts/:id/reporting-settings'
  },
  {
    name: 'admin-accounts-user-settings',
    path: '/admin/accounts/:id/user-settings'
  },
  {
    name: 'admin-accounts-account-management-settings',
    path: '/admin/accounts/:id/account-management'
  },
  {
    name: 'admin-accounts-informal-observations',
    path: '/admin/accounts/:id/informal-observations'
  },

  // Manage Accounts Subscriptions
  {
    name: 'admin-accounts-subscriptions',
    path: '/admin/accounts/:id/subscriptions'
  },
  {
    name: 'admin-create-accounts-subscription',
    path: '/admin/accounts/:accountId/subscriptions/new'
  },
  {
    name: 'admin-view-accounts-subscription',
    path: '/admin/accounts/:accountId/subscriptions/:id'
  },
  {
    name: 'admin-view-accounts-subscription-invite',
    path: '/admin/accounts/:accountId/subscriptions/:id/invite'
  },
  {
    name: 'admin-view-accounts-subscription-coach-groups',
    path: '/admin/accounts/:accountId/subscriptions/:id/coach-groups'
  },
  {
    name: 'admin-create-accounts-subscription-coach-groups',
    path: '/admin/accounts/:accountId/subscriptions/:id/coach-groups/new'
  },
  {
    name: 'admin-edit-accounts-subscription-coach-groups',
    path: '/admin/accounts/:accountId/subscriptions/:id/coach-groups/:coachId/edit'
  },
  {
    name: 'admin-view-accounts-subscription-users',
    path: '/admin/accounts/:accountId/subscriptions/:id/users'
  },
  {
    name: 'admin-accounts-subscription-users-create',
    path: '/admin/accounts/:accountId/subscriptions/:id/users/new'
  },
  {
    name: 'admin-accounts-subscription-users-edit',
    path: '/admin/accounts/:accountId/subscriptions/:id/users/:userId'
  },
  // Manage Accounts Users
  {
    name: 'admin-accounts-users',
    path: '/admin/accounts/:id/users'
  },
  {
    name: 'admin-accounts-edit-user',
    path: '/admin/accounts/:accountId/users/:id'
  },

  // Manage Account Product Key
  {
    name: 'admin-accounts-product-keys',
    path: '/admin/accounts/:id/product-keys'
  },
  {
    name: 'admin-accounts-product-key-create',
    path: '/admin/accounts/:id/product-keys/new'
  },
  {
    name: 'admin-accounts-product-key-view',
    path: '/admin/accounts/:id/product-keys/:token'
  },
  {
    name: 'admin-accounts-product-key-usages',
    path: '/admin/accounts/:id/product-keys/:token/usages'
  },

  // Account trainings
  {
    name: 'admin-accounts-trainings',
    path: '/admin/accounts/:id/trainings'
  },
  {
    name: 'admin-accounts-trainings-participants',
    path: '/admin/accounts/:id/trainings/:trainingId/participants'
  },

  // Account Batch Imports
  {
    name: 'admin-accounts-users-upload',
    path: '/admin/accounts/:id/users/upload'
  },
  {
    name: 'admin-accounts-users-upload-batch',
    path: '/admin/accounts/:id/users/upload/:batchId'
  },
  {
    name: 'admin-accounts-hierarchy-upload',
    path: '/admin/accounts/:id/hierarchy/upload'
  },
  {
    name: 'admin-accounts-hierarchy-upload-batch',
    path: '/admin/accounts/:id/hierarchy/upload/:batchId'
  },

  // Manage Age Level Groups
  { name: 'admin-age-level-groups', path: '/admin/age-level-groups' },
  { name: 'admin-view-age-level-group', path: '/admin/age-level-groups/:slug' },

  // Manage Certifications
  { name: 'admin-certifications', path: '/admin/certifications' },
  { name: 'admin-view-certification', path: '/admin/certifications/:id' },
  {
    name: 'admin-view-certification-course',
    path: '/admin/certifications/:id/courses'
  },
  {
    name: 'admin-view-certification-course-new',
    path: '/admin/certifications/:id/courses/:type/new'
  },
  { name: 'admin-create-certification', path: '/admin/certifications/new' },

  // Manage Courses
  { name: 'admin-courses', path: '/admin/courses' },
  { name: 'admin-courses-view', path: '/admin/courses/:id' },
  { name: 'admin-view-course-sections', path: '/admin/courses/:id/sections' },
  {
    name: 'admin-create-course-section',
    path: '/admin/courses/:id/sections/new'
  },
  {
    name: 'admin-view-course-section',
    path: '/admin/courses/:courseId/sections/:id'
  },
  {
    name: 'admin-view-course-section-enrollments',
    path: '/admin/courses/:courseId/sections/:sectionId/enrollments'
  },
  {
    name: 'admin-create-course-section-enrollment',
    path: '/admin/courses/:courseId/sections/:sectionId/enrollments/new'
  },
  {
    name: 'admin-view-course-section-enrollment',
    path: '/admin/courses/:courseId/sections/:sectionId/enrollments/:id'
  },

  // Manage Unsynced Enrollments
  {
    name: 'admin-unsynced-enrollments',
    path: '/admin/unsynced-enrollments'
  },

  // Manage Products
  { name: 'admin-products', path: '/admin/products' },
  { name: 'admin-products-view', path: '/admin/products/:id' },
  { name: 'admin-products-view-courses', path: '/admin/products/:id/courses' },
  {
    name: 'admin-products-create-course',
    path: '/admin/products/:id/courses/new'
  },
  {
    name: 'admin-products-edit-course',
    path: '/admin/products/:id/courses/:courseId'
  },

  // Manage User Tokens
  { name: 'admin-user-tokens', path: '/admin/user_tokens' },
  { name: 'admin-user-token', path: '/admin/user_tokens/:token' },
  { name: 'admin-user-token-usages', path: '/admin/user_tokens/:token/usages' },

  // Manage Learning Pathways
  { name: 'admin-learning-pathways', path: '/admin/learning_pathways' },
  {
    name: 'admin-learning-pathways-table',
    path: '/admin/learning_pathways/table'
  },
  {
    name: 'admin-learning-pathway-sections',
    path: '/admin/learning_pathways/:learningPathwayId/sections'
  },
  {
    name: 'admin-learning-pathway-approvals',
    path: '/admin/learning_pathways/:learningPathwayId/approvals'
  },
  {
    name: 'admin-learning-pathway-enroll-user',
    path: '/admin/learning_pathways/:learningPathwayId/enroll-user'
  },

  // Manage Learning Pathways Templates
  {
    name: 'admin-learning-pathway-templates',
    path: '/admin/learning_pathways/templates'
  },
  {
    name: 'admin-learning-pathway-templates-create',
    path: '/admin/learning_pathways/templates/new'
  },
  {
    name: 'admin-learning-pathway-templates-create-instance',
    path: '/admin/learning_pathways/templates/:id/instances/new'
  },
  {
    name: 'admin-learning-pathway-template',
    path: '/admin/learning_pathways/templates/:id'
  },
  {
    name: 'admin-learning-pathways-template-groups',
    path: '/admin/learning_pathways/templates/:id/groups'
  },
  {
    name: 'admin-learning-pathways-template-instances',
    path: '/admin/learning_pathways/templates/:id/instances'
  },
  {
    name: 'admin-templates-groups-create',
    path: '/admin/learning_pathways/templates/:id/groups/new'
  },
  {
    name: 'admin-templates-groups-edit',
    path: '/admin/learning_pathways/templates/:id/groups/:groupId/edit'
  },

  // Manage Videos
  { name: 'admin-videos', path: '/admin/videos' },
  { name: 'admin-videos-create', path: '/admin/videos/new' },
  { name: 'admin-videos-edit', path: '/admin/videos/:id/edit' },
  { name: 'admin-videos-answer-sets', path: '/admin/videos/:id/edit/answers' },
  {
    name: 'admin-videos-update-answers',
    path: '/admin/videos/:id/classtemplates/:classTemplateId/edit/answers'
  },

  // Learning Resources
  { name: 'admin-learning-resources', path: '/admin/learning-resources' },
  {
    name: 'admin-learning-resources-edit',
    path: '/admin/learning-resources/:id/edit'
  },
  {
    name: 'admin-learning-resources-archive',
    path: '/admin/learning-resources/:id'
  },
  {
    name: 'admin-learning-resources-create',
    path: '/admin/learning-resources/new'
  },
  {
    name: 'admin-learning-resources-table',
    path: '/admin/learning_resources/table'
  },
  { name: 'admin-playlist', path: '/admin/learning-resources/playlist' },
  {
    name: 'admin-playlist-create',
    path: '/admin/learning-resources/playlist/new'
  },
  {
    name: 'admin-playlist-edit',
    path: '/admin/learning-resources/playlist/:id/edit'
  },
  { name: 'admin-coaching', path: '/admin/learning-resources/coaching' },

  // Manage Test Templates
  { name: 'admin-test-templates', path: '/admin/test_templates' },
  { name: 'admin-test-templates-create', path: '/admin/test_templates/new' },
  { name: 'admin-test-templates-edit', path: '/admin/test_templates/:id/edit' },

  // Manage Test Templates Questions
  {
    name: 'admin-test-templates-questions',
    path: '/admin/test_templates/:id/edit/questions'
  },
  {
    name: 'admin-test-templates-questions-new',
    path: '/admin/test_templates/:id/questions/new'
  },
  {
    name: 'admin-test-templates-questions-edit',
    path: '/admin/test_templates/:id/questions/:question_id'
  },

  // Manage Test Templates Answers
  {
    name: 'admin-test-templates-answers',
    path: '/admin/test_templates/:id/questions/:question_id/answers'
  },
  {
    name: 'admin-test-templates-answers-new',
    path: '/admin/test_templates/:id/questions/:question_id/answers/new'
  },
  {
    name: 'admin-test-templates-answers-edit',
    path: '/admin/test_templates/:id/questions/:question_id/answers/:answer_id'
  },

  // Manage Focus Content
  { name: 'admin-focus-content', path: '/admin/focus-content' },
  { name: 'admin-focus-content-areas', path: '/admin/focus-content/areas' },
  {
    name: 'admin-focus-content-categories',
    path: '/admin/focus-content/categories'
  },
  {
    name: 'admin-focus-content-categories-create',
    path: '/admin/focus-content/categories/new'
  },
  {
    name: 'admin-focus-content-categories-edit',
    path: '/admin/focus-content/categories/:id/edit'
  },
  {
    name: 'admin-focus-content-priorities',
    path: '/admin/focus-content/priorities'
  },
  {
    name: 'admin-focus-content-priorities-create',
    path: '/admin/focus-content/priorities/new'
  },
  {
    name: 'admin-focus-content-priorities-edit',
    path: '/admin/focus-content/priorities/:id/edit'
  },
  {
    name: 'admin-focus-content-focuses',
    path: '/admin/focus-content/focuses'
  },
  {
    name: 'admin-focus-content-focuses-create',
    path: '/admin/focus-content/focuses/new'
  },
  {
    name: 'admin-focus-content-focuses-edit',
    path: '/admin/focus-content/focuses/:id/edit'
  },
  {
    name: 'admin-focus-content-strategies',
    path: '/admin/focus-content/strategies'
  },
  {
    name: 'admin-focus-content-strategies-create',
    path: '/admin/focus-content/strategies/new'
  },
  {
    name: 'admin-focus-content-strategies-edit',
    path: '/admin/focus-content/strategies/:id/edit'
  },
  {
    name: 'admin-focus-content-cues',
    path: '/admin/focus-content/cues'
  },
  {
    name: 'admin-focus-content-cues-create',
    path: '/admin/focus-content/cues/new'
  },
  {
    name: 'admin-focus-content-cues-edit',
    path: '/admin/focus-content/cues/:id/edit'
  },
  {
    name: 'admin-focus-content-prompts',
    path: '/admin/focus-content/prompts'
  },
  {
    name: 'admin-focus-content-prompts-create',
    path: '/admin/focus-content/prompts/new'
  },
  {
    name: 'admin-focus-content-prompts-edit',
    path: '/admin/focus-content/prompts/:id/edit'
  },
  {
    name: 'admin-focus-content-general-cues',
    path: '/admin/focus-content/general-cues'
  },
  {
    name: 'admin-focus-content-general-cues-create',
    path: '/admin/focus-content/general-cues/new'
  },
  {
    name: 'admin-focus-content-general-cues-edit',
    path: '/admin/focus-content/general-cues/:id/edit'
  },

  // Manage User Applications
  {
    name: 'admin-users-applications',
    path: '/admin/users/:userId/applications'
  },

  // Manage User Subscriptions
  {
    name: 'admin-users-subscriptions',
    path: '/admin/users/:userId/subscriptions'
  },

  // View User Trainings
  {
    name: 'admin-users-trainings',
    path: '/admin/users/:userId/trainings'
  },

  // View User Tests
  {
    name: 'admin-users-tests',
    path: '/admin/users/:userId/tests'
  },
  {
    name: 'admin-users-tests-create',
    path: '/admin/users/:userId/tests/new'
  },
  {
    name: 'admin-users-tests-edit',
    path: '/admin/users/:userId/tests/:testId/edit'
  },
  {
    name: 'admin-user-tests-attempts',
    path: '/admin/users/:userId/tests/:testId/attempts'
  },
  {
    name: 'admin-users-test-attempts',
    path: '/admin/users/:userId/tests/:testId/attempts'
  },

  // View User Enrollments
  {
    name: 'admin-users-enrollments',
    path: '/admin/users/:userId/enrollments'
  },
  {
    name: 'admin-users-enrollments-transfer',
    path: '/admin/users/:userId/enrollments/:enrollmentId/transfer'
  },

  // View User Pathways
  {
    name: 'admin-users-pathways',
    path: '/admin/users/:userId/learning_pathways'
  },
  {
    name: 'admin-users-pathways-transfer',
    path: '/admin/users/:userId/learning_pathways/:pathwayId/transfer'
  },
  {
    name: 'admin-users-pathways-suspend',
    path: '/admin/users/:userId/learning_pathways/:pathwayId/suspend'
  },

  // View User Client Accounts
  {
    name: 'admin-users-client-accounts',
    path: '/admin/users/:userId/client-accounts'
  },

  // Manage User Certifications
  {
    name: 'admin-users-certifications',
    path: '/admin/users/:userId/certifications'
  },
  {
    name: 'admin-users-certifications-view',
    path: '/admin/users/:userId/certifications/:id'
  },
  {
    name: 'admin-users-certifications-create',
    path: '/admin/users/:userId/certifications/new'
  },

  // Manage Training Templates
  { name: 'admin-templates', path: '/admin/templates' },
  { name: 'admin-view-template', path: '/admin/templates/:id' },
  { name: 'admin-create-template', path: '/admin/templates/new' },
  { name: 'admin-templates-courses', path: '/admin/templates/:id/courses' },
  {
    name: 'admin-templates-courses-create',
    path: '/admin/templates/:id/courses/:type/new'
  },
  {
    name: 'admin-templates-courses-edit',
    path: '/admin/templates/:id/courses/:courseId/edit'
  },

  // Manage Trainings
  { name: 'admin-trainings', path: '/admin/trainings' },
  { name: 'admin-view-training', path: '/admin/trainings/:id' },
  { name: 'admin-view-training-tokens', path: '/admin/trainings/:id/tokens' },

  // Tags
  { name: 'admin-tags', path: '/admin/tags' },
  { name: 'admin-tags-create', path: '/admin/tags/new' },

  /* Trainer Dashboard */

  // Trainings
  { name: 'td-trainings', path: '/trainings' },
  {
    name: 'td-trainings-policy',
    path: '/trainings/affiliate-cancellation-policy'
  },
  { name: 'td-trainings-view', path: '/trainings/:id' },
  { name: 'td-trainings-view-edit', path: '/trainings/:id/edit' },
  { name: 'td-trainings-view-details', path: '/trainings/:id/details' },
  {
    name: 'td-trainings-view-participants',
    path: '/trainings/:id/participants'
  },
  { name: 'td-trainings-view-attendance', path: '/trainings/:id/attendance' },
  { name: 'td-trainings-view-checkout', path: '/trainings/:id/checkout' },
  {
    name: 'td-trainings-view-card-checkout',
    path: '/trainings/:id/card-checkout'
  },
  {
    name: 'td-trainings-view-checkout-completed',
    path: '/trainings/:id/checkout-completed'
  },

  // Training Catalog
  { name: 'td-catalog', path: '/training-catalog' },
  { name: 'td-catalog-new', path: '/training-catalog/:id/new' },

  // Training History
  { name: 'td-history', path: '/training-history' },

  // Test Attempts
  {
    name: 'test-attempts',
    path: '/test-attempts/:id'
  },
  {
    name: 'test-attempts-score-report',
    path: '/test-attempts/:id/score-report'
  },
  {
    name: 'test-attempts-trainer-score-report',
    path: '/test-attempts/:id/trainer-score-report'
  },

  // Test Preparations
  {
    name: 'test-preparations',
    path: '/test-preparations/:id'
  },
  {
    name: 'test-preparations-score-report',
    path: '/test-preparations/:id/score-report'
  },

  // Test Templates
  {
    name: 'test-template-resources',
    path: '/test-template/:id/prep-resources'
  },

  // User
  { name: 'users-login', path: '/users/login' },
  { name: 'users-forgot-password', path: '/users/forgot-password' },
  { name: 'users-resend-email', path: '/users/resend-email' },
  { name: 'users-activate-pk', path: '/users/activate-product-key' },
  { name: 'users-cm-login', path: '/users/course-merchant/login' },
  { name: 'users-cm-register', path: '/users/course-merchant/register' },

  // User Token
  { name: 'user-token', path: '/tokens/:token' },
  { name: 'user-token-invitation', path: '/invitations/:token' },

  // Token Confirmation
  { name: 'token-confirmation', path: '/token-confirmation' },

  // User Choice Product Key Token
  { name: 'user-choice-product-key', path: '/user-choice-product-key' },

  // Learning Pathway Product Key Token
  {
    name: 'learning-pathway-product-key',
    path: '/learning-pathway-product-key'
  },

  // Observer Recertification Product Key Token
  {
    name: 'recertification-product-key',
    path: '/recertification-product-key'
  },

  // Certifications
  { name: 'certifications', path: '/certifications' },
  { name: 'certifications-summary', path: '/certifications/organization' },
  {
    name: 'certifications-observers',
    path: '/certifications/organization/observers'
  },
  {
    name: 'certifications-my-certifications',
    path: '/certifications/my-certifications'
  },

  // Organization
  { name: 'organization', path: '/organization' },
  { name: 'organization-add-members', path: '/organization/add-members' },
  { name: 'organization-members', path: '/organization/members' },
  { name: 'organization-members-id', path: '/organization/members/:memberId' },
  { name: 'organization-hierarchy', path: '/organization/hierarchy' },
  {
    name: 'organization-subscriptions',
    path: '/organization/subscriptions'
  },
  {
    name: 'organization-subscriptions-learning-resources',
    path: '/organization/subscriptions/:id/learning-resources'
  },
  {
    name: 'organization-subscriptions-data-collection',
    path: '/organization/subscriptions/:id/users'
  },
  {
    name: 'organization-subscriptions-invite',
    path: '/organization/subscriptions/:id/invite-users'
  },
  {
    name: 'organization-subscriptions-coach-groups',
    path: '/organization/subscriptions/:id/coach-groups/:coachId'
  },

  // Data Collection
  { name: 'measure', path: '/measure' },
  { name: 'measure-create', path: '/measure/new' },
  {
    name: 'measure-create-template',
    path: '/measure/new?template_id=:template_id'
  },
  { name: 'measure-view', path: '/measure/:id' },
  { name: 'measure-view-edit', path: '/measure/:id/edit' },
  { name: 'measure-view-details', path: '/measure/:id/details' },
  { name: 'measure-view-environment', path: '/measure/:id/environment' },
  { name: 'measure-view-review', path: '/measure/:id/review' },
  { name: 'measure-summary', path: '/measure/:id/summary' },
  { name: 'measure-completed', path: '/measure/:id/completed' },

  // Data Collection (CLASS Assessment Specific)
  { name: 'measure-view-cycle', path: '/measure/:id/cycles/:cycleId' },
  { name: 'measure-view-video', path: '/measure/:id/videos/:videoId' },
  { name: 'measure-examples-notes', path: '/measure/:id/examples-and-notes' },

  // Observation Videos
  { name: 'measure-videos', path: '/measure/observation-videos' },
  { name: 'measure-videos-view', path: '/measure/observation-videos/:id' },

  //Reports observation
  { name: 'reports-observation', path: '/reports/observation' },
  { name: 'reports-observation-new', path: '/reports/observation/new' },
  { name: 'reports-observation-edit', path: '/reports/observation/:id' },

  /* External Application Links */

  // myCLASS
  { name: 'myclass-user', path: `${MY_CLASS_URL}/en/users/:id` },
  {
    name: 'myclass-user-history',
    path: `${MY_CLASS_URL}/en/users_history/:id`
  },
  { name: 'myclass-pk_activation', path: `${MY_CLASS_URL}/self_registration` },
  {
    name: 'myclass-calibration',
    path: `${MY_CLASS_URL}/:lang/pages/calibrations`
  },
  { name: 'myclass-certification', path: `${MY_CLASS_URL}/:lang/certificates` },
  {
    name: 'myclass-contract_admin',
    path: `${MY_CLASS_URL}/:lang/pages/contract_admin`
  },
  { name: 'myclass-myclass_admin', path: `${MY_CLASS_URL}/:lang/pages/admin` },
  {
    name: 'myclass-my_trainings',
    path: `${MY_CLASS_URL}/:lang/pages/my_trainings`
  },
  {
    name: 'myclass-training_portal',
    path: `${MY_CLASS_URL}/:lang/pages/trainer_resources`
  },
  {
    name: 'myclass-video_coaching',
    path: `${MY_CLASS_URL}/:lang/pages/video_coaching/:id`
  },
  {
    name: 'myclass-video_library',
    path: `${MY_CLASS_URL}/:lang/pages/video_library`
  },
  { name: 'myclass-training-user', path: `${MY_CLASS_URL}/users/:id` },

  // myTeachstone
  { name: 'myts-observations', path: `${MY_TEACHSTONE_URL}/observations` },
  { name: 'myts-resources', path: `${MY_TEACHSTONE_URL}/resources/all` },
  {
    name: 'myts-user-account',
    path: `${MY_TEACHSTONE_URL}/accounts/users/:id`
  },

  // Community and Canvas
  { name: 'community', path: `${COMMUNITY_URL}` },
  { name: 'canvas', path: `${CANVAS_URL}` },
  { name: 'canvas-course', path: `${CANVAS_URL}/courses/:id` },
  { name: 'canvas-user', path: `${CANVAS_URL}/accounts/1/users/:id` },

  // myCoach Connect
  { name: 'mycoach-connect', path: MY_COACH_CONNECT_URL },

  /* Improvements */
  {
    name: 'improvement-educator-profile',
    path: '/improvement/educator-profile/:id'
  },
  { name: 'improvement-home', path: '/improvement' },

  // Learning Resources
  { name: 'learning-resources-detail-page', path: '/learning-resources/:id' },
  {
    name: 'learning-resources-preferences',
    path: '/learning-resources/preferences'
  },
  { name: 'learning-resources', path: '/learning-resources' },
  {
    name: 'learning-resources-playlist',
    path: '/learning-resources/playlist/:id'
  },
  {
    name: 'coach-learning-resources-detail-page',
    path: '/coach/learning-resources/:id'
  },
  { name: 'coach-learning-resources', path: '/coach/learning-resources' },
  {
    name: 'coach-learning-resources-see-all',
    path: '/coach/learning-resources/see-all'
  },
  {
    name: 'coach-learning-resources-recommend',
    path: '/coach/learning-resources/recommend?educator_guid=:guid'
  },
  {
    name: 'coach-learning-resources-playlist',
    path: '/coach/learning-resources/playlist/:id'
  },
  {
    name: 'coach-learning-resources-detail-page',
    path: '/coach/learning-resources/:id'
  }
] as const;

export default routes;
