const PATH = 'fe.components.measure.custom_questions';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Custom Questions'
  }
};

export default messages;
