import { useEffect, useState } from 'react';

type BaseState = {
  query: string;
  sort_by: string | null;
  sort_dir: 'desc' | 'asc' | null;
};

type TableStateProps<StateType extends BaseState> = {
  search: string;
  state: StateType;
  setState: (state: Partial<StateType>) => void;
  setPage: (page: number) => void;
};

export function useTableState<T extends BaseState>({
  search,
  state,
  setState,
  setPage
}: TableStateProps<T>) {
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    setState({ query: search } as Partial<T>);
    setSelected([]);
  }, [search, setState]);

  function sortBy(header: string) {
    if (header === state.sort_by && state.sort_dir === 'asc') {
      _setState({ sort_dir: 'desc' } as Partial<T>);
    } else if (header === state.sort_by && state.sort_dir === 'desc') {
      _setState({ sort_by: null, sort_dir: null } as Partial<T>);
    } else {
      _setState({ sort_by: header, sort_dir: 'asc' } as Partial<T>);
    }
  }

  function toggleSelected(value: number) {
    setSelected(prevSelected =>
      prevSelected.includes(value)
        ? prevSelected.filter(id => id !== value)
        : [...prevSelected, value]
    );
  }

  function _setState(newState: Partial<T>) {
    setState(newState);
    setSelected([]);
  }

  function _setPage(newPage: number) {
    setPage(newPage);
    setSelected([]);
  }

  return {
    selected,
    setSelected,
    toggleSelected,
    setSortBy: sortBy,
    setState: _setState,
    setPage: _setPage
  };
}
