import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

type MembershipTrackerRecord = {
  id: number;
  subscription_id: number;
  product_name: string;
  role: string;
  status: 'accepted' | 'invited' | 'no_invitation';
  enrolled_on: string;
  invited_on: string;
};

type GetMembershipTrackersResponse = {
  membership_trackers: MembershipTrackerRecord[];
  pagination: { total_pages: number; current_page: number };
};

export function getUserMembershipTrackers(userId: number) {
  return axios.get<GetMembershipTrackersResponse>(
    `/api/v2/users/${userId}/membership_trackers`
  );
}

export function useGetUserMembershipTrackers(userId: number) {
  return useQuery({
    queryKey: ['users', userId, 'membershipTrackers'],
    queryFn: () => getUserMembershipTrackers(userId).then(res => res.data)
  });
}

type MembershipTracker = {
  id: number;
  user_id: number;
  name: string;
  email: string;
  role: string;
  invite_status: 'accepted' | 'invited' | 'no_invitation';
  invited_on: string;
};

export type GetSubscriptionMembershipTrackers = {
  membership_trackers: MembershipTracker[];
  pagination: { total_pages: number; current_page: number };
};

export function getSubscriptionMembershipTrackers(
  subscriptionId: number,
  params: any = {}
) {
  return axios.get<GetSubscriptionMembershipTrackers>(
    `/api/v2/subscriptions/${subscriptionId}/membership_trackers`,
    {
      params
    }
  );
}

export function useGetSubscriptionMembershipTrackers(
  subscriptionId: number,
  params: any = {}
) {
  return useQuery({
    queryKey: ['subscriptions', subscriptionId, 'membership_trackers', params],
    queryFn: () =>
      getSubscriptionMembershipTrackers(subscriptionId, params).then(
        response => response.data
      )
  });
}

export function removeUsersFromSubscription(
  subscriptionId: number,
  trackerIds: number[]
) {
  return axios.delete(
    `/api/v2/subscriptions/${subscriptionId}/membership_trackers/bulk_destroy`,
    {
      data: { membership_tracker_ids: trackerIds }
    }
  );
}
