const PATH =
  'fe.components.organization.view_subscription_users.tables.lr_coach_groups_table';

const messages = {
  columns: {
    nameAndEmail: {
      key: `${PATH}.columns.name_and_email`,
      defaultValue: 'Name & Email'
    },
    educators: {
      key: `${PATH}.columns.educators`,
      defaultValue: 'Educators'
    }
  },
  view: {
    key: `${PATH}.view`,
    defaultValue: 'View ({{count}})'
  },
  actions: {
    delete: {
      key: `${PATH}.actions.delete`,
      defaultValue: 'Delete Coach Groups ({{count}})'
    },
    deleteSuccess: {
      key: `${PATH}.actions.delete_success`,
      defaultValue: 'Coach Group(s) deleted successfully.'
    },
    deleteError: {
      key: `${PATH}.actions.delete_error`,
      defaultValue: 'Error deleting Coach Group(s). Please try again later.'
    },
    deselect: {
      key: `${PATH}.actions.deselect`,
      defaultValue: 'Deselect All'
    }
  }
};

export default messages;
