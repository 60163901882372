import axios from 'axios';
import {
  DEFAULT_PER_PAGE,
  DEFAULT_CURRENT_PAGE
} from 'pages/LearningResources/utils';
import { useQuery } from '@tanstack/react-query';

type Coaching = {
  coach_id: number;
  educator_id: number;
};

export function getMyEducators() {
  return axios.get('/api/v2/coachings');
}

export function getEducatorRecommendations(
  educatorGuid: string | undefined,
  perPage: number = DEFAULT_PER_PAGE,
  currentPage: number = DEFAULT_CURRENT_PAGE
) {
  return axios.get(
    `/api/v2/coachings/recommendations_by_educator?educator_guid=${educatorGuid}&per_page=${perPage}&page=${currentPage}`
  );
}

export function createCoachings(subscriptionId: number, coachings: Coaching[]) {
  return axios.post(`/api/v2/coachings`, {
    subscription_id: subscriptionId,
    coachings
  });
}

export function getEducatorsCoachGroup(
  subscriptionId: number,
  coachId: number,
  params: any = {}
) {
  return axios.get(
    `/api/v2/coachings/${coachId}?subscription_id=${subscriptionId}`,
    {
      params
    }
  );
}

export function useGetEducatorsCoachGroup(
  subscriptionId: number,
  coachId: number,
  params: any = {}
) {
  return useQuery({
    queryKey: ['coachings', subscriptionId, coachId, params],
    queryFn: () =>
      getEducatorsCoachGroup(subscriptionId, coachId, params).then(
        response => response.data
      )
  });
}

export function updateCoachings(subscriptionId: number, coachings: Coaching[]) {
  return axios.put(`/api/v2/coachings`, {
    subscription_id: subscriptionId,
    coachings
  });
}

export function deleteCoachGroup(coachIds: number[], subscriptionId: number) {
  return axios.delete(`/api/v2/coachings`, {
    params: {
      coach_id: coachIds,
      subscription_id: subscriptionId
    }
  });
}
