import axios from './axiosConfig';
import { ObservationReportType } from 'types/api/envScales/Reports';

type Domain = {
  name: string;
  t_name: string;
  abbreviation: string;
};

type Dimension = {
  name: string;
  t_name: string;
  abbreviation: string;
};

export type ScoreAverage = {
  value: number;
  domain: Domain;
  dimension?: Dimension;
};

type UserReportParams = {
  report_configuration_id?: number;
  target?: string;
};

type ClassroomParams = {
  report_configuration_id?: number;
};

export type GetAveragesRes = {
  total_observations: number;
  scores: ScoreAverage[];
};

export function getAverages(params: UserReportParams) {
  return axios.get('/api/v1/reports/observations/averages', { params });
}

export type Node = {
  id: number;
  name: string;
  scores: ScoreAverage[];
  children: Node[];
  archived: boolean;
};

export type GetAveragesByNodeRes = {
  total_observations: number;
  nodes: Node[];
};

export type GetAveragesByClassroomRes = {
  observations: ObservationReportType[];
};

export function getAveragesByNode(params: UserReportParams) {
  return axios.get('/api/v1/reports/observations/averages_by_node', { params });
}

export function getAveragesByClassroom(params: ClassroomParams) {
  return axios.get(
    '/api/v1/reports/observations/classroom_level_observations',
    {
      params
    }
  );
}

export function getObservationsCount(params = {}) {
  // NOTE: The POST method is used here to allow multiple values to be passed for node_id
  return axios.post('/api/v1/reports/observations/count', { params });
}

export function createReportConfiguration(params = {}) {
  return axios.post('/api/v1/reports/configurations', params);
}

export function getReportConfigurations() {
  return axios.get('/api/v1/reports/configurations');
}

export function getReportConfigurationById(id: number) {
  return axios.get(`/api/v1/reports/configurations/${id}`);
}

export function updateReportConfiguration(id: number, params = {}) {
  return axios.put(`/api/v1/reports/configurations/${id}`, params);
}
