import i18n from 'helpers/i18n';
import messages from './messages';
import { RadioInput } from './Styled';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { useRouteParams } from 'hooks/useRouteParams';
import { createUserAnswers, deleteUserAnswers } from 'actions/userAnswers';
import { useKnowledgeTest } from './KnowledgeTestContext';

type AnswerOptions = {
  user_answer_id?: number;
  checked: boolean;
  description: string;
  id: number;
};

interface SingleAnswerProps {
  answer_options: AnswerOptions[];
  category: string | undefined;
  questionId: number;
}

type URLParams = {
  id: string;
};

function SingleAnswer({
  answer_options: answerOptions,
  category,
  questionId
}: SingleAnswerProps) {
  const { id } = useRouteParams<URLParams>();
  const { updater } = useKnowledgeTest();
  const [currentQuestionState, setCurrentQuestionState] =
    useState(answerOptions);
  const [previousAnswerId, setPreviousAnswerId] = useState<
    number | undefined
  >();
  const [currentAnswerId, setCurrentAnswerId] = useState<number | undefined>();
  const [isProcessing, setIsProcessing] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    setCurrentQuestionState(answerOptions);
    const previousAnswerSet = answerOptions.filter(el => {
      return el.user_answer_id !== undefined;
    });
    if (previousAnswerSet.length > 0) {
      setPreviousAnswerId(previousAnswerSet[0].id);
      setCurrentAnswerId(previousAnswerSet[0].id);
    } else {
      setPreviousAnswerId(undefined);
      setCurrentAnswerId(undefined);
    }
  }, [answerOptions]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // prettier-ignore
  function handleCheckboxInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.dataset.answerId === undefined || isProcessing) {
      return;
    }

    const answerId = parseInt(event.currentTarget.dataset.answerId);
    setIsProcessing(true);

    const userAnswer = {
      question_id: questionId,
      question_answer_id: answerId
    };

    const selectedAnswerSet = currentQuestionState.find(
      el => el.id === answerId
    );

    const previousAnswerSet = currentQuestionState.find(
      el => el.id === previousAnswerId
    );

    if (!selectedAnswerSet) {
      setIsProcessing(false);
      return;
    }

    setPreviousAnswerId(answerId);

    const deletePrevious = previousAnswerSet?.user_answer_id
      ? deleteUserAnswers(id, previousAnswerSet.user_answer_id).catch(() => {
          toast.error(i18n.ft(messages.error));
        })
      : Promise.resolve();

    deletePrevious
      .then(() => {
        if (!isMounted.current) return;

        return createUserAnswers(id, userAnswer)
          .then(response => {
            if (!isMounted.current) return;

            const newAnswerSetState = currentQuestionState.map(el => ({
              ...el,
              checked: el.id === answerId,
              user_answer_id:
                el.id === answerId ? response.data.id : el.user_answer_id
            }));

            setCurrentQuestionState(newAnswerSetState);
            setCurrentAnswerId(answerId);
          })
          .catch(() => {
            toast.error(i18n.ft(messages.error));
          });
      })
      .finally(() => {
        if (!isMounted.current) return;
        // refresh context
        updater();
        setIsProcessing(false);
      });
  }

  return (
    <div className="pt-4 text-base">
      {answerOptions &&
        answerOptions?.map((answer: AnswerOptions, idx: number) => {
          return (
            <div
              key={answer.id}
              className="grid grid-cols-12 items-center mb-8"
            >
              <div className="pl-2 col-span-1">
                <RadioInput
                  id={`question_${answer.id}`}
                  name={`question_${questionId}`}
                  className="align-middle"
                  data-answer-id={answer.id}
                  onChange={handleCheckboxInputChange}
                  checked={answer.id === currentAnswerId}
                  error={false}
                />
              </div>
              <div className="ml-4 col-span-11 pl-2 md:pl-0">
                <label
                  htmlFor={`question_${answer.id}`}
                  className=" cursor-pointer"
                >
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: answer.description
                      }}
                    ></span>
                  }
                </label>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default SingleAnswer;
