const PATH = 'fe.components.organization.csv_member_upload_modal';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Invite New Users via CSV'
  },
  directions: {
    key: `${PATH}.directions`,
    defaultValue: 'Directions'
  },
  uploadText: {
    key: `${PATH}.upload_text`,
    defaultValue:
      'Download and use our CSV template and instructions to make sure your data is formatted correctly.'
  },
  template: {
    key: `${PATH}.template`,
    defaultValue: 'Download CSV Template'
  },
  complete: {
    key: `${PATH}.complete`,
    defaultValue: 'Once complete, upload your .CSV file below.'
  },
  drop: {
    key: `${PATH}.drop`,
    defaultValue: 'Drag and drop your file here'
  },
  or: {
    key: `${PATH}.or`,
    defaultValue: 'or'
  },
  browse: {
    key: `${PATH}.browse`,
    defaultValue: 'browse'
  },
  file: {
    key: `${PATH}.file`,
    defaultValue: 'Selected file:'
  },
  upload: {
    key: `${PATH}.upload`,
    defaultValue: 'Upload'
  },
  success: {
    key: `${PATH}.success`,
    defaultValue: 'File uploaded successfully!'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue: 'Error uploading file. Please try again later.'
  },
  noFile: {
    key: `${PATH}.no_file`,
    defaultValue: 'No file selected!'
  }
};

export default messages;
