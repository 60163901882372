import styled from 'styled-components';

export const Section = styled.section`
  padding: 24px 0px;
  background-color: ${p => p.theme.colors.white};

  @media screen and (min-width: 640px) {
    padding: 24px 32px;
  }

  @media screen and (min-width: 768px) {
    padding: 24px 48px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: 1024px) {
    padding: 80px 80px;
    margin: 0 48px 5px;
  }
`;

export const DimensionScoreIcon = styled.div<{ passed?: boolean }>`
  font-weight: 700;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${p =>
    p.passed
      ? p.theme.colors.reliability.passVideo
      : p.theme.colors.reliability.failedAttempt};
`;

export const MessageBox = styled.div`
  color: ${props => props.theme.colors.scaleDarkBlue};
  background-color: ${props => props.theme.colors.scaleLightSky};
`;

export const ListItem = styled.li`
  color: rgb(249, 152, 8);
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
`;

export const ListItemContent = styled.span`
  color: ${p => p.theme.colors.scaleDarkBlue};
  left: 16px;
  position: relative;
`;
