import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Subscription } from 'types/api/Subscription';
import { SubscriptionSeat } from 'types/api/SubscriptionSeat';
import { Product } from 'types/api/Product';

export function getSubscriptions() {
  return axios.get(`/api/v2/subscriptions`);
}

export function getUserSubscriptions(userId: number) {
  return axios.get(`/api/v2/users/${userId}/subscriptions`);
}

export function getSubscription(id: number) {
  return axios.get<Subscription>(`/api/v2/subscriptions/${id}`);
}

export function useGetSubscription(id: number) {
  return useQuery({
    queryKey: ['subscriptions', id],
    queryFn: () => getSubscription(id).then(res => res.data)
  });
}

type GetSubscriptionUsersParams = {
  id: number;
  params?: Object;
};

type GetSubscriptionUsersResponse = {
  subscription_users: SubscriptionSeat[];
  pagination: {
    total_pages: number;
    current_page: number;
  };
};

type GetSubscriptionUsersByRoleParams = {
  id: number;
  role: String;
};

type GetSubscriptionUsersByRoleResponse = {
  subscription_users: SubscriptionSeat[];
};

export function getSubscriptionUsers({
  id,
  params = {}
}: GetSubscriptionUsersParams) {
  return axios.get<GetSubscriptionUsersResponse>(
    `/api/v2/subscriptions/${id}/users`,
    { params }
  );
}

export function getSubscriptionUsersByRole({
  id,
  role
}: GetSubscriptionUsersByRoleParams) {
  return axios.get<GetSubscriptionUsersByRoleResponse>(
    `/api/v2/subscriptions/${id}/users_by_role/${role}`
  );
}

export function useGetSubscriptionUsers(params: GetSubscriptionUsersParams) {
  return useQuery({
    queryKey: ['subscriptions', params.id, 'users', params],
    queryFn: () => getSubscriptionUsers(params).then(res => res.data)
  });
}

export function getSubscriptionUser(subscriptionId: number, userId: number) {
  return axios.get(`/api/v2/subscriptions/${subscriptionId}/users/${userId}`);
}

export function createSubscriptionUser(subscriptionId: number, params = {}) {
  return axios.post(`/api/v2/subscriptions/${subscriptionId}/users`, params);
}

export function updateSubscriptionUser(
  subscriptionId: number,
  userId: number,
  params = {}
) {
  return axios.put(
    `/api/v2/subscriptions/${subscriptionId}/users/${userId}`,
    params
  );
}

export function deleteSubscriptionUser(subscriptionId: number, userId: number) {
  return axios.delete(
    `/api/v2/subscriptions/${subscriptionId}/users/${userId}`
  );
}

export function deleteSubscription(id: number) {
  return axios.delete(`/api/v2/subscriptions/${id}`);
}

export function getMyCoachConnectTags() {
  return axios.get(`/api/v2/subscriptions/my_coach_connect_tags`);
}

export function createSubscription(subscription = {}) {
  return axios.post('/api/v2/subscriptions', { subscription });
}

export function createSubscriptionInvites(subscriptionId: number, params = {}) {
  return axios.post(`/api/v2/subscriptions/${subscriptionId}/invite`, params);
}

export function createAccountSubscription(
  accountId: number,
  subscription = {}
) {
  return axios.post(`/api/v2/accounts/${accountId}/subscriptions`, {
    subscription
  });
}

export function updateAccountSubscription(
  accountId: number,
  subscriptionId: number,
  subscription = {}
) {
  return axios.put(
    `/api/v2/accounts/${accountId}/subscriptions/${subscriptionId}`,
    {
      subscription
    }
  );
}

/** Used in Manage Organization Subscriptions */
export function getManageableAccountSubscriptions(params = {}) {
  return axios.get<{ subscriptions: Subscription[] }>(
    `/api/v2/ca/subscriptions`,
    { params }
  );
}

export type CASubscription = {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  product: Product;
};

export function getCASubscription(id: number) {
  return axios.get<CASubscription>(`/api/v2/ca/subscriptions/${id}`);
}

export function useGetCASubscription(id: number) {
  return useQuery({
    queryKey: ['ca', 'subscriptions', id],
    queryFn: () => getCASubscription(id).then(res => res.data)
  });
}

export function uploadCsvImport(subscriptionId: number, file: File) {
  const data = new FormData();
  data.append('file', file);
  return axios.post(
    `/api/v2/ca/subscriptions/${subscriptionId}/csv_import`,
    data
  );
}

type GetUnsubscribeCountResponse = {
  lr_admin: number;
  lr_coach: number;
  lr_educator: number;
};

export function getUnsubscribeCount(subscriptionId: number, params = {}) {
  return axios.get<GetUnsubscribeCountResponse>(
    `/api/v2/ca/subscriptions/${subscriptionId}/unsubscribe_count`,
    { params }
  );
}
