import i18n from 'helpers/i18n';
import messages from './messages';
import debounce from 'debounce';
import PageLoader from 'components/PageLoader';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'components/Organization/Input';
import { useRouteParams } from 'hooks/useRouteParams';
import { BackgroundSVG } from '../Members/BackgroundSVG';
import { UserStatusSelect } from '../Members/UserStatusSelect';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { useGetCASubscription, CASubscription } from 'actions/subscriptions';
import { DCUsersTable } from './tables/DCUsersTable';
import { LRUsersTable } from './tables/LRUsersTable';
import { LRCoachGroupsTable } from './tables/LRCoachGroupsTable';
import { UnsubscribedUsersTable } from './tables/UnsubscribedUsersTable';
import { TABLE_TYPES, getTableTypeOptions, getTableFilterRoles } from './utils';

interface ViewSubscriptionUsersProps {
  subscription: CASubscription;
}

function ViewSubscriptionUsers({ subscription }: ViewSubscriptionUsersProps) {
  const tableOptions = getTableTypeOptions(subscription.product.name);
  const filterRoles = getTableFilterRoles(subscription.product.name);
  const [tableType, setTableType] = useState(tableOptions[0].value);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  function renderTable() {
    switch (tableType) {
      case TABLE_TYPES.DC_ACTIVE_USERS:
        return (
          <DCUsersTable
            searchQuery={searchQuery}
            subscriptionId={subscription.id}
          />
        );
      case TABLE_TYPES.LR_ACTIVE_USERS:
        return (
          <LRUsersTable
            searchQuery={searchQuery}
            subscriptionId={subscription.id}
          />
        );
      case TABLE_TYPES.UNSUBSCRIBED_USERS:
        return (
          <UnsubscribedUsersTable
            searchQuery={searchQuery}
            subscriptionId={subscription.id}
            filterRoles={filterRoles}
          />
        );
      case TABLE_TYPES.LR_COACH_GROUPS:
        return (
          <LRCoachGroupsTable
            searchQuery={searchQuery}
            subscriptionId={subscription.id}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div className="my-8 relative isolate">
      <BackgroundSVG className="absolute left-0 right-0 -mx-6 xl:-mx-14 z-0" />

      <div className="z-10 relative">
        <div className="pt-6 pb-4 md:pb-6">
          <h2 className="font-sans font-bold text-3xl text-white">
            {subscription.product.display_name}
          </h2>
        </div>

        <div className="p-6 pb-12 bg-white rounded-2xl">
          <div className="flex justify-between items-center">
            <UserStatusSelect
              value={tableType}
              onChange={setTableType}
              options={tableOptions}
            />

            <div className="flex gap-4 ml-auto">
              <div className="relative">
                <Input
                  type="search"
                  className="max-w-94 min-w-80"
                  onChange={debounce(handleSearch, 500)}
                  placeholder={i18n.ft(messages.searchPlaceholder)}
                  aria-label={i18n.ft(messages.searchPlaceholder)}
                />
                <i className="fa fa-search absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              </div>

              <Link
                to={`/organization/subscriptions/${subscription.id}/invite-users`}
              >
                <PrimaryButton icon="fa-solid fa-plus">
                  {i18n.ft(messages.inviteUsers)}
                </PrimaryButton>
              </Link>
            </div>
          </div>

          <div className="mt-4">{renderTable()}</div>
        </div>
      </div>
    </div>
  );
}

type URLParams = {
  id: string;
};

function ViewSubscriptionUsersController() {
  const params = useRouteParams<URLParams>();
  const subscriptionId = parseInt(params.id);

  const { data: subscription, isSuccess } =
    useGetCASubscription(subscriptionId);

  if (!isSuccess) {
    return <PageLoader />;
  }

  return <ViewSubscriptionUsers subscription={subscription} />;
}

export default ViewSubscriptionUsersController;
