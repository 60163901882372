import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { toTitleCase } from 'helpers/text';
import { Table } from 'components/Organization/Table';
import { useGetCAUserCertifications } from 'actions/certifications';

interface CertificationsTableProps {
  userId: number;
}

export function CertificationsTable({ userId }: CertificationsTableProps) {
  const { data, isLoading } = useGetCAUserCertifications(userId);
  const certifications = data ?? [];

  function formatDate(date?: string) {
    if (!date) {
      return '--';
    }

    return moment(date).format('L');
  }

  function getStatusMessage(status: string) {
    switch (status) {
      case 'transitioned':
        return i18n.ft(messages.statusMessage.transitioned);
      case 'deprecated':
        return i18n.ft(messages.statusMessage.renewed);
      case 'active_renewable':
        return i18n.ft(messages.statusMessage.activeRenewable);
      case 'expired_renewable':
        return i18n.ft(messages.statusMessage.expiredRenewable);
      case 'expired':
        return i18n.ft(messages.statusMessage.expired);
      default:
        return i18n.ft(messages.statusMessage.active);
    }
  }

  const rows = certifications.map(certification => ({
    name: toTitleCase(certification.name),
    age_level: certification.age_level,
    status: getStatusMessage(certification.status),
    expires_on: formatDate(certification.expires_on)
  }));

  return (
    <div className="font-sans bg-white p-6 rounded-2xl mt-6">
      <div className="font-bold text-xl md:text-2xl">
        {i18n.ft(messages.header)}
      </div>

      <div className="mt-10">
        <Table
          isLoading={isLoading}
          headers={[
            {
              accessor: 'name',
              content: i18n.ft(messages.certificationName)
            },
            { accessor: 'age_level', content: i18n.ft(messages.ageLevel) },
            { accessor: 'status', content: i18n.ft(messages.status) },
            { accessor: 'expires_on', content: i18n.ft(messages.expiresOn) }
          ]}
          data={rows}
        />
      </div>
    </div>
  );
}
