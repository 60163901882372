const PATH = 'fe.pages.reports.observation';

const messages = {
  archived: {
    key: `${PATH}.archived`,
    defaultValue: 'Archived'
  },
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Observation Reports'
  },
  download: {
    key: `${PATH}.download`,
    defaultValue: 'Download'
  },
  pdf: {
    key: `${PATH}.pdf`,
    defaultValue: 'PDF'
  },
  programReport: {
    key: `${PATH}.program_report`,
    defaultValue: 'Program Report'
  },
  createReport: {
    key: `${PATH}.create_report`,
    defaultValue: 'Create New Report'
  },
  editReport: {
    key: `${PATH}.edit_report`,
    defaultValue: 'Edit This Report'
  },
  reportContains: {
    key: `${PATH}.report_contains`,
    defaultValue: 'Your Report Contains'
  },
  fields: {
    ageLevel: {
      key: `${PATH}.fields.age_level`,
      defaultValue: 'Age Level'
    },
    ageLevelPlaceholder: {
      key: `${PATH}.fields.age_level_placeholder`,
      defaultValue: 'Select observation age level'
    },
    date: {
      key: `${PATH}.fields.date`,
      defaultValue: 'Date'
    },
    hierarchy: {
      key: `${PATH}.fields.hierarchy`,
      defaultValue: 'Hierarchy Selection'
    }
  },
  supportHeader: {
    key: `${PATH}.support_header`,
    defaultValue: 'This screen size is not supported yet.'
  },
  supportBody: {
    key: `${PATH}.support_body`,
    defaultValue: 'Please use a device with 1024px or more.'
  },
  completed: {
    key: `${PATH}.completed`,
    defaultValue: 'Completed'
  },
  domains: {
    key: `${PATH}.domains`,
    defaultValue: 'Domains'
  },
  dimensions: {
    key: `${PATH}.dimensions`,
    defaultValue: 'Dimensions'
  },
  mustSelectAccount: {
    key: `${PATH}.must_select_account`,
    defaultValue: 'You must select an account.'
  },
  mustHaveConfiguration: {
    key: `${PATH}.must_have_configuration`,
    defaultValue: 'Please Create a Report to view your CLASS Data.'
  }
};

export default messages;
