import React from 'react';
import i18n from 'helpers/i18n';
import messages from './messages';
import colors from 'styles/colors';
import { Pie, PieChart } from 'recharts';
import AgeLevel from 'components/AgeLevel';
import Typography from 'components/Typography';
import { AGE_LEVEL_ABBREVIATIONS } from 'types/api/AgeLevel';

import {
  Container,
  Header,
  ChartContainer,
  ChartLabel,
  LegendContainer,
  LegendItem,
  ChartFooter,
  FooterDetail,
  DetailValue,
  DetailLabel
} from './Styled';
import getPassLabel from '../utils';
import { TestType } from 'types/api/testType';

interface OverallChartProps {
  /** The number of users who passed on the first attempt. */
  attempt01: number;

  /** The number of users who passed on the second attempt. */
  attempt02: number;

  /** The number of users who passed on the third attempt. */
  attempt03: number;

  /** The number of users who took the test. */
  totalTaken: number;

  /** Age level abbreviation. */
  abbreviation: AGE_LEVEL_ABBREVIATIONS;

  /** Test type. */
  testType?: TestType;
}

const OverallChart: React.FC<OverallChartProps> = ({
  attempt01,
  attempt02,
  attempt03,
  totalTaken,
  abbreviation,
  testType = 'reliability'
}) => {
  const atLeastOneTaken = totalTaken > 0;
  const totalPassed = attempt01 + attempt02 + attempt03;
  const failed = atLeastOneTaken ? totalTaken - totalPassed : 1;
  const reliability = atLeastOneTaken ? (totalPassed / totalTaken) * 100 : 0;

  const chartData = [
    {
      name: 'attempt1',
      value: attempt01,
      fill: colors.reliability.attempt01,
      stroke: colors.reliability.attempt01
    },
    {
      name: 'attempt2',
      value: attempt02,
      fill: colors.reliability.attempt02,
      stroke: colors.reliability.attempt02
    },
    {
      name: 'attempt3',
      value: attempt03,
      fill: colors.reliability.attempt03,
      stroke: colors.reliability.attempt03
    },
    {
      name: 'failed',
      value: failed,
      fill: colors.reliability.failed,
      stroke: colors.reliability.failed
    }
  ];

  const header =
    testType === 'knowledge'
      ? messages.knowledge.header
      : messages.reliability.header;

  const passLabel = getPassLabel(testType);

  return (
    <Container abbreviation={abbreviation}>
      <Header>
        <Typography bold size="huge">
          {i18n.ft(header)}
        </Typography>
        <AgeLevel replaceK3 abbreviation={abbreviation} />
      </Header>

      <ChartContainer>
        <PieChart
          width={150}
          height={150}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <Pie
            data={chartData}
            dataKey="value"
            cx={75}
            cy={75}
            innerRadius={50}
            outerRadius={70}
            startAngle={90}
            endAngle={-270}
            isAnimationActive={false}
          />
        </PieChart>

        <ChartLabel>{reliability.toFixed(0)}%</ChartLabel>
      </ChartContainer>

      <LegendContainer>
        <LegendItem color={colors.reliability.attempt01}>
          {i18n.ft(messages.attemptNumber, { value: 1 })}
        </LegendItem>
        <LegendItem color={colors.reliability.attempt02}>
          {i18n.ft(messages.attemptNumber, { value: 2 })}
        </LegendItem>
        <LegendItem color={colors.reliability.attempt03}>
          {i18n.ft(messages.attemptNumber, { value: 3 })}
        </LegendItem>
      </LegendContainer>

      <ChartFooter>
        <FooterDetail>
          <DetailValue>{totalPassed}</DetailValue>
          <DetailLabel>{i18n.ft(messages[passLabel])}</DetailLabel>
        </FooterDetail>
        <FooterDetail>
          <DetailValue>/</DetailValue>
        </FooterDetail>
        <FooterDetail>
          <DetailValue>{totalTaken}</DetailValue>
          <DetailLabel>{i18n.ft(messages.tested)}</DetailLabel>
        </FooterDetail>
      </ChartFooter>
    </Container>
  );
};

export default OverallChart;
