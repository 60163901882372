const PATH = 'fe.pages.organization.view_member.subscriptions';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Subscriptions'
  },
  productName: {
    key: `${PATH}.product_name`,
    defaultValue: 'Product Name'
  },
  role: {
    key: `${PATH}.role`,
    defaultValue: 'Role'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status'
  },
  invitedOn: {
    key: `${PATH}.invited_on`,
    defaultValue: 'Invited On'
  },
  enrolledOn: {
    key: `${PATH}.enrolled_on`,
    defaultValue: 'Enrolled On'
  }
};

export default messages;
