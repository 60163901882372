import styled, { css } from 'styled-components';
import {
  addBorderRadius,
  addSpace,
  SpaceProps,
  BorderProps
} from 'components/Box/system';

export const SectionHeader = styled.h2`
  color: ${p => p.theme.colors['neutral']['700']};
  background-color: #f4f8fa;
  display: inline-block;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;

  @media screen and (min-width: 640px) {
    font-size: 24px;
    line-height: 32px;
    padding: 12px 20px;
  }
`;

interface SectionProps {
  error?: boolean;
  shadowOnHover?: boolean;
  thinPadding?: boolean;
}

export const Section = styled.section<SpaceProps & BorderProps & SectionProps>`
  ${addSpace};
  ${addBorderRadius};
  padding: 24px 16px;
  background-color: #ffffff;

  ${p =>
    p.error &&
    css`
      border: 1px solid ${p => p.theme.colors.scaleWarning};
    `};

  ${p =>
    p.shadowOnHover &&
    css`
      &:hover {
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      }
    `};

  @media screen and (min-width: 640px) {
    padding: 24px;
  }

  @media screen and (min-width: 1280px) {
    padding: 24px;

    ${p =>
      p.thinPadding &&
      css`
        padding: 24px 48px;
      `};
  }
`;

export const SectionContent = styled.div<{ expanded?: boolean }>`
  margin-top: 16px;
  display: ${p => (p.expanded !== undefined && !p.expanded ? 'none' : 'block')};

  @media screen and (min-width: 640px) {
    margin-top: 24px;
    padding: 0 56px 20px;
  }
`;

export const Wrapper = styled.div`
  padding: 10px 20px;
  box-shadow: 1px 1px 11px #d7e6ed;
  border-radius: 12px;
  color: #364a5e;

  @media screen and (min-width: 640px) {
    padding: 24px 48px;
  }
`;

export const CustomQuestion = styled.strong`
  font-weight: bold;
`;

export const CustomQuestionAnswer = styled.div`
  margin-top: 8px;
`;
