import axios from './axiosConfig';
import axiosOnelogin from 'axios';

import {
  TARGET_TYPE,
  ObservationFeedback
} from 'types/api/envScales/ObservationFeedback';

export type GetObservationFeedbackRes = {
  questions_with_answers: ObservationFeedback[];
};

export function getObservationFeedback(
  observationId: number,
  targetType: TARGET_TYPE
) {
  return axios.get<GetObservationFeedbackRes>(
    `/api/v1/observations/${observationId}/observation_feedbacks?target_type=${targetType}`
  );
}

export function answerQuestion(
  observationId: number,
  questionId: number,
  payload: any = {}
) {
  return axios.put(
    `/api/v1/observations/${observationId}/observation_feedbacks/${questionId}`,
    payload
  );
}

type CustomQuestionOption = {
  id: number;
  option: string;
  account_custom_question_id: number;
};

type CustomQuestion = ObservationFeedback & {
  question_type: 'free_text' | 'single_option';
  options: CustomQuestionOption[];
};

export type GetCustomQuestionsRes = {
  questions_with_answers: CustomQuestion[];
};

export function getCustomQuestions(assessmentId: number) {
  return axiosOnelogin.get(
    `/api/v3/client/data_collection/assessments/${assessmentId}/custom_questions`
  );
}

export function answerCustomQuestion(
  assessmentId: number,
  questionId: number,
  payload = {}
) {
  return axiosOnelogin.put(
    `/api/v3/client/data_collection/assessments/${assessmentId}/custom_questions/${questionId}`,
    payload
  );
}
