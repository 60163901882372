import i18n from 'helpers/i18n';
import messages from '../DetailPage/messages';
import { Tag } from 'types/api/Tag';
import { useEffect, useState } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { getHoursMinutesSeconds } from '../utils';
import { useRouteParams } from 'hooks/useRouteParams';
import { USER_ENGAGEMENT_EVENT_VERBS } from 'constants/userEngagementEventVerbs';
import { AspectRatio } from 'components/KalturaVideo/Styled';
import { getConfiguration, getDomId } from 'pages/Testing/Reliability/utils';
import { VideoPlayerContainer } from 'components/ReliabilityTest/CodingVideos/Styled';
import useUserEngagementEvent from 'hooks/useUserEngagementEvent';
import { getCurrentLearningResource } from './utils';
import RouteHelpers from 'helpers/routes';
import DimensionTagLabel from 'components/LearningResources/DimensionTagLabel';
import NotesSection from 'components/LearningResources/NotesSection';
import SupportingAssets from 'components/LearningResources/SupportingAssets';
import ProgressBar from 'components/LearningResources/Playlist/ProgressBar';
import Playlist from 'components/LearningResources/Playlist/Playlist';
import { LearningTracks, PlaylistRecord } from 'types/LearningResources/types';
import {
  getPlaylistItems,
  startPlaylistTrack,
  completePlaylistTrack
} from 'actions/learningResources';
import MarkAsImportant from 'components/LearningResources/MarkAsImportant';

type ParamsType = {
  id: string;
};

export type PlaylistCurrentTrack = {
  current_track_id: number;
};

// TO DO: move to util when BE json is finalized
const findDimensionTag = (tags?: Tag[]): Tag | undefined => {
  let dimensionTag = tags?.find(t => t.group?.name === 'dimension');
  return dimensionTag;
};

function PlaylistDetailPage() {
  const [playlist, setPlaylist] = useState<
    PlaylistRecord & PlaylistCurrentTrack
  >();
  const [playbackHasBegun, setPlaybackHasBegun] = useState(false);
  const [playbackHasEnded, setPlaybackHasEnded] = useState(false);
  const [currentLearningResource, setCurrentLearningResource] =
    useState<LearningTracks>();
  const { id: playListID } = useRouteParams<ParamsType>();
  const { id: userId, roles } = useCurrentUser();

  const kalturaPlayerID = getDomId(`${playListID ? playListID : 1}`);

  /* when video playback is initiated, transmit the user event */
  useUserEngagementEvent({
    objectId: currentLearningResource?.learning_resources.id,
    objectType: 'LearningResourceRecord',
    userId,
    verb: USER_ENGAGEMENT_EVENT_VERBS.BEGINS_PLAYBACK,
    ready: playbackHasBegun
  });

  /* when video is watched to the end, transmit the user event */
  useUserEngagementEvent({
    objectId: currentLearningResource?.learning_resources.id,
    objectType: 'LearningResourceRecord',
    userId,
    verb: USER_ENGAGEMENT_EVENT_VERBS.COMPLETES,
    ready: playbackHasEnded
  });

  /* load data */
  useEffect(() => {
    if (!playListID) {
      return;
    }

    getPlaylistItems(parseInt(playListID))
      .then(response => {
        const data = response.data;
        setPlaylist(data);
        setCurrentLearningResource(getCurrentLearningResource(data));
      })
      .catch(() => {});
  }, [playListID]);

  /* set up the video player */
  useEffect(() => {
    if (!currentLearningResource) {
      return;
    }

    /* configure a video player */
    const kalturaPlayer = (window as any).KalturaPlayer?.setup(
      getConfiguration(kalturaPlayerID)
    );

    /* load the video */
    kalturaPlayer.loadMedia({
      entryId: currentLearningResource.learning_resources.video_uri
    });

    /* listen for the first play event and log it */
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.FIRST_PLAYING,
      (_e: unknown) => {
        setPlaybackHasBegun(true);
      }
    );

    // CALL createFirstViewsEvent (from utils.ts) here

    /* listen for the play back ended event and log it */
    kalturaPlayer.addEventListener(
      kalturaPlayer.Event.Core.PLAYBACK_ENDED,
      (_e: unknown) => {
        setPlaybackHasEnded(true);
      }
    );

    /* clean up */
    return () => {
      const kalturaPlayer = (window as any).KalturaPlayer?.getPlayer(
        kalturaPlayerID
      );
      kalturaPlayer?.destroy();
    };
  }, [kalturaPlayerID, currentLearningResource, userId]);

  useEffect(() => {
    if (!playbackHasEnded) {
      return;
    }

    if (currentLearningResource) {
      completePlaylistTrack(parseInt(playListID), currentLearningResource.id)
        .then(response => {
          const data = response.data;
          setPlaylist(data);
          setCurrentLearningResource(getCurrentLearningResource(data));
          setPlaybackHasEnded(false);
        })
        .catch(() => {});
    }
  }, [currentLearningResource, playListID, playbackHasEnded]);

  useEffect(() => {
    if (!playbackHasBegun) {
      return;
    }

    startPlaylistTrack(parseInt(playListID))
      .then(response => {
        setPlaybackHasBegun(false);
      })
      .catch(() => {});
  }, [playListID, playbackHasBegun]);

  function isCoach() {
    return roles.includes('lr_coach');
  }

  if (!playlist || !currentLearningResource) {
    return <></>;
  }

  return (
    <>
      {!isCoach() && (
        <div className="font-bold text-[#0A9CCA] cursor-pointer">
          <a href={RouteHelpers.getPath('learning-resources')}>
            {`< ${i18n.ft(messages.back)}`}
          </a>
        </div>
      )}
      <div className="mt-12" style={{ maxWidth: '1360px' }}>
        <h1 className="px-4 text-xl md:text-3xl md:px-0">
          {currentLearningResource.learning_resources.name}
        </h1>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 xl:col-span-8 bg-[#F5F5F5]">
          <div className="">
            <div className="flex justify-center mt-4">
              <AspectRatio />
              <VideoPlayerContainer id={kalturaPlayerID} isTablet={false} />
            </div>
            <div className="mt-4 mx-4 md:mt-0 md:mx-0">
              <div className="bg-white p-4 pb-8">
                <div className="text-base">
                  <span className="font-bold text-black">
                    {i18n.ft(messages.time)}
                  </span>
                  <span className="pl-4">
                    {getHoursMinutesSeconds(
                      currentLearningResource.learning_resources.estimated_time
                    )}
                  </span>
                </div>

                <MarkAsImportant
                  learningResourceID={
                    currentLearningResource.learning_resources.id
                  }
                  userId={userId}
                />

                <DimensionTagLabel tag={findDimensionTag(playlist.tags)} />
              </div>
              <div className="bg-white p-4 pb-6 mt-6 md:mt-0">
                <NotesSection
                  notes={currentLearningResource.learning_resources.notes}
                />
                {currentLearningResource.learning_resources.instructions && (
                  <NotesSection
                    notes={
                      currentLearningResource.learning_resources.instructions
                    }
                    title={i18n.ft(messages.instructions)}
                  />
                )}
                {currentLearningResource.learning_resources.indicators && (
                  <NotesSection
                    notes={
                      currentLearningResource.learning_resources.indicators
                    }
                    title={i18n.ft(messages.indicators)}
                  />
                )}
                {currentLearningResource.learning_resources
                  .resource_description && (
                  <NotesSection
                    notes={
                      currentLearningResource.learning_resources
                        .resource_description
                    }
                    title={i18n.ft(messages.description)}
                  />
                )}
                {currentLearningResource.learning_resources.learning_goals && (
                  <NotesSection
                    notes={
                      currentLearningResource.learning_resources.learning_goals
                    }
                    title={i18n.ft(messages.goals)}
                  />
                )}
                {playlist.assets ? (
                  <SupportingAssets assets={playlist.assets} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 xl:col-span-4">
          <div className="mt-4 mx-4 md:mt-0 md:mx-0">
            <div className="mt-4 pb-4 text-xl font-bold">{playlist.name}</div>
            <div className="text-sm font-normal leading-4 pb-4 mb-4 border-slate-400 border-b-2">
              {/* <RichTextEditor readOnly={true} value={playlist.notes} /> */}
            </div>

            <ProgressBar percentage={playlist.completion_percentage} />

            <Playlist
              playlist={playlist}
              currentVideoId={currentLearningResource.learning_resources.id}
              setCurrentLearningResource={setCurrentLearningResource}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlaylistDetailPage;
