import colors from 'styles/colors';
import { Pie, PieChart, Cell } from 'recharts';
import { Score } from 'pages/Testing/Reliability/ScoreReport/PerformanceReportSection/Styled';

interface ShowScoresProps {
  scores: number[];
  subtitle: string;
}

const chartData = [
  {
    value: 100,
    fill: colors.reliability.passVideo,
    stroke: colors.reliability.passVideo
  }
];

function ShowScores({ scores, subtitle }: ShowScoresProps) {
  const hasScores = scores ? scores.length : 0;

  if (hasScores === 0) {
    return <></>;
  }

  return (
    <>
      <div className="ml-2 md:ml-0 flex my-6 text-scaleDarkBlue font-bold text-[20px] leading-6">
        {subtitle}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 items-center justify-center mx-auto">
        {scores.map((score: number, idx: number) => {
          return (
            <div
              key={idx}
              className={`mx-auto text-center ${
                scores.length === idx + 1 ? 'col-span-2 md:col-auto' : ''
              }`}
            >
              <PieChart
                width={122}
                height={122}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Pie
                  data={chartData}
                  dataKey="value"
                  cx={60}
                  cy={60}
                  innerRadius={40}
                  outerRadius={55}
                  startAngle={90}
                  endAngle={-270}
                  isAnimationActive={false}
                >
                  {chartData.map((_entry: any, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        score >= 80
                          ? colors.reliability.passVideo
                          : colors.reliability.noPassVideo
                      }
                      stroke={
                        score >= 80
                          ? colors.reliability.passVideo
                          : colors.reliability.noPassVideo
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
              <div className="mt-2 mb-8 PrintMargin">Video {idx + 1}</div>
              <Score className="VideoScore">{score}%</Score>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ShowScores;
