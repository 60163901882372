import styled from 'styled-components';

export const Score = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.scaleDarkBlue};
  width: 100%;
  text-align: center;
  position: relative;
  top: -135px;
`;
