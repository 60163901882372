const PATH = 'fe.components.organization.coach_autocomplete';

const messages = {
  loading: {
    key: `${PATH}.loading`,
    defaultValue: 'Loading...'
  },
  noResults: {
    key: `${PATH}.no_results`,
    defaultValue: 'No results found'
  },
  coach: {
    key: `${PATH}.coach`,
    defaultValue: 'Coach:'
  },
  placeholder: {
    key: `${PATH}.placeholder`,
    defaultValue: 'Enter email address'
  }
};

export default messages;
