import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import MainContent from 'components/MainContent';
import { Formik, Form } from 'components/EnhancedForm';
import { FormikHelpers } from 'formik';
import SelectWithTag from 'components/EnhancedForm/SelectWithTag';
import RouteHelpers from 'helpers/routes';
import moment from 'moment';
import useAsync from 'hooks/useAsync';
import useCurrentUser from 'hooks/useCurrentUser';
import { getAssessmentTemplates } from 'actions/envScales/assessmentTemplates';
import { getReportSettings, getSettings } from 'actions/envScales/settings';
import { AssessmentTemplate } from 'types/api/envScales/AssessmentTemplate';
import Warning from 'components/Measure/Warning';
import {
  hasDataEntryRole,
  hasDcAdminRole
} from 'components/Measure/Home/CreateButton/utils';
import ObserversList from 'components/Measure/Create/ObserversList';
import { useActiveSubscriptions } from 'context/auth';
import {
  classAgeLevels,
  environmentsAgeLevels,
  class2EditionAgeLevels
} from 'constants/ageLevels';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { useDCLanguages } from 'components/dropdowns/Languages';
import { CentersAndClassroomsGetter } from './CentersAndClassroomsGetter';

import {
  Button,
  Loader,
  DropdownItemProps,
  InputOnChangeData,
  ButtonProps
} from 'semantic-ui-react';

import { useLocation, useNavigate, Link, Navigate } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';

import InputWithTag, {
  TagElement,
  TextTag,
  UserTag
} from 'components/Measure/Create/InputWithTag';

import {
  PageWrapper,
  FormWrapper,
  FormFields,
  ButtonWrapper,
  SectionFooter,
  Select,
  DateInput,
  CenterWrapper,
  FormFieldsRow,
  Checkbox,
  Input
} from './Styled';

import {
  initialValues,
  initialEditValues,
  validateDate,
  validateTeachers,
  getTimezoneOptions
} from './utils';

import {
  createAssessment,
  getAssessment,
  updateAssessment,
  deleteAssessment
} from 'actions/envScales/assessments';

import { toastSuccess } from 'components/Toast';
import { Assessment } from 'types/api/envScales/Assessment';
import GradesDropdown from 'components/dropdowns/Grades';
import { useQueryClient } from '@tanstack/react-query';
import { getAccountEducators, getAccountNodeEducators } from 'actions/accounts';
import { useMeasure } from 'context/measure';

type URLParams = {
  id: string;
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface CreateProps {
  /** If `true`, applies changes to work alongisde sidebar navigation. */
  isContainedInSidenav?: boolean;
}

function Create({ isContainedInSidenav }: CreateProps) {
  const languages = useDCLanguages();
  const currentUser = useCurrentUser();
  const { id } = useRouteParams<URLParams>();
  const queryClient = useQueryClient();

  // context only available when editing assessments
  const measureCtx = useMeasure({ skipContextCheck: true });

  const {
    data: assessment,
    setData: setAssessment,
    run: assessmentRun,
    isPending: loading
  } = useAsync();

  const [dateWarnings, setDateWarnings] = useState<string[]>([]);
  const [teacherWarnings, setTeacherWarnings] = useState<string[]>([]);

  const accountId = currentUser.current_account_id || 0;
  const subscriptions = useActiveSubscriptions();

  const navigate = useNavigate();
  const isEditing = Boolean(id);
  const query = useQuery();
  const cycleNumberOptions = Array.from(Array(6).keys()).map(n => ({
    value: n + 1,
    text: n + 1
  }));

  const accountGuid = subscriptions.find(
    s => s.subscription.owner_id === accountId
  )?.subscription.owner_guid;

  const {
    data: assessmentTemplatesData,
    run: assessmentTemplateRun,
    isSuccess: assessmentTemplateIsSuccess
  } = useAsync();

  const {
    run: runAccountSettings,
    data: accountSettings,
    isPending: isPendingAccountSetting
  } = useAsync();

  const {
    run: runReportSettings,
    data: reportSettings,
    isPending: isPendingReportSettings
  } = useAsync();

  const templateId = isEditing
    ? assessment?.assessment_template_id
    : query.get('template_id');
  const envScalesTemplate =
    assessmentTemplateIsSuccess &&
    assessmentTemplatesData.assessment_templates.find(
      (at: AssessmentTemplate) => at.env_scales
    );
  const classTemplate =
    assessmentTemplateIsSuccess &&
    assessmentTemplatesData.assessment_templates.find(
      (at: AssessmentTemplate) => !at.env_scales && at.class_version === 1
    );
  const class2ndEditionTemplate =
    assessmentTemplateIsSuccess &&
    assessmentTemplatesData.assessment_templates.find(
      (at: AssessmentTemplate) => !at.env_scales && at.class_version === 2
    );
  const isEnvScales = envScalesTemplate?.id === Number(templateId);
  const isClass = classTemplate?.id === Number(templateId);
  const isClass2ndEdition = class2ndEditionTemplate?.id === Number(templateId);
  const isDataEntryUser = hasDataEntryRole(subscriptions, accountId);
  const isDcAdminUser = hasDcAdminRole(subscriptions, accountId);
  const requireObserver = isDataEntryUser;

  let options: DropdownItemProps[] = [];
  if (isEnvScales) {
    options = environmentsAgeLevels;
  } else if (isClass2ndEdition) {
    options = class2EditionAgeLevels;
  } else if (isClass) {
    options = classAgeLevels;
  }

  let yupValidationObject = {
    taken_at: yup.date().required(() => i18n.ft(messages.errors.date.required)),
    number_of_cycles: yup.number().optional(),
    center_guid: yup
      .string()
      .required(() => i18n.ft(messages.errors.fieldRequired)),
    classroom_guid: yup
      .string()
      .required(() => i18n.ft(messages.errors.fieldRequired)),
    observer_guid: yup.string().optional(),
    job_id: yup.string().optional(),
    teachers: yup.array(yup.object()).optional()
  };
  if (isClass || isClass2ndEdition) {
    yupValidationObject.number_of_cycles = yup
      .number()
      .required(i18n.ft(messages.errors.fieldRequired));
  }
  if (requireObserver) {
    yupValidationObject.observer_guid = yup
      .string()
      .required(() => i18n.ft(messages.errors.fieldRequired));
  }

  const isJobIdRequired =
    accountSettings?.account_setting?.require_job_id &&
    (isClass || isClass2ndEdition);

  if (isJobIdRequired) {
    yupValidationObject.job_id = yup
      .string()
      .required(() => i18n.ft(messages.errors.fieldRequired));
  }

  const isEnvironmentsEnabled =
    accountSettings?.account_setting?.environments_enabled;

  const isTeacherNameRequired =
    accountSettings?.account_setting?.require_teacher_name;

  const presetCycleEnabled =
    accountSettings?.account_setting?.preset_cycle_enabled;

  if (isTeacherNameRequired) {
    yupValidationObject.teachers = yup
      .array(yup.object())
      .min(1, () => i18n.ft(messages.errors.fieldRequired))
      .required(() => i18n.ft(messages.errors.fieldRequired));
  }

  const isVideoObservation = accountSettings?.account_setting?.video_enabled;
  const validationSchema = yup.object(yupValidationObject);

  const displayExternalIds =
    reportSettings?.account_report_setting.display_external_id;

  useEffect(() => {
    if (accountGuid) {
      runAccountSettings(getSettings(accountGuid));
      runReportSettings(getReportSettings(accountGuid));
    }
  }, [accountGuid, runAccountSettings, runReportSettings]);

  useEffect(() => {
    assessmentTemplateRun(getAssessmentTemplates());
  }, [assessmentTemplateRun]);

  useEffect(() => {
    if (id) {
      assessmentRun(getAssessment(parseInt(id)));
    }
  }, [assessmentRun, id]);

  function getInitialValues(assessment: Assessment) {
    const numOfCycles = accountSettings?.account_setting?.preset_cycle_count;

    return isEditing
      ? initialEditValues(assessment)
      : initialValues(options, numOfCycles);
  }

  function getCancelProps(isDirty: boolean): ButtonProps {
    if (isContainedInSidenav) {
      return {
        type: 'reset',
        disabled: !isDirty
      };
    }

    const cancelPath = isEditing
      ? RouteHelpers.getPath('measure-view', { id })
      : RouteHelpers.getPath('measure');

    return {
      type: 'button',
      as: Link,
      to: cancelPath
    };
  }

  function getSubmitProps(isDirty: boolean): ButtonProps {
    if (isContainedInSidenav) {
      return {
        disabled: !isDirty
      };
    }

    return {};
  }

  function handleSubmit(values: any, formik: FormikHelpers<any>) {
    const teachers = values.teachers
      .filter((tag: TagElement) => tag.type === 'text')
      .map((tag: TextTag) => tag.value);

    const educators = values.teachers
      .filter((tag: TagElement) => tag.type === 'user')
      .map((tag: UserTag) => tag.guid);

    const submitedValues = {
      ...values,
      taken_at: moment(values.taken_at, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      assessment_template_id: query.get('template_id'),
      educator_guids: educators,
      teachers
    };

    if (values.double_coding) {
      submitedValues.primary_observer = values.primary_observer === 'primary';
    }

    if (isEditing) {
      updateAssessment(parseInt(id), submitedValues).then(response => {
        if (isContainedInSidenav) {
          toastSuccess();
          setAssessment(response.data);
          formik.resetForm({ values: getInitialValues(response.data) });
          measureCtx?.setAssessment(response.data);
        } else {
          const redirectURL =
            assessment.status === 'awaiting_approval'
              ? RouteHelpers.getPath('measure-summary', { id })
              : RouteHelpers.getPath('measure-view', { id });

          navigate(redirectURL);
        }
      });
    } else {
      createAssessment(submitedValues).then(
        data => {
          navigate(RouteHelpers.getPath('measure-view', { id: data.data.id }));
        },
        e => {
          const dateError = e.response.data?.errors?.taken_at;
          formik.setFieldError('taken_at', dateError);
          formik.setSubmitting(false);
        }
      );
    }
  }

  // Disable support for creating new standalone "environments" assessments
  if (isEnvScales && !isEditing) {
    return <Navigate replace to={RouteHelpers.getPath('measure')} />;
  }

  if (isEditing && assessment && assessment.status === 'completed') {
    return (
      <Navigate
        replace
        to={RouteHelpers.getPath('measure-completed', { id })}
      />
    );
  }

  if (
    isEditing &&
    assessment &&
    assessment.created_by_guid !== currentUser.guid &&
    assessment.observer_guid !== currentUser.guid &&
    isDcAdminUser &&
    assessment.status !== 'awaiting_approval'
  ) {
    return <Navigate replace to={RouteHelpers.getPath('measure')} />;
  }

  function checkDate(_e: React.SyntheticEvent, data: InputOnChangeData) {
    setDateWarnings(validateDate(data.value));
  }

  function checkTeachers(tags: TagElement[]) {
    setTeacherWarnings(validateTeachers(tags));
  }

  function deleteObservation() {
    deleteAssessment(assessment.id).then(() => {
      navigate(RouteHelpers.getPath('measure'), { replace: true });
    });
  }

  const customSearch = (options: any, query: any) => {
    return options.filter((option: any) => {
      const { title, subtitle } = option.content.props;
      if (title.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      if (subtitle && subtitle.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      return false;
    });
  };

  async function handleTeacherInputChange(query: string) {
    const data = await queryClient.fetchQuery({
      queryKey: ['accounts', accountId, 'educators', query],
      queryFn: () =>
        getAccountEducators(accountId, { query }).then(
          response => response.data
        )
    });

    return data.account_users.map((aUser: any) => ({
      guid: aUser.user.guid,
      title: aUser.user.name,
      description: aUser.user.email
    }));
  }

  async function autoFillTeachers(
    classroomId: number,
    setFieldValue: any,
    values: any
  ) {
    const data = await queryClient.fetchQuery({
      queryKey: ['accounts', accountId, 'classrooms', classroomId, 'educators'],
      queryFn: () => {
        return getAccountNodeEducators(accountId, classroomId).then(
          response => response.data
        );
      }
    });

    const teacherTags = data.account_users.map((teacher: any) => ({
      type: 'user',
      guid: teacher.user.guid,
      value: teacher.user.name
    }));

    const newTags = teacherTags
      .filter(
        (tag: any) => !values.teachers.some((t: any) => t.guid === tag.guid)
      )
      .slice(0, 10);

    setFieldValue('teachers', [...values.teachers, ...newTags]);
  }

  const TeacherWarningWrapper = isTeacherNameRequired
    ? React.Fragment
    : (p: any) => (
        <Warning
          warningTexts={teacherWarnings}
          maxWidth={290}
          dismissible
          onDismiss={() => setTeacherWarnings([])}
        >
          {p.children}
        </Warning>
      );

  return (
    <MainContent maxWidth={1280}>
      <PageWrapper>
        <div className="my-5">
          <div className="font-bold text-2xl pl-6 md:pl-8 xl:pl-16">
            {id ? i18n.ft(messages.update) : i18n.ft(messages.start)}
          </div>
        </div>

        {(id && loading) ||
        !assessmentTemplateIsSuccess ||
        isPendingAccountSetting ||
        isPendingReportSettings ? (
          <Loader active inline="centered" />
        ) : (
          <Formik
            onSubmit={handleSubmit}
            initialValues={getInitialValues(assessment)}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values, dirty, isSubmitting }) => (
              <Form noValidate="novalidate">
                <FormWrapper>
                  <FormFields>
                    <Warning
                      warningTexts={dateWarnings}
                      maxWidth={290}
                      dismissible
                      onDismiss={() => setDateWarnings([])}
                    >
                      <DateInput
                        required
                        name="taken_at"
                        label={i18n.ft(messages.fields.date)}
                        placeholder={i18n.ft(messages.fields.datePlaceholder)}
                        iconPosition="right"
                        errorLabel={{ textAlign: 'left' }}
                        icon="calendar alternate outline"
                        autoComplete="off"
                        onChange={checkDate}
                      />
                    </Warning>

                    {(isClass || isClass2ndEdition) && (
                      <Select
                        name="number_of_cycles"
                        required
                        label={i18n.ft(messages.fields.numberOfCycles)}
                        placeholder={i18n.ft(
                          messages.fields.numberOfCyclesPlaceholder
                        )}
                        options={cycleNumberOptions}
                        disabled={isEditing || presetCycleEnabled}
                        errorLabel={{ textAlign: 'left' }}
                      />
                    )}

                    <CentersAndClassroomsGetter
                      accountId={accountId}
                      displayExternalIds={displayExternalIds}
                    >
                      {({ centers, classrooms, centerTags, classroomTag }) => (
                        <>
                          <CenterWrapper>
                            <SelectWithTag
                              disabled={isEditing && isVideoObservation}
                              name="center_guid"
                              label={i18n.ft(messages.fields.center)}
                              placeholder={i18n.ft(
                                messages.fields.centerPlaceholder
                              )}
                              required
                              options={centers}
                              tagName={centerTags.name}
                              subTagName={centerTags.hierarchy}
                              selectOnNavigation={false}
                              selectOnBlur={false}
                              onChange={() => {}}
                              customSearch={customSearch}
                              cleanOption={() => {
                                setFieldValue('center_guid', '');
                                setFieldValue('classroom_guid', '');
                              }}
                            />
                          </CenterWrapper>

                          <SelectWithTag
                            disabled={isEditing && isVideoObservation}
                            name="classroom_guid"
                            label={i18n.ft(messages.fields.classroom)}
                            placeholder={i18n.ft(
                              messages.fields.classroomPlaceholder
                            )}
                            required
                            fluid
                            options={classrooms}
                            tagName={classroomTag}
                            selectOnNavigation={false}
                            selectOnBlur={false}
                            onChange={(classroomGuid: string) => {
                              return autoFillTeachers(
                                classrooms.find(
                                  classroom => classroom.value === classroomGuid
                                )?.key,
                                setFieldValue,
                                values
                              );
                            }}
                            customSearch={customSearch}
                            cleanOption={() => {
                              setFieldValue('classroom_guid', '');
                            }}
                          />
                        </>
                      )}
                    </CentersAndClassroomsGetter>

                    <TeacherWarningWrapper>
                      <InputWithTag
                        name="teachers"
                        label={i18n.ft(messages.fields.teachers)}
                        placeholder={i18n.ft(
                          messages.fields.teachersPlaceholder
                        )}
                        maxElements={10}
                        maxInputLength={20}
                        onTagChange={checkTeachers}
                        onBlur={checkTeachers}
                        required={isTeacherNameRequired}
                        fetchOptions={handleTeacherInputChange}
                      />
                    </TeacherWarningWrapper>

                    <Select
                      name="age_level"
                      label={i18n.ft(messages.fields.classAssessment)}
                      options={options}
                      disabled={isEditing}
                      $addVerticalMargin
                    />

                    <Select
                      name="language"
                      label={i18n.ft(messages.fields.language)}
                      options={languages}
                    />

                    {(isClass || isClass2ndEdition) && (
                      <Select
                        multiple
                        name="grade_id"
                        control={GradesDropdown}
                        label={i18n.ft(messages.fields.gradeLevel)}
                        placeholder={i18n.ft(
                          messages.fields.gradeLevelPlaceholder
                        )}
                      />
                    )}

                    {requireObserver && <ObserversList accountId={accountId} />}

                    {(isClass || isClass2ndEdition) && (
                      <Input
                        fluid
                        errorLabel={{ textAlign: 'left' }}
                        required={isJobIdRequired}
                        name="job_id"
                        label={i18n.ft(messages.fields.jobID)}
                        placeholder={i18n.ft(messages.fields.jobPlaceholder)}
                      />
                    )}

                    <Select
                      search
                      name="local_time_zone"
                      label={i18n.ft(messages.fields.timeZone)}
                      placeholder={i18n.ft(messages.fields.timeZonePlaceholder)}
                      options={getTimezoneOptions()}
                    />
                  </FormFields>

                  {isEnvironmentsEnabled && (isClass2ndEdition || isClass) && (
                    <FormFieldsRow>
                      <Checkbox
                        name="includes_environments"
                        label={i18n.ft(messages.fields.includesEnvironment)}
                      />
                    </FormFieldsRow>
                  )}

                  {(isClass || isClass2ndEdition) && (
                    <FormFieldsRow>
                      <Checkbox
                        name="double_coding"
                        label={i18n.ft(messages.fields.doubleCoding)}
                      />
                      {values.double_coding && (
                        <div>
                          <Checkbox
                            radio
                            name="primary_observer"
                            value="primary"
                            label={i18n.ft(messages.fields.primaryObserver)}
                          />
                          <Checkbox
                            radio
                            name="primary_observer"
                            value="secondary"
                            label={i18n.ft(messages.fields.secondaryObserver)}
                          />
                        </div>
                      )}
                    </FormFieldsRow>
                  )}
                </FormWrapper>

                <SectionFooter>
                  {isEditing && !isVideoObservation && (
                    <ConfirmationModal
                      title={i18n.ft(messages.modal.title)}
                      message={i18n.ft(messages.modal.warning)}
                      confirmButton={i18n.ft(messages.modal.delete)}
                      icon="archive"
                      triggerNode={
                        <ButtonWrapper>
                          <Button fluid color="red" type="button">
                            {i18n.ft(messages.delete)}
                          </Button>
                        </ButtonWrapper>
                      }
                      onConfirm={({ closeModal }) => {
                        deleteObservation();
                        closeModal();
                      }}
                    />
                  )}

                  <ButtonWrapper alignRight>
                    <Button fluid basic {...getCancelProps(dirty)}>
                      {i18n.ft(messages.cancel)}
                    </Button>
                  </ButtonWrapper>

                  <ButtonWrapper alignTop>
                    <Button
                      fluid
                      color="blue"
                      type="submit"
                      loading={isSubmitting}
                      {...getSubmitProps(dirty)}
                    >
                      {isEditing
                        ? i18n.ft(messages.update)
                        : i18n.ft(messages.start)}
                    </Button>
                  </ButtonWrapper>
                </SectionFooter>
              </Form>
            )}
          </Formik>
        )}
      </PageWrapper>
    </MainContent>
  );
}

export default Create;
