import i18n from 'helpers/i18n';
import messages from './messages';
import * as Dialog from '@radix-ui/react-alert-dialog';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { SecondaryButton } from 'components/Organization/SecondaryButton';

interface UnsubscribeAlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
  rolesWithWarnings: {
    lr_coach: number;
    lr_educator: number;
  };
}

export function UnsubscribeAlertDialog({
  open,
  onOpenChange,
  onConfirm,
  rolesWithWarnings
}: UnsubscribeAlertDialogProps) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-50 " />
        <Dialog.Content className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-xl translate-x-[-50%] translate-y-[-50%] gap-4 border bg-white p-6 shadow-lg sm:rounded-lg">
          <Dialog.Title className="font-sans text-black text-2xl mb-0">
            {i18n.ft(messages.dialog.title)}
          </Dialog.Title>

          <Dialog.Description className="font-sans text-black">
            {i18n.ft(messages.dialog.message01) + ' '}
            <strong>
              {i18n.ft(messages.dialog.message02, {
                count: rolesWithWarnings.lr_educator ?? 0
              }) + ' '}
            </strong>
            {i18n.ft(messages.dialog.message03) + ' '}
            <strong>
              {i18n.ft(messages.dialog.message04, {
                count: rolesWithWarnings.lr_coach ?? 0
              }) + ' '}
            </strong>
            {i18n.ft(messages.dialog.message05)}
          </Dialog.Description>

          <div className="flex gap-2 justify-end">
            <Dialog.Cancel asChild>
              <SecondaryButton size="small">
                {i18n.ft(messages.dialog.cancel)}
              </SecondaryButton>
            </Dialog.Cancel>

            <Dialog.Action asChild onClick={onConfirm}>
              <PrimaryButton size="small">
                {i18n.ft(messages.dialog.continue)}
              </PrimaryButton>
            </Dialog.Action>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
