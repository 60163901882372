import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { roleToText } from 'helpers/role';
import { Popup } from 'components/Organization/Popup';

interface ActivityRowProps {
  /** Action type. */
  action: string;

  /** User who executed the action. */
  fromUser: string;

  /** User who received the action. */
  toUser: string;

  /** Date the action took place. */
  timestamp: string;

  /** Details of the action taken. */
  details?: {
    oldValue: any;
    newValue: any;
  };
}

export function ActivityRow({
  action,
  details,
  toUser,
  fromUser,
  timestamp
}: ActivityRowProps) {
  function renderActionText(action: string, receiver: string) {
    return (
      <>
        <span>{action}</span>
        <span className="font-bold">{receiver}</span>
      </>
    );
  }

  function getActionText() {
    switch (action) {
      case 'member_added':
        return renderActionText(i18n.ft(messages.memberAdded), toUser);
      case 'member_archived':
        return renderActionText(i18n.ft(messages.memberArchived), toUser);
      case 'member_unarchived':
        return renderActionText(i18n.ft(messages.memberUnarchived), toUser);
      case 'role_updated':
        return renderActionText(
          i18n.ft(messages.memberUpdated),
          i18n.ft(messages.role)
        );
      case 'node_updated':
        return renderActionText(
          i18n.ft(messages.memberUpdated),
          i18n.ft(messages.hierarchy)
        );
      default:
        return 'unknown';
    }
  }

  function renderDetailValue(value: any) {
    const isNilOrEmpty = value === null || value === '';

    return isNilOrEmpty ? (
      <span className="text-charcoal-400">{i18n.ft(messages.none)}</span>
    ) : (
      <span>{value}</span>
    );
  }

  function renderDetails() {
    if (action === 'role_updated') {
      return (
        <div className="flex items-center gap-4 mt-2">
          {renderDetailValue(roleToText(details?.oldValue))}
          <i className="fa-solid fa-arrow-right" />
          {renderDetailValue(roleToText(details?.newValue))}
        </div>
      );
    }

    if (action === 'node_updated') {
      return (
        <div className="flex items-center gap-4 mt-2">
          {renderDetailValue(details?.oldValue.join(', '))}
          <i className="fa-solid fa-arrow-right" />
          {renderDetailValue(details?.newValue.join(', '))}
        </div>
      );
    }

    return null;
  }

  const date = moment(timestamp);

  return (
    <div className="relative py-4 last:pb-0 first:pt-0 flex gap-6 items-center border-l border-navy-100">
      <div className="h-4 w-4 rounded-full border-2 border-white bg-action absolute -left-2" />

      <div className="pl-8">
        <div className="flex gap-4">
          <div className="flex gap-1.5">
            <span className="font-bold">{fromUser}</span>
            {getActionText()}
          </div>

          <Popup label={date.format('LLL')}>
            <span className="text-charcoal-400">{date.fromNow()}</span>
          </Popup>
        </div>

        {renderDetails()}
      </div>
    </div>
  );
}
