const PATH = 'fe.components.organization.leave_modal';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Leave without inviting users?'
  },
  content: {
    key: `${PATH}.content`,
    defaultValue:
      'If you leave this page without clicking “Invite Users” first, the users will not be invited and your work will be lost.'
  },
  no: {
    key: `${PATH}.no`,
    defaultValue: 'No, Cancel'
  },
  yes: {
    key: `${PATH}.yes`,
    defaultValue: 'Leave without inviting'
  }
};

export default messages;
