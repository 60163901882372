import clsx from 'clsx';
import i18n from 'helpers/i18n';
import messages from './messages';
import IconButton from 'components/IconButton';
import { useReportTotal } from './utils';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, DropdownItemProps, Icon } from 'semantic-ui-react';
import { useAccountHierarchy } from 'pages/Reports/Observation';
import { HierarchyInput, SelectInput, StepSection } from './styled';
import { FormValues } from 'pages/Reports/ObservationV2/Observation';
import DateRangePicker from 'components/Reports/Observation/DateRangePicker/DateRangePicker';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';

interface CollapsibleConfigurationFormProps {
  /** User Report Configuration form values. */
  values: FormValues;

  /** Callback fired when one of the values change. */
  onChange: (values: Partial<FormValues>) => void;

  /** Options for age level dropdown. */
  ageLevelOptions: DropdownItemProps[];

  /** Options for class version dropdown. */
  classTemplateOptions: DropdownItemProps[];

  /** Callback fired when the total number of observations change (based on the filters). */
  onTotalChange: (total: number) => void;

  /** Callback fired when removing the configuration. */
  onRemove: () => void;

  /** Title for the collapsible section. */
  title: string;

  /** When `false`, the age level cannot be updated. */
  disableAgeLevel?: boolean;

  /** When `true`, the section can be expanded and collapsed. */
  collapseEnabled: boolean;

  /** ID for the current account. */
  currentAccountId: number;

  /** When `true`, the configuration is for a comparison report. */
  isComparisonConfig: boolean;
}

export function CollapsibleConfigurationForm({
  title,
  values,
  onChange,
  onRemove,
  ageLevelOptions,
  classTemplateOptions,
  disableAgeLevel,
  collapseEnabled,
  onTotalChange,
  currentAccountId,
  isComparisonConfig
}: CollapsibleConfigurationFormProps) {
  const totalObservations = useReportTotal(values);
  const hierarchy = useAccountHierarchy(currentAccountId, values.nodeIds);

  const [initialClassVersion, setClassVersion] = useState(() => {
    if (values.ageLevel) {
      const ageLevel = ageLevelOptions.find(
        opt => opt.value === values.ageLevel
      );

      if (ageLevel) {
        return ageLevel.class_version;
      }
    }

    if (classTemplateOptions.length === 1) {
      return classTemplateOptions[0].value;
    }

    return null;
  });

  const filteredAgeLevelOptions = ageLevelOptions.filter(
    opt => opt.class_version === (values.classVersion || initialClassVersion)
  );

  useEffect(() => {
    if (typeof totalObservations !== 'number') {
      onTotalChange(0);
    } else {
      onTotalChange(totalObservations);
    }
    // Ignoring onTotalChange updates
    // eslint-disable-next-line
  }, [totalObservations]);

  const panelClasses = clsx('border-[#d2d4d4]', {
    'border-t': collapseEnabled
  });

  function handleChange(newValues: Partial<FormValues>) {
    onChange(newValues);
  }

  /**
   * Need to use `useCallback` because of a bug with react-dropdown-tree-select
   * that would cause the entire list of selected options to be lost if any of the props change.
   */
  const handleNodeChange = useCallback((_: any, tags: any) => {
    const nodeIds = tags.map((t: any) => Number(t.value));
    onChange({ nodeIds });
    // Ignoring onChange updates
    // eslint-disable-next-line
  }, []);

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <div className="bg-white rounded-[10px] shadow-xl border border-[#d2d4d4] mb-6">
          {collapseEnabled || !open ? (
            <div className="text-lg font-bold flex justify-between">
              <DisclosureButton className="py-5 px-6 w-full text-left">
                {open ? (
                  <Icon name="chevron up" />
                ) : (
                  <Icon name="chevron down" />
                )}
                <span className="ml-6">{title}</span>
              </DisclosureButton>

              {collapseEnabled && (
                <div className="py-5 px-6">
                  <IconButton
                    icon="trash alternate outline"
                    onClick={onRemove}
                  />
                </div>
              )}
            </div>
          ) : null}

          <DisclosurePanel className={panelClasses}>
            <div className="p-4">
              <div className="rounded-lg text-center bg-[#EAEFF8] py-5">
                <span className="font-bold text-2xl">
                  {i18n.ft(messages.completedObservations) + ':'}
                  <span className="ml-10">{totalObservations}</span>
                </span>
              </div>
            </div>

            <StepSection step={1} label={i18n.ft(messages.fields.time)}>
              <div className="lg:w-1/2">
                <DateRangePicker
                  endDate={values.endDate}
                  startDate={values.startDate}
                  onChange={([startDate, endDate]) => {
                    handleChange({ startDate, endDate });
                  }}
                />
              </div>
            </StepSection>

            <StepSection step={2}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1 font-bold text-lg">
                    {i18n.ft(messages.fields.classVersion)}
                  </label>

                  <SelectInput
                    fluid
                    disabled={
                      !values.startDate || !values.endDate || disableAgeLevel
                    }
                    value={values.classVersion || initialClassVersion}
                    placeholder={i18n.ft(
                      messages.fields.classVersionPlaceholder
                    )}
                    options={classTemplateOptions}
                    onChange={(_: any, data: any) => {
                      setClassVersion(data.value);
                      handleChange({
                        ageLevel: null,
                        classVersion: data.value
                      });
                    }}
                  />
                </div>

                <div>
                  <label className="block mb-1 font-bold text-lg">
                    {i18n.ft(messages.fields.ageLevel)}
                  </label>

                  <SelectInput
                    fluid
                    disabled={
                      !values.startDate ||
                      !values.endDate ||
                      (!values.classVersion && !initialClassVersion) ||
                      disableAgeLevel
                    }
                    placeholder={i18n.ft(messages.fields.ageLevelPlaceholder)}
                    options={filteredAgeLevelOptions}
                    value={values.ageLevel}
                    onChange={(_: any, data: any) =>
                      handleChange({ ageLevel: data.value })
                    }
                  />
                </div>
              </div>
            </StepSection>

            <StepSection step={3} label={i18n.ft(messages.fields.hierarchy)}>
              <HierarchyInput
                disabled={!values.ageLevel}
                data={hierarchy}
                onChange={handleNodeChange}
              />

              {!isComparisonConfig && (
                <div className="mt-6">
                  <Checkbox
                    disabled={!values.ageLevel || isComparisonConfig}
                    label={
                      <label className="font-bold">
                        {i18n.ft(messages.fields.classroomDetails)}
                      </label>
                    }
                    checked={values.classroomLevelDetails}
                    onChange={(_, data) =>
                      handleChange({ classroomLevelDetails: data.checked })
                    }
                  />
                  <p className="text-charcoal-500 ml-[30px]">
                    {i18n.ft(messages.fields.classroomDetailsPlaceholder)}
                  </p>
                </div>
              )}
            </StepSection>
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
}
