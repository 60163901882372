import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import { Checkbox } from '@headlessui/react';
import PageLoader from 'components/PageLoader';
import { Table } from 'components/Organization/Table';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetEducatorsCoachGroup } from 'actions/coachings';
import { composeUser } from 'pages/Organization/Members/utils';
import { TextButton } from 'components/Organization/TextButton';
import { IconButton } from 'components/Organization/IconButton';
import { toastError, toastSuccess } from 'components/Organization/Toast';
import { updateCoachings } from 'actions/coachings';

type Educator = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
};

function CoachGroups() {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { id: subscriptionId } = useParams<{ id: string }>();
  const { coachId } = useParams<{ coachId: string }>();
  const navigate = useNavigate();

  const handleRowSelect = (userId: number) => {
    setSelectedRows(prevSelectedRows =>
      prevSelectedRows.includes(userId)
        ? prevSelectedRows.filter(id => id !== userId)
        : [...prevSelectedRows, userId]
    );
  };

  const handleHeaderSelect = () => {
    if (!data || !data.educators) return;

    if (selectedRows.length <= 0) {
      const allUserIdsOnPage = data.educators.map(
        (educator: any) => educator.id
      );
      setSelectedRows(allUserIdsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const sortMapping: { [key: string]: string } = {
    name: 'coach_name',
    educators: 'educators'
  };

  const baseParams = {
    query: '',
    sort_by: sortBy && sortBy in sortMapping ? sortMapping[sortBy] : sortBy,
    sort_dir: sortDir
  };

  const tableParams = {
    ...baseParams,
    per_page: 20
  };

  const filteredTableParams = Object.fromEntries(
    Object.entries(tableParams).filter(([_, value]) => value != null)
  );

  const { data, isLoading, refetch } = useGetEducatorsCoachGroup(
    Number(subscriptionId),
    Number(coachId),
    filteredTableParams
  );

  const handleSort = (header: string) => {
    if (header === sortBy && sortDir === 'asc') {
      setSortDir('desc');
    } else if (header === sortBy && sortDir === 'desc') {
      setSortBy(null);
      setSortDir(null);
    } else {
      setSortBy(header);
      setSortDir('asc');
    }
  };

  const handleRemove = () => {
    if (selectedRows.length === 0) return;
    if (subscriptionId === undefined) return;

    const coachings = data.educators
      .filter((educator: Educator) => selectedRows.includes(educator.id))
      .map((educator: Educator) => ({
        coach_id: coachId,
        educator_id: educator.id,
        _destroy: 1
      }));

    updateCoachings(Number(subscriptionId), coachings)
      .then(() => {
        setSelectedRows([]);
        toastSuccess({
          message: i18n.ft(messages.success)
        });
        refetch();
      })
      .catch(() => {
        toastError({
          message: i18n.ft(messages.error)
        });
      });
  };

  const tableHeaders = [
    {
      content: (
        <Checkbox
          checked={selectedRows.length > 0}
          onChange={handleHeaderSelect}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-minus" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      accessor: 'checkbox'
    },
    {
      content: i18n.ft(messages.nameAndEmail),
      accessor: 'educator_name',
      sortable: true
    }
  ];

  const tableData = data?.educators.map((educator: Educator) => {
    return {
      selected: selectedRows.includes(educator.id),
      checkbox: (
        <Checkbox
          checked={selectedRows.includes(educator.id)}
          onChange={() => handleRowSelect(educator.id)}
          className="text-xl text-action"
        >
          {({ checked }) =>
            checked ? (
              <i className="fa-solid fa-square-check" />
            ) : (
              <i className="fa-regular fa-square" />
            )
          }
        </Checkbox>
      ),
      educator_name: composeUser(
        educator.id,
        educator.full_name,
        educator.email
      )
    };
  });

  const membersCount = data?.educators?.length;

  return (
    <div className="font-sans px-4 md:px-6 xl:px-10 max-w-7xl mx-auto text-black">
      <div className="font-sans pt-12 pb-4 flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        <div className="flex items-center gap-2">
          <IconButton
            icon="fa-solid fa-arrow-left"
            onClick={() => navigate(-1)}
          />

          <h2 className="font-sans text-2xl sm:text-3xl">{data?.full_name}</h2>
        </div>
      </div>

      <div className="p-6 bg-white rounded-2xl">
        <h2 className="text-black font-sans font-bold text-3xl">
          {i18n.ft(messages.educators)}
        </h2>

        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {tableData && (
              <>
                <div className="text-[#3C3F42] mt-6 mb-1.5 text-sm">
                  {i18n.ft(messages.usersCount, {
                    initial: data?.educators?.length > 0 ? 1 : 0,
                    end: data?.educators?.length || 0,
                    total: membersCount
                  })}
                </div>

                <Table
                  headers={tableHeaders}
                  data={tableData}
                  sortBy={sortBy}
                  sortDir={sortDir}
                  onSort={handleSort}
                  actions={
                    selectedRows.length > 0 && (
                      <div className="flex gap-6">
                        <TextButton
                          inverted
                          size="small"
                          onClick={() => handleRemove()}
                        >
                          <i className="fa-regular fa-redo mr-2"></i>
                          <span>
                            {i18n.ft(messages.remove, {
                              count: selectedRows.length
                            })}
                          </span>
                        </TextButton>
                        <TextButton
                          inverted
                          size="small"
                          onClick={() => setSelectedRows([])}
                        >
                          <span>{i18n.ft(messages.deselectAll)}</span>
                        </TextButton>
                      </div>
                    )
                  }
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CoachGroups;
