import { clsx } from 'clsx';

interface BackgroundSVGProps {
  className?: string;
}

export function BackgroundSVG({ className }: BackgroundSVGProps) {
  return (
    <>
      {/* This SVG is better a fit for large screens. */}
      <svg
        fill="none"
        viewBox="0 0 1536 346"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx('hidden xl:block', className)}
      >
        <path
          d="M0 0H1536V212.191C1536 226.173 1525.2 237.78 1511.26 238.788L28.5888 345.934C13.1445 347.05 0 334.821 0 319.337V0Z"
          fill="#6E2262"
        />
      </svg>

      {/* This SVG is better a fit for medium screens. */}
      <svg
        fill="none"
        viewBox="0 0 1280 288"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx('hidden md:block xl:hidden', className)}
      >
        <path
          d="M0 0H1280V172.691C1280 186.673 1269.2 198.28 1255.26 199.288L28.5887 287.934C13.1444 289.05 0 276.821 0 261.337V0Z"
          fill="#6E2262"
        />
      </svg>

      {/* This SVG is a better fit for small screens. */}
      <svg
        fill="none"
        viewBox="0 0 640 216"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx('md:hidden', className)}
      >
        <path
          d="M0 0H640V125.232C640 138.84 629.754 150.265 616.227 151.741L29.5601 215.774C13.7808 217.496 0 205.137 0 189.264V0Z"
          fill="#6E2262"
        />
      </svg>
    </>
  );
}
