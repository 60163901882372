import * as yup from 'yup';
import i18n from 'helpers/i18n';
import messages from './messages';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { expirationMonths, expirationYears, getGrandTotal } from './utils';
import { DualRow, DualRowItem, ErrorContainer, Checkbox } from './Styled';
import { SubmitButton } from '../Styled';
import { createOrder, submitPayment } from 'actions/trainings';
import Typography from 'components/Typography';
import { isNil } from 'ramda';
import { Formik, Form, Input, Select } from 'components/EnhancedForm';

const validationSchema = yup.object({
  agreeToPolicy: yup
    .bool()
    .oneOf([true], () => i18n.ft(messages.errors.agreeToPolicy.mustAgree)),
  cardholder_name: yup
    .string()
    .required(() => i18n.ft(messages.errors.cardName.required)),
  card_number: yup
    .string()
    .required(() => i18n.ft(messages.errors.cardNumber.required)),
  expiry_month: yup
    .string()
    .required(() => i18n.ft(messages.errors.expiryMonth.required)),
  expiry_year: yup
    .string()
    .required(() => i18n.ft(messages.errors.expiryYear.required)),
  security_code: yup
    .string()
    .required(() => i18n.ft(messages.errors.securityCode.required))
});

// NOTE: This is a temporary change to be able to test the new payment type on QAT
const url = new URL(window.location.href);
const paymentMethod = url.hostname.includes('qat')
  ? 'authorizenet.card'
  : 'stripeupe.card';

const initialValues = {
  payment_method_id: paymentMethod,
  cardholder_name: '',
  card_number: '',
  expiry_month: '',
  expiry_year: '',
  security_code: '',
  agreeToPolicy: false
};

const CreditCardForm = ({ trainingId, afterSubmit, checkout }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  function handleSubmit(values) {
    setError(null);
    setLoading(true);
    createOrder(trainingId).then(() => {
      submitPayment(trainingId, values)
        .then(() => {
          setLoading(false);
          afterSubmit();
        })
        .catch(() => {
          setLoading(false);
          setError(i18n.ft(messages.errors.unknown));
        });
    });
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ submitForm }) => (
        <Form>
          <Input hidden name="payment_method_id" />
          <Input name="cardholder_name" label={i18n.ft(messages.cardName)} />
          <Input name="card_number" label={i18n.ft(messages.cardNumber)} />
          <DualRow>
            <DualRowItem>
              <Select
                search
                upward
                name="expiry_month"
                options={expirationMonths()}
                placeholder={i18n.ft(messages.month)}
                label={i18n.ft(messages.expirationDate)}
                aria-label={i18n.ft(messages.expiryMonth)}
              />
            </DualRowItem>
            <DualRowItem>
              <Select
                search
                upward
                label=""
                name="expiry_year"
                options={expirationYears()}
                placeholder={i18n.ft(messages.year)}
                aria-label={i18n.ft(messages.expiryYear)}
              />
            </DualRowItem>
          </DualRow>

          <DualRow>
            <DualRowItem>
              <Input
                name="security_code"
                label={i18n.ft(messages.securityCode)}
              />
            </DualRowItem>
          </DualRow>

          <Checkbox
            name="agreeToPolicy"
            error={{ textAlign: 'left' }}
            aria-label={i18n.ft(messages.policyLabel)}
            label={
              <label>
                {i18n.ft(messages.policy01) + ' '}
                <Link
                  target="_blank"
                  to="/trainings/affiliate-cancellation-policy"
                >
                  {i18n.ft(messages.policy02) + ' '}
                  <Icon
                    size="small"
                    name="external"
                    aria-label={i18n.ft(messages.opensWindow)}
                  />
                </Link>
              </label>
            }
          />

          <SubmitButton>
            <Button
              color="green"
              loading={loading}
              disabled={loading}
              onClick={submitForm}
            >
              {isNil(checkout)
                ? i18n.ft(messages.finishAndPay)
                : `${i18n.ft(messages.finishAndPay)} ${getGrandTotal(
                    checkout
                  )}`}
            </Button>
          </SubmitButton>

          {error && (
            <ErrorContainer>
              <Typography
                bold
                color="red"
                display="block"
                content={error}
                textAlign="center"
              />
            </ErrorContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};

CreditCardForm.propTypes = {
  /** The training ID. */
  trainingId: PropTypes.number.isRequired,

  /** Callback fired after the payment has been processed. */
  afterSubmit: PropTypes.func.isRequired,

  /** Big Commerce Checkout */
  checkout: PropTypes.object
};

export default CreditCardForm;
