import { Checkbox as BaseCheckbox } from '@headlessui/react';

interface CheckboxProps {
  /** When `true`, the checkbox is checked. */
  checked?: boolean;

  /** Callback fired when the checkbox is toggled. */
  onChange: (checked: boolean) => void;

  /** When `true`, the checkbox renders an indeterminate icon. */
  indeterminate?: boolean;
}

export function Checkbox(props: CheckboxProps) {
  return (
    <BaseCheckbox className="text-xl text-action" {...props}>
      {({ checked, indeterminate }) =>
        indeterminate ? (
          <i className="fa-solid fa-square-minus" />
        ) : checked ? (
          <i className="fa-solid fa-square-check" />
        ) : (
          <i className="fa-regular fa-square" />
        )
      }
    </BaseCheckbox>
  );
}
