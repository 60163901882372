import i18n from 'helpers/i18n';
import messages from './messages';
import { roleToText } from 'helpers/role';
import { useMutation } from '@tanstack/react-query';
import { reactivateUsersToAccount } from 'actions/accounts';
import { PrimaryButton } from 'components/Organization/PrimaryButton';
import { SecondaryButton } from 'components/Organization/SecondaryButton';

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop
} from '@headlessui/react';

type Member = {
  id: number;
  name: string;
  email: string;
  role: string | null;
};

interface UnarchiveModalProps {
  /** Modal open state. */
  open: boolean;

  /** Callback fired when modal is closed. */
  onClose: () => void;

  /** Callback fired when deletion is confirmed. */
  onConfirm: () => void;

  /** Account ID. */
  accountId: number;

  /** List of members to unarchive. */
  members: Member[];
}

export function UnarchiveModal({
  accountId,
  members,
  open,
  onClose,
  onConfirm
}: UnarchiveModalProps) {
  const { isPending, mutate: reactivateSelected } = useMutation({
    mutationFn: reactivateUsersToAccount,
    onSuccess: () => onConfirm()
  });

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = async () => {
    if (isPending) {
      return;
    }

    const params = {
      user_ids: members.map(member => member.id)
    };

    reactivateSelected({ accountId, params });
  };

  return (
    <Dialog open={open} onClose={onClose} className="text-black relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-2xl bg-white rounded-lg font-sans">
          <div className="px-6 py-3 flex flex-col gap-2.5">
            <DialogTitle className="font-sans font-bold text-2xl m-0">
              {i18n.ft(messages.modal.title)}
            </DialogTitle>

            <p>
              {i18n.ft(messages.modal.description, { count: members.length })}
            </p>
          </div>

          <div className="px-6 py-4 flex flex-col gap-4 max-h-64 overflow-y-scroll">
            {members.map(user => (
              <div
                key={user.id}
                className="p-4 border border-navy-300 rounded-lg flex flex-col gap-2"
              >
                <div>
                  <span className="font-semibold">{user.name}</span>
                  <span>{user.role ? ': ' : null}</span>
                  <span>{user.role ? roleToText(user.role) : null}</span>
                </div>
                <div>{user.email}</div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-between px-6 py-4 border-t border-navy-100">
            <SecondaryButton onClick={handleCancel}>
              {i18n.ft(messages.modal.cancel)}
            </SecondaryButton>

            <PrimaryButton onClick={handleConfirm}>
              <span>{i18n.ft(messages.modal.proceed)}</span>
              <i className="fa-solid fa-arrow-right ml-2" />
            </PrimaryButton>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
