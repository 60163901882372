import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { isBlank, getSortIcon } from './utils';
import PageLoader from 'components/PageLoader';

type TableHeader = {
  /** The header content. */
  content: ReactNode;

  /** The name of the data field to pull the content from. */
  accessor: string;

  /** Optional className. */
  className?: string;

  /** When `true`, the header column will support sorting.  */
  sortable?: boolean;

  /**Filter component for header */
  filter?: ReactNode;
};

interface TableProps {
  /** Array of TableHeaders. */
  headers: TableHeader[];

  /** Array of data to render with the table. */
  data: any[];

  /** Active field table is being sorted by. */
  sortBy?: string | null;

  /** Active sorting direction. */
  sortDir?: 'asc' | 'desc' | null;

  /** Callback fired when a sortable column is clicked. */
  onSort?: (header: string) => void;

  /** Actions to be displayed below the table headers. */
  actions?: ReactNode;

  /** When `true`, the table is in loading state and a loading spinner is displayed. */
  isLoading?: boolean;
}

export function Table({
  actions,
  headers,
  data,
  sortBy,
  sortDir,
  onSort,
  isLoading
}: TableProps) {
  return (
    <table className="w-full">
      <thead>
        <tr className="border-b border-action">
          {headers.map((header, colIdx) => {
            const thClasses = clsx(
              'text-left font-semibold pt-2 pb-3.5 px-5',
              colIdx === 0 && 'pl-2',
              header.className
            );

            const content = header.sortable ? (
              <button
                className="font-sans flex items-center gap-2"
                onClick={() => onSort?.(header.accessor)}
              >
                <span>{header.content}</span>
                {getSortIcon(header.accessor === sortBy, sortDir)}
              </button>
            ) : header.filter ? (
              <div className="flex items-center gap-2">
                {header.content}
                {header.filter}
              </div>
            ) : (
              header.content
            );

            return (
              <th key={header.accessor} className={thClasses}>
                {content}
              </th>
            );
          })}
        </tr>
      </thead>

      {isLoading ? (
        <tbody className="text-sm">
          <tr>
            <td colSpan={headers.length} className="text-center">
              <PageLoader />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody className="text-sm">
          {actions && (
            <>
              {/* Adds a white border above the actions. Needed so border radious can be visible. */}
              <tr className="border-b-2 border-white">
                <td colSpan={headers.length} />
              </tr>

              {/* Adds the actions section. */}
              <tr>
                <td
                  colSpan={headers.length}
                  className="bg-action px-2 py-1 rounded text-white"
                >
                  {actions}
                </td>
              </tr>

              {/* Adds a white border below the actions. Needed so border radious can be visible. */}
              <tr className="border-b-2 border-white">
                <td colSpan={headers.length} />
              </tr>

              {/* Adds an extra row, so there's no shift in the background color of the table rows. */}
              <tr>
                <td colSpan={headers.length} />
              </tr>
            </>
          )}

          {data.length === 0 && (
            <tr>
              <td colSpan={headers.length} className="text-center pt-8 pb-2">
                No data to display.
              </td>
            </tr>
          )}

          {data.map((row, rowIndex) => {
            const rowClasses = clsx(
              'border-y-4 border-white first:border-t-0',
              row['selected'] ? 'bg-action-tint' : 'even:bg-charcoal-25'
            );

            return (
              <tr key={rowIndex} className={rowClasses}>
                {headers.map((header, colIdx) => {
                  const tdClasses = clsx(
                    'px-5 pt-4 pb-3',
                    colIdx === 0 && 'pl-2'
                  );

                  return (
                    <td key={colIdx} className={tdClasses}>
                      {isBlank(row[header.accessor])
                        ? '--'
                        : row[header.accessor]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
}
