import i18n from 'helpers/i18n';
import messages from './messages';
import { ActivityRow } from './ActivityRow';
import { AccountMember } from 'types/api/AccountMember';
import { useGetAccountMemberLog } from 'actions/accounts';

interface ActivitySectionProps {
  /** Member Account ID. */
  accountId: number;

  /** Account Member. */
  user: AccountMember;
}

export function ActivitySection({ accountId, user }: ActivitySectionProps) {
  const { data, isSuccess } = useGetAccountMemberLog(accountId, user.user_id);

  if (!isSuccess) {
    return null;
  }

  return (
    <div className="font-sans bg-white p-6 rounded-2xl mt-6">
      <div className="font-bold text-xl md:text-2xl">
        {i18n.ft(messages.activity)}
      </div>
      <div className="mt-10">
        {data.account_user_logs.map(item => (
          <ActivityRow
            key={item.id}
            action={item.action}
            fromUser={item.performed_by.user_name}
            toUser={user.user}
            timestamp={item.created_at}
            details={{
              oldValue: item.details?.old_value,
              newValue: item.details?.new_value
            }}
          />
        ))}

        {data.account_user_logs.length === 0 && (
          <div className="italic text-gray-500">
            {i18n.ft(messages.noActivity)}
          </div>
        )}
      </div>
    </div>
  );
}
