import i18n from 'helpers/i18n';
import messages from './messages';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'components/Organization/IconButton';
import { EditableNode } from 'components/Organization/EditHierarchy/EditableNode';

import {
  NodeList,
  HierarchyProvider
} from 'components/Organization/HierarchyBuilder';

function EditHierarchy() {
  const navigate = useNavigate();

  return (
    <div className="px-4 md:px-6 xl:px-10 max-w-7xl mx-auto">
      <div className="flex items-center gap-2 pt-12 pb-4">
        <IconButton
          label={i18n.ft(messages.goBack)}
          icon="fa-solid fa-arrow-left"
          onClick={() => navigate(-1)}
        />

        <h2 className="font-sans text-2xl sm:text-3xl">
          {i18n.ft(messages.header)}
        </h2>
      </div>

      <div className="bg-white rounded-2xl px-6 py-4">
        <NodeList NodeComponent={EditableNode} />
      </div>
    </div>
  );
}

function EditHierarchyController() {
  return (
    <HierarchyProvider area="ManageOrganization">
      <EditHierarchy />
    </HierarchyProvider>
  );
}

export default EditHierarchyController;
