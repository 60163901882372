const PATH = 'fe.pages.organization.edit_hierarchy';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Edit Hierarchy'
  },
  goBack: {
    key: `${PATH}.go_back`,
    defaultValue: 'Go Back'
  }
};

export default messages;
