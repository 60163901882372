const PATH = 'fe.pages.organization.view_subscription_users';

const messages = {
  activeUsers: {
    key: `${PATH}.active_users`,
    defaultValue: 'Active users'
  },
  unsubscribedUsers: {
    key: `${PATH}.unsubscribed_users`,
    defaultValue: 'Unsubscribed Users'
  },
  coachGroups: {
    key: `${PATH}.coach_groups`,
    defaultValue: 'Coach Groups'
  },
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search name or email'
  },
  inviteUsers: {
    key: `${PATH}.invite_users`,
    defaultValue: 'Invite Users'
  }
};

export default messages;
