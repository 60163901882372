const PATH = 'fe.pages.organization.view_member.trainings';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Trainings'
  },
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Title'
  },
  type: {
    key: `${PATH}.type`,
    defaultValue: 'Type'
  },
  ageLevelGroup: {
    key: `${PATH}.age_level_group`,
    defaultValue: 'Age Group'
  },
  framework: {
    key: `${PATH}.framework`,
    defaultValue: 'Framework'
  },
  language: {
    key: `${PATH}.language`,
    defaultValue: 'Language'
  },
  typeMessage: {
    cgcTransitionTainer: {
      key: `${PATH}.cgc_transition_trainer`,
      defaultValue: 'CGC Transition: Trainer'
    },
    ieTrainer: {
      key: `${PATH}.ie_trainer`,
      defaultValue: 'IE Trainer'
    },
    observer: {
      key: `${PATH}.observer`,
      defaultValue: 'Observer'
    },
    observer_trainer: {
      key: `${PATH}.observer_trainer`,
      defaultValue: 'Observer Trainer'
    },
    transition_observer: {
      key: `${PATH}.transition_observer`,
      defaultValue: 'Transition: Observer'
    },
    transition_trainer: {
      key: `${PATH}.transition_trainer`,
      defaultValue: 'Transition: Trainer'
    },
    undefined: {
      key: `${PATH}.undefined`,
      defaultValue: '--'
    }
  },
  frameworkMessage: {
    firstEdition: {
      key: `${PATH}.first_edition`,
      defaultValue: 'CLASS 2008'
    },
    secondEdition: {
      key: `${PATH}.second_edition`,
      defaultValue: 'CLASS 2nd Edition'
    },
    classVersionNeutral: {
      key: `${PATH}.class_version_neutral`,
      defaultValue: 'CLASS Version Neutral'
    },
    undefined: {
      key: `${PATH}.undefined_framework`,
      defaultValue: '--'
    }
  }
};

export default messages;
