import clsx from 'clsx';
import debounce from 'debounce';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState, useRef, useEffect, KeyboardEvent } from 'react';
import { Label, Field } from '@headlessui/react';
import { useGetAccountMembers } from 'actions/accounts';

interface AccountMember {
  id: number;
  email: string;
  name: string;
}

interface EmailAutocompleteProps {
  accountId: number;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onSelect?: (customer: AccountMember) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

function EmailAutocomplete({
  value,
  onChange,
  placeholder = i18n.ft(messages.placeholder),
  onSelect,
  onBlur,
  onFocus,
  accountId
}: EmailAutocompleteProps) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useGetAccountMembers(accountId, {
    query: value,
    per_page: 99
  });

  const handleSelect = (customer: AccountMember) => {
    onChange(customer.email);
    setDropdownVisible(false);
    setSelectedIndex(-1);
    onSelect?.(customer);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isDropdownVisible || !data || data.users?.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prevIndex => {
          const nextIndex = (prevIndex + 1) % data.users.length;
          scrollIntoView(nextIndex);
          return nextIndex;
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prevIndex => {
          const prev = prevIndex <= 0 ? data.users.length - 1 : prevIndex - 1;
          scrollIntoView(prev);
          return prev;
        });
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          handleSelect(data.users[selectedIndex]);
        }
        break;
      case 'Escape':
        setDropdownVisible(false);
        break;
    }
  };

  const scrollIntoView = (index: number) => {
    if (dropdownRef.current) {
      const item = dropdownRef.current.children[index] as HTMLElement;
      item?.scrollIntoView({ block: 'nearest' });
    }
  };

  useEffect(() => {
    if (!isDropdownVisible) setSelectedIndex(-1);
  }, [isDropdownVisible]);

  const inputClasses = clsx(
    'w-full rounded-md px-4 py-2 focus:outline-none border text-black font-sans text-base',
    isDropdownVisible
      ? 'rounded-b-none ring-1 ring-action border-action'
      : 'border-navy-300'
  );

  return (
    <div className="w-full">
      <Field className="flex flex-col gap-1 font-sans">
        <Label className="font-semibold text-base text-black">
          {i18n.ft(messages.email)}
        </Label>
        <input
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          onFocus={() => {
            setDropdownVisible(true);
            onFocus?.();
          }}
          onBlur={() => {
            setTimeout(() => setDropdownVisible(false), 200);
            onBlur?.();
          }}
          onKeyDown={debounce((e: KeyboardEvent<HTMLInputElement>) => {
            setDropdownVisible(true);
            handleKeyDown(e);
          }, 300)}
          className={inputClasses}
        />
      </Field>
      {isDropdownVisible && (
        <div
          ref={dropdownRef}
          className="z-10 bg-white border-x border-b border-action ring-1 ring-action rounded-b-md w-full shadow-lg max-h-48 overflow-auto"
        >
          {isLoading ? (
            <div className="p-4 text-gray-500">{i18n.ft(messages.loading)}</div>
          ) : (data?.users ?? []).length > 0 ? (
            data?.users?.map((customer, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelect(customer);
                }}
                className={clsx(
                  'px-3 py-2 font-sans text-black hover:bg-charcoal-25 cursor-pointer flex flex-col gap-0.5',
                  {
                    'bg-action-tint': index === selectedIndex
                  }
                )}
              >
                <div className="font-semibold">{customer.email}</div>
                <div className="text-base">{customer.name}</div>
              </div>
            ))
          ) : (
            <div className="p-4 font-sans text-gray-500">
              {i18n.ft(messages.noResults)}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmailAutocomplete;
