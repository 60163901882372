import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

/** Trainings APIs */

export function getTrainings(params = {}) {
  return axios.get('/api/v2/trainings', { params });
}

export function getTraining(id: number) {
  return axios.get(`/api/v2/trainings/${id}`);
}

export function createTraining(training = {}) {
  return axios.post('/api/v2/trainings', { training });
}

export function updateTraining(id: number, training = {}) {
  return axios.put(`/api/v2/trainings/${id}`, { training });
}

export function cancelTraining(id: number) {
  return axios.put(`/api/v2/trainings/${id}/cancel`);
}

export function completeTraining(id: number) {
  return axios.put(`/api/v2/trainings/${id}/complete`);
}

export function reopenTraining(id: number) {
  return axios.put(`/api/v2/trainings/${id}/reopen`);
}

export function purchaseTraining(id: number, params = {}) {
  return axios.post(`/api/v2/trainings/${id}/purchase`, params);
}

export function getUserTrainingParticipants(userId: number) {
  return axios.get(`/api/v2/users/${userId}/trainings`);
}

/** Trainings Courses API */

export function getTrainingCourses(id: number) {
  return axios.get(`/api/v2/trainings/${id}/courses`);
}

/** Training User Tokens APIs */

export function getTrainingUserTokens(trainingId: number) {
  return axios.get(`/api/v2/trainings/${trainingId}/user_tokens`);
}

/** Training Participants APIs */

export function getTrainingParticipants(trainingId: number) {
  return axios.get(`/api/v2/trainings/${trainingId}/participants`);
}

export function createTrainingParticipant(trainingId: number, params = {}) {
  return axios.post(`/api/v2/trainings/${trainingId}/participants`, params);
}

export function cancelTrainingParticipants(trainingId: number, params = {}) {
  return axios.put(
    `/api/v2/trainings/${trainingId}/participants/cancel`,
    params
  );
}

export function inviteTrainingParticipant(trainingId: number, params = {}) {
  return axios.put(
    `/api/v2/trainings/${trainingId}/participants/invite_declined`,
    params
  );
}

export function inviteCanceledTrainingParticipants(
  trainingId: number,
  params = {}
) {
  return axios.put(
    `/api/v2/trainings/${trainingId}/participants/invite_canceled`,
    params
  );
}

export function requestPaymentForTraining(trainingId: number, params = {}) {
  return axios.post(
    `/api/v2/trainings/${trainingId}/participants/request_payment`,
    params
  );
}

export function getTrainingInvitation(trainingId: number) {
  return axios.get(`/api/v2/trainings/${trainingId}/participants/me`);
}

export function respondTrainingInvitation(trainingId: number, response = {}) {
  return axios.put(`/api/v2/trainings/${trainingId}/participants/respond`, {
    response
  });
}

export function editParticipantEmail(
  trainingId: number,
  participantId: number,
  params = {}
) {
  return axios.patch(
    `/api/v2/trainings/${trainingId}/participants/${participantId}`,
    params
  );
}

/** Training Purchases APIs */

export function createCart(trainingId: number, participantIds: number[]) {
  return axios.post(`/api/v2/trainings/${trainingId}/purchases/carts`, {
    participant_ids: participantIds
  });
}

export function addBillingAddress(trainingId: number, params = {}) {
  return axios.post(
    `/api/v2/trainings/${trainingId}/purchases/cart/billing_address`,
    params
  );
}

export function addShippingAddress(trainingId: number, params = {}) {
  return axios.post(
    `/api/v2/trainings/${trainingId}/purchases/cart/shipping_address`,
    params
  );
}

export function addShippingOption(trainingId: number, params = {}) {
  return axios.put(
    `/api/v2/trainings/${trainingId}/purchases/cart/shipping_option`,
    params
  );
}

export function createOrder(trainingId: number) {
  return axios.post(`/api/v2/trainings/${trainingId}/purchases/cart/orders`);
}

export function submitPayment(trainingId: number, params = {}) {
  return axios.post(
    `/api/v2/trainings/${trainingId}/purchases/cart/payments`,
    params
  );
}

export function submitPurchaseOrder(trainingId: number, params = {}) {
  return axios.post(
    `/api/v2/trainings/${trainingId}/purchases/cart/purchase_orders`,
    params
  );
}

export function applyCoupon(trainingId: number, params = {}) {
  return axios.post(
    `/api/v2/trainings/${trainingId}/purchases/cart/coupon_code`,
    params
  );
}

/** Training's Reliability Test Report */

export function getReliabilityReport(trainingId: number) {
  return axios.get(`/api/v2/trainings/${trainingId}/reliability_test_report`);
}

export function getKnowledgeReport(trainingId: number) {
  return axios.get(`/api/v2/trainings/${trainingId}/knowledge_test_report`);
}

/** Trainings from current account */
export function getAccountTrainings(params = {}) {
  return axios.get(`/api/v2/ca/trainings`, { params });
}

export function getAccountTrainingDetail(id: number) {
  return axios.get(`/api/v2/ca/trainings/${id}`);
}

export type UserTrainingsType =
  | 'undefined_training_type'
  | 'cgc_transition_trainer'
  | 'ie_trainer'
  | 'observer'
  | 'observer_trainer'
  | 'transition_observer'
  | 'transition_trainer';

export type UserTrainingsFramework =
  | 'undefined_framework'
  | 'first_edition'
  | 'second_edition'
  | 'class_version_neutral';

type UserTrainingsRecord = {
  title: string;
  type: UserTrainingsType;
  age_level_group: string;
  framework: UserTrainingsFramework;
  language: string;
};

export function getCAUserTrainings(userId: number) {
  return axios.get<UserTrainingsRecord[]>(
    `/api/v2/ca/users/${userId}/trainings`
  );
}

export function useGetCAUserTrainings(userId: number) {
  return useQuery({
    queryKey: ['ca', 'users', userId, 'trainings'],
    queryFn: () => getCAUserTrainings(userId).then(response => response.data)
  });
}
