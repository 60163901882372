import * as R from 'ramda';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useEffect } from 'react';
import useAsync from 'hooks/useAsync';
import ReminderIcon from './ReminderIcon';
import { Action } from 'types/api/Action';
import { Button } from 'semantic-ui-react';
import { getActions } from 'actions/actions';
import { getActions as getCoreActions } from 'actions/core/certifications';
import ActionsIcon from 'images/actions.svg';
import { convertUTCToLocal } from 'helpers/date/local_timezone';

import {
  Header,
  ReminderWrapper,
  DetailsWrapper,
  ButtonWrapper,
  TypeLabel,
  Title,
  Subtitle,
  EmptyText
} from './Styled';

function Reminders() {
  const { run, data, isPending, isSuccess } = useAsync();
  const {
    run: coreRun,
    data: coreData,
    isSuccess: coreSuccess,
    ...coreRequest
  } = useAsync();

  useEffect(() => {
    run(getActions());
    coreRun(getCoreActions());
  }, [run, coreRun]);

  if (!isSuccess) {
    return null;
  }

  if (isPending || coreRequest.isPending) {
    return null;
  }

  const actions = data.concat(coreData || []);

  function formatSubtitle(subtitle: string) {
    if (!subtitle) {
      return '';
    }

    const formattedDate = convertUTCToLocal(subtitle);

    if (!formattedDate) {
      return subtitle; // when it's not a valid date, return the subtitle as is.
    }

    const year = formattedDate.split(' ')[0];
    const time = formattedDate.split(' ').slice(1).join(' ');

    return (
      <div>
        <div>{year}</div>
        {time && <div>{time}</div>}
      </div>
    );
  }

  return (
    <div>
      <Header>
        <img
          src={ActionsIcon}
          alt={i18n.ft(messages.header)}
          className="inline"
        />
        {i18n.ft(messages.header)}
      </Header>

      {R.isEmpty(data) && R.isEmpty(coreData) && (
        <EmptyText>{i18n.ft(messages.noActions)}</EmptyText>
      )}

      {actions.map((action: Action, idx: number) => (
        <ReminderWrapper key={idx}>
          <DetailsWrapper>
            <ReminderIcon action={action} />
            <div>
              <TypeLabel>{action.type_label}</TypeLabel>
              <Title>{action.title}</Title>
              <Subtitle>{action.subtitle_header}</Subtitle>
              <Subtitle>{formatSubtitle(action.subtitle)}</Subtitle>
            </div>
          </DetailsWrapper>

          <ButtonWrapper>
            <Button
              fluid
              color="blue"
              size="small"
              as="a"
              href={action.link}
              content={action.link_title}
            />
          </ButtonWrapper>
        </ReminderWrapper>
      ))}
    </div>
  );
}

export default Reminders;
