import i18n from 'helpers/i18n';
import messages from './messages';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

interface SubscriptionProps {
  subscription: {
    id: any;
    name: string;
    product: { display_name: string; name: string };
    end_date: string;
    max_seats: number;
    users: number;
  };
}

const variants = {
  learningResources: {
    icon: 'fa-graduation-cap',
    iconBgColor: 'bg-purple-100',
    topBorder: 'bg-purple-500'
  },
  dataCollection: {
    icon: 'fa-clipboard',
    iconBgColor: 'bg-orange-100',
    topBorder: 'bg-orange-500'
  }
};

function SubscriptionCard({ subscription }: SubscriptionProps) {
  const isLearningResources =
    subscription.product.name === 'learning_resources';

  function getProductVariant() {
    return isLearningResources
      ? variants.learningResources
      : variants.dataCollection;
  }

  const variant = getProductVariant();

  return (
    <div className="shadow-md rounded-b-lg">
      <div className={clsx('h-4 rounded-t-lg', variant.topBorder)} />

      <Link
        className="block p-4 text-black hover:text-black rounded-b-lg"
        to={`/organization/subscriptions/${subscription.id}/users`}
      >
        <div className="flex justify-between items-center">
          <div className="text-base font-semibold text-charcoal-500">
            {subscription.product.display_name}
          </div>
          <div
            className={clsx(
              'rounded-full size-10 flex justify-center items-center',
              variant.iconBgColor
            )}
          >
            <i className={clsx('fa-solid text-xl', variant.icon)} />
          </div>
        </div>

        <div className="mb-4 text-xl font-bold">{subscription.name}</div>

        <div className="mb-4 flex justify-between">
          <span className="font-semibold">{i18n.ft(messages.expiresOn)}:</span>
          <span>{subscription.end_date || '--'}</span>
        </div>

        <div className="flex flex-col gap-2">
          <div className="font-semibold text-sm text-charcoal-500">
            {i18n.ft(messages.seats)}
          </div>

          {subscription.max_seats && (
            <div className="flex justify-between">
              <span className="font-semibold">
                {i18n.ft(messages.available)}:
              </span>
              {subscription.max_seats - subscription.users}
            </div>
          )}

          <div className="flex justify-between">
            <span className="font-semibold">{i18n.ft(messages.used)}:</span>
            {subscription.users}
          </div>

          {subscription.max_seats && (
            <div className="flex justify-between">
              <span className="font-semibold">{i18n.ft(messages.total)}:</span>
              {subscription.max_seats}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default SubscriptionCard;
