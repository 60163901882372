import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export function getAccoutMembers(params = {}) {
  return axios.get(`/api/v2/customers`, { params });
}

export function useGetAccountMembers(params = {}) {
  return useQuery({
    queryKey: ['customers', 'query', params],
    queryFn: () => getAccoutMembers(params).then(response => response.data)
  });
}

export function resendInvitations(subscriptionId: number, emails: string[]) {
  return axios.post(`/api/v2/customers/resend_invitations`, {
    invites: {
      subscription_id: subscriptionId,
      emails: emails
    }
  });
}
