const PATH = 'fe.components.organization.assign_coach_modal';

const messages = {
  title: {
    key: `${PATH}.title`,
    defaultValue: 'Assign to Coach'
  },
  description: {
    key: `${PATH}.description`,
    defaultValue:
      'Choose the coach you want to assign the selected educators to.'
  },
  assign: {
    key: `${PATH}.assign`,
    defaultValue: 'Assign'
  },
  error: {
    key: `${PATH}.error`,
    defaultValue:
      "You've selected {{educators}} educator(s), {{coaches}} coach(es), and {{admins}} admin(s)."
  },
  educatorsOnly: {
    key: `${PATH}.educators_only`,
    defaultValue: 'Only educators can be assigned to coaches.'
  }
};

export default messages;
