const PATH = 'fe.pages.organization.view_member.certifications';

const messages = {
  header: {
    key: `${PATH}.header`,
    defaultValue: 'Certifications'
  },
  certificationName: {
    key: `${PATH}.certification_name`,
    defaultValue: 'Certification Name'
  },
  ageLevel: {
    key: `${PATH}.age_level`,
    defaultValue: 'Age Level'
  },
  expiresOn: {
    key: `${PATH}.expires_on`,
    defaultValue: 'Expires On'
  },
  status: {
    key: `${PATH}.status`,
    defaultValue: 'Status'
  },
  statusMessage: {
    transitioned: {
      key: `${PATH}.transitioned`,
      defaultValue: 'Transitioned'
    },
    renewed: {
      key: `${PATH}.renewed`,
      defaultValue: 'Renewed'
    },
    activeRenewable: {
      key: `${PATH}.active_renewable`,
      defaultValue: 'Active-Renewable'
    },
    expiredRenewable: {
      key: `${PATH}.expired_renewable`,
      defaultValue: 'Expired-Renewable'
    },
    expired: {
      key: `${PATH}.expired`,
      defaultValue: 'Expired'
    },
    active: {
      key: `${PATH}.active`,
      defaultValue: 'Active'
    }
  }
};

export default messages;
