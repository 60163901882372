import clsx from 'clsx';
import i18n from 'helpers/i18n';
import { useState } from 'react';
import messages from './messages';
import CoachAutocomplete from '../CoachAutoComplete';
import { PrimaryButton } from 'components/Organization/PrimaryButton';

type RolesType = {
  coaches: number;
  admins: number;
  educators: number;
};

interface AssignCoachModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAssign: (coachId: number) => void;
  roles: RolesType;
}

function AssignCoachModal({
  isOpen,
  onClose,
  onAssign,
  roles
}: AssignCoachModalProps) {
  const [coach, setCoach] = useState({ email: '', id: 0 });

  if (!isOpen) return null;

  function coachesAndAdminsSelected() {
    return roles.coaches > 0 || roles.admins > 0;
  }

  return (
    <div
      className={clsx(
        'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'
      )}
    >
      <div
        className={`bg-white rounded-lg shadow-lg p-6 relative w-3/6 ${coachesAndAdminsSelected() ? 'h-2/3' : 'h-3/5'} `}
      >
        <div className="flex justify-between items-center pb-2">
          <div className="text-2xl font-bold">{i18n.ft(messages.title)}</div>
          <button
            onClick={() => {
              setCoach({ email: '', id: 0 });
              onClose();
            }}
            className="hover:text-gray-800"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>

        {coachesAndAdminsSelected() ? (
          <div className="mt-10 bg-red-100 text-black px-4 py-3 rounded-lg relative mb-8 font-semibold mx-4">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-1 justify-self-center">
                <i className="fa-solid fa-circle-xmark text-red-500 fa-lg"></i>
              </div>
              <div className="col-span-11">
                <div className="">
                  {i18n.ft(messages.error, {
                    educators: roles.educators,
                    coaches: roles.coaches,
                    admins: roles.admins
                  })}
                </div>
                <div className="">{i18n.ft(messages.educatorsOnly)}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-10 mx-4">
            <p className="text-sm text-gray-600 mb-4">
              {i18n.ft(messages.description)}
            </p>
          </div>
        )}

        <div className="mx-4 mt-4">
          <CoachAutocomplete
            value={coach.email}
            handleChange={e => {
              setCoach({ email: e.email, id: e.id });
            }}
            onSelect={customer =>
              setCoach({ id: customer.id, email: customer.email })
            }
          />
        </div>

        <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2 z-0">
          <div className="py-4 flex justify-end">
            <PrimaryButton
              type="button"
              disabled={!coach.id || roles.educators === 0}
              className="disabled:bg-[#EBEFF4] disabled:cursor-not-allowed disabled:text-navy-200 disabled:pointer-events-none px-14"
              onClick={() => {
                onAssign(coach.id);
                setCoach({ email: '', id: 0 });
              }}
            >
              {i18n.ft(messages.assign)}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignCoachModal;
