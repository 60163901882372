import i18n from 'helpers/i18n';
import messages from './messages';
import { toastError } from 'components/Toast';
import useCurrentUser from 'hooks/useCurrentUser';
import { useState, useContext, useEffect, useCallback } from 'react';
import { LearningResourcesContext } from 'pages/LearningResources/LearningResourcesContext';
import RoutesHelper from 'helpers/routes';
import { useLocation } from 'react-router-dom';
import { ResourceType } from 'types/api/LearningResource';
import { Video } from '../types';

interface VideoResourceProps {
  handleClick: (id: number, type: ResourceType) => void;
  ariaRoleDescription: string;
  ariaLabel: string;
  video: Video;
  tags?: any;
  showAllResources?: boolean;
}

function VideoResource({
  handleClick,
  ariaRoleDescription,
  ariaLabel,
  video,
  tags
}: VideoResourceProps) {
  const { roles = [] } = useCurrentUser();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isChecked, setIsChecked] = useState(false);
  const learningResourcesContext = useContext(LearningResourcesContext);

  if (!learningResourcesContext) {
    throw new Error(
      'Learning Resources must be used within a VideoTagsProvider'
    );
  }

  const { recommendedVideosArray, setRecommendedVideosArray } =
    learningResourcesContext;

  const ageLevelsTags = tags?.filter((tag: any) => tag.group === 'age_group');

  const classVersionsTags = tags?.filter(
    (tag: any) => tag.group === 'class_version'
  );

  const resourceLanguage = tags?.filter(
    (tag: any) => tag.group === 'resource_language'
  );

  const isCheckedItem = useCallback(() => {
    return recommendedVideosArray.find(item => item.id === video.id);
  }, [recommendedVideosArray, video.id]);

  function isCoach() {
    return (
      roles.includes('lr_coach') &&
      currentPath.includes(RoutesHelper.getPath('coach-learning-resources'))
    );
  }

  useEffect(() => {
    setIsChecked(isCheckedItem() ? true : false);
  }, [isCheckedItem, recommendedVideosArray]);

  function handleChangeOnClick(e: any) {
    e.stopPropagation();
    if (e.target.type === undefined) {
      const itemFound = recommendedVideosArray.find(
        item => item.id === video.id
      );

      setIsChecked(prev => {
        let newCheckedStatus = prev;

        if (prev) {
          if (!!itemFound) {
            setRecommendedVideosArray([
              ...recommendedVideosArray.filter(item => item.id !== video.id)
            ]);
            newCheckedStatus = false;
          }
        } else {
          if (!!itemFound) {
            newCheckedStatus = prev;
            toastError({
              description: i18n.ft(messages.error)
            });
          } else {
            setRecommendedVideosArray([
              ...recommendedVideosArray,
              {
                name: video.name,
                id: video.id,
                videoURI: video.videoURI,
                estimatedTime: video.estimatedTime,
                type: video.type
              }
            ]);
            newCheckedStatus = true;
          }
        }

        return newCheckedStatus;
      });
    }
  }

  function handleCheckboxChange(e: any) {
    e.stopPropagation();
    if (e.target.type === 'checkbox') {
      setIsChecked(!e.target.checked);
    }
  }

  function getLanguageTag(tagName: string) {
    switch (tagName) {
      case 'spanish':
        return 'ES';
      case 'english_spanish':
        return 'EN & ES';
      default:
        return '';
    }
  }

  function renderClassVersions() {
    return classVersionsTags.map((classVersion: any, idx: number) => (
      <div
        key={idx}
        className="font-semibold text-black ml-4 text-sm leading-4 mr-2"
      >
        {classVersion.description}
      </div>
    ));
  }

  function renderAgeLevels() {
    return ageLevelsTags
      .map((ageLevel: any, idx: number) => (
        <span
          key={idx}
          className={`${
            classVersionsTags.length === 0 ? 'ml-4' : ''
          } bg-[#008FBF] font-semibold text-black mr-2 text-sm leading-4 rounded-md px-2 py-1 w-fit`}
        >
          {ageLevel.description}
        </span>
      ))
      .slice(0, 1);
  }

  function renderResourceLanguage() {
    return resourceLanguage
      .map(
        (language: any, idx: number) =>
          language.name !== 'english' && (
            <span
              key={idx}
              className={`${
                classVersionsTags.length === 0 && ageLevelsTags.length === 0
                  ? 'ml-4'
                  : ''
              } bg-[#008FBF] font-semibold text-black text-sm leading-4 rounded-md px-2 py-1 w-fit`}
            >
              {getLanguageTag(language.name)}
            </span>
          )
      )
      .slice(0, 1);
  }

  function renderCoachSection() {
    return (
      <div
        className="hover:bg-[#E6F5FA] text-right py-2 color=[#3C3F42] rounded-b-lg"
        onClick={handleChangeOnClick}
      >
        <span>
          {`${i18n.ft(messages.recommend)}`}
          <label
            className="checkbox-container inline-block relative pl-8 mb-3 cursor-pointer text-lg"
            htmlFor={`${video.id}`}
          >
            <input
              type="checkbox"
              id={`${video.id}`}
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="absolute opacity-0 cursor-pointer h-0 w-0"
            />
            <span
              className={`checkmark absolute top-0 left-0 h-3 w-3 ml-2 hover:bg-[#e6f5fa] border border-slate-500 ${
                isChecked
                  ? 'bg-[#0A9CCA] hover:bg-[#0A9CCA]'
                  : '!hover:bg-[#0A9CCA]'
              }`}
            >
              {isChecked && (
                <svg
                  viewBox="0 0 24 24"
                  className="w-full h-full fill-current text-white"
                >
                  <polyline
                    points="20 6 9 17 4 12"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              )}
            </span>
          </label>
        </span>
      </div>
    );
  }

  return (
    <div
      className={`min-w-[286px] rounded-lg h-fit cursor-pointer ${isChecked ? 'bg-[#E6F5FA] border-2 border-[#077699]' : 'bg-white'}`}
      style={{ boxShadow: '0px 6px 25px 0px #0000003D' }}
    >
      <div
        onClick={() => handleClick(video.id, video.type)}
        className={`${isCoach() ? '' : 'pb-4'}`}
      >
        <div
          className="snap-start flex flex-shrink-0  relative"
          aria-roledescription={ariaRoleDescription}
          aria-label={ariaLabel}
          tabIndex={0}
        >
          <img
            className={
              'w-full  max-w-full rounded-t-lg object-cover md:!h-[269px] lg:!h-60 2xl:!h-44'
            }
            src={`https://cdnsecakmi.kaltura.com/p/966871/thumbnail/entry_id/${video.videoURI}/width/600`}
            alt="Thumbnail"
          />
          <div className="mt-2 ml-2 absolute top-0 left-0 bg-white text-black px-2 py-1 rounded text-xs font-semibold">
            {video.estimatedTime}
          </div>
        </div>

        <div className="font-semibold my-3 text-black mx-4 text-lg line-clamp-2 leading-5 h-10">
          {video.name}
        </div>

        {/* Tags */}
        <div className={'flex items-center h-[22px] py-2 mb-2'}>
          {classVersionsTags && renderClassVersions()}
          {ageLevelsTags && renderAgeLevels()}
          {resourceLanguage && renderResourceLanguage()}
        </div>
      </div>

      {isCoach() && renderCoachSection()}
    </div>
  );
}

export default VideoResource;
