const PATH =
  'fe.pages.organization.view_subscription_users.tables.dc_users_table';

const messages = {
  invitationsSent: {
    key: `${PATH}.invitations_sent`,
    defaultValue: 'Invitations sent successfully'
  },
  unsubscribeSuccess: {
    key: `${PATH}.unsubscribe_success`,
    defaultValue: '{{count}} users were unsubscribed'
  },
  unsubscribeError: {
    key: `${PATH}.unsubscribe_error`,
    defaultValue: 'Error unsubscribing user(s). Please try again later.'
  },
  columns: {
    nameAndEmail: {
      key: `${PATH}.columns.name_and_email`,
      defaultValue: 'Name & Email'
    },
    role: {
      key: `${PATH}.columns.role`,
      defaultValue: 'Role'
    },
    status: {
      key: `${PATH}.columns.status`,
      defaultValue: 'Status'
    },
    invitedOn: {
      key: `${PATH}.columns.invited_on`,
      defaultValue: 'Invited On'
    }
  },
  actions: {
    resendInvite: {
      key: `${PATH}.actions.resend_invite`,
      defaultValue: 'Resend Invite ({{count}})'
    },
    unsubscribe: {
      key: `${PATH}.actions.unsubscribe`,
      defaultValue: 'Unsubscribe Users ({{count}})'
    },
    deselect: {
      key: `${PATH}.actions.deselect`,
      defaultValue: 'Deselect All'
    }
  }
};

export default messages;
