import axios from 'axios';
import i18n from 'helpers/i18n';
import messages from './messages';
import { clsx } from 'clsx';
import { useState } from 'react';
import { roleToText } from 'helpers/role';
import { getSubscriptionStatus } from 'actions/memberships';
import { Controller, useFormContext } from 'react-hook-form';
import { TextButton } from 'components/Organization/TextButton';
import { Select, SelectOption } from 'components/Organization/Select';
import EmailAutocomplete from 'components/Organization/EmailAutocomplete';

interface UserFormProps {
  /** Current account id. */
  accountId: number;

  /** Subscription id. */
  subscriptionId: number;

  /** Index of the form. */
  index: number;

  /** List of roles. */
  roles: SelectOption[];

  /** Callback to remove the form. */
  remove?: () => void;
}

export function InviteUserForm({
  accountId,
  subscriptionId,
  index,
  roles,
  remove
}: UserFormProps) {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    trigger
  } = useFormContext();

  const [subscriptionError, setSubscriptionError] = useState<string | null>(
    null
  );

  function checkSubscription(email: string) {
    if (!email) {
      return;
    }

    getSubscriptionStatus({
      email: email,
      subscription_id: subscriptionId
    })
      .then(response => {
        const { invite_status, name, role } = response.data;

        const statusActions = {
          invited: {
            error: i18n.ft(messages.errors.invited, {
              name,
              role: roleToText(role)
            }),
            setError: true
          },
          accepted: {
            error: i18n.ft(messages.errors.subscribed, {
              name,
              role: roleToText(role)
            }),
            setError: true
          },
          no_invitation: {
            error: i18n.ft(messages.errors.noInvitation, {
              name,
              role: roleToText(role)
            }),
            setError: true
          }
        };

        if (statusActions[invite_status]) {
          setSubscriptionError(statusActions[invite_status].error);
          if (statusActions[invite_status].setError) {
            setError(`members.${index}.email`, {
              type: 'manual'
            });
          }
        } else {
          setSubscriptionError(null);
          clearErrors(`members.${index}.email`);
        }
      })
      .catch(error => {
        axios.isAxiosError(error) && error.response?.status === 404
          ? setSubscriptionError(null)
          : setSubscriptionError(i18n.ft(messages.errors.error));
      });
  }

  function setUserRole(role: string | null) {
    clearErrors(`members.${index}.role`);
    setValue(`members.${index}.role`, role);
  }

  function handleChange(value: string, field: any) {
    field.onChange(value);
    setSubscriptionError(null);
    clearErrors(`members.${index}.email`);
  }

  function handleFocus() {
    setSubscriptionError(null);
    clearErrors(`members.${index}.email`);
  }

  async function handleBlur(field: any) {
    if (field.value) {
      const isValid = await trigger(`members.${index}.email`);

      if (!isValid) {
        setError(`members.${index}.email`, {
          type: 'manual',
          message: i18n.ft(messages.errors.email)
        });
      } else {
        checkSubscription(field.value);
      }
    }
  }

  function handleSelect(customer: any) {
    setValue(`members.${index}.email`, customer.email);
    checkSubscription(customer.email);
  }

  const handleClearForm = (index: number) => {
    setValue(`members.${index}.email`, '');
    setValue(`members.${index}.role`, '');
    setSubscriptionError(null);
  };

  function getFieldError(field: string) {
    return Array.isArray(errors.members) && errors.members[index]
      ? errors.members[index][field]?.message ?? ''
      : null;
  }

  return (
    <section>
      {subscriptionError && (
        <div className="font-sans font-semibold text-base text-[#3C3F42] px-4 py-3.5 bg-red-100 border-x border-t border-error rounded-t-2xl">
          <i className="fa-solid fa-circle-xmark text-error mr-2" />
          <span>{subscriptionError}</span>
        </div>
      )}
      <div
        className={clsx(
          'bg-white p-6 pt-3 rounded-2xl',
          subscriptionError && 'rounded-t-none border-b border-x border-error'
        )}
      >
        <div className="flex justify-end">
          {remove ? (
            <TextButton size="small" type="button" onClick={remove}>
              {i18n.ft(messages.deleteForm)}
              <i className="fa-solid fa-trash-can ml-2" />
            </TextButton>
          ) : (
            <TextButton
              size="small"
              type="button"
              onClick={() => handleClearForm(0)}
            >
              {i18n.ft(messages.clearForm)}
              <i className="fa-solid fa-close ml-2" />
            </TextButton>
          )}
        </div>

        <div className="grid gap-6 grid-cols-2">
          <Controller
            name={`members.${index}.email`}
            control={control}
            render={({ field }) => (
              <div>
                <EmailAutocomplete
                  accountId={accountId}
                  value={field.value}
                  onChange={value => handleChange(value, field)}
                  onFocus={handleFocus}
                  onBlur={() => handleBlur(field)}
                  onSelect={handleSelect}
                />
                {getFieldError('email') && (
                  <p className="font-sans text-red-500 text-sm mt-1 font-semibold">
                    {getFieldError('email')}
                  </p>
                )}
              </div>
            )}
          />
          <Controller
            name={`members.${index}.role`}
            control={control}
            render={({ field }) => (
              <div>
                <Select
                  value={field.value}
                  label={i18n.ft(messages.role)}
                  placeholder={i18n.ft(messages.rolePlaceholder)}
                  onChange={setUserRole}
                  options={roles}
                />
                {getFieldError('role') && (
                  <p className="font-sans text-red-500 text-sm mt-1 font-semibold">
                    {getFieldError('role')}
                  </p>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </section>
  );
}
