import moment from 'moment';
import settings from 'helpers/settings';
import { toSnakeCase } from 'helpers/text';
import { getAgeLevel } from 'helpers/ageLevel';
import { UserCertification } from 'types/api/UserCertification';
import { CoreCertification } from 'types/api/core/Certification';
import { findIndex, forEach, groupBy, propEq, sort } from 'ramda';

import {
  CertificationCardBase,
  CertificationCardProps
} from '../CertificationCard';

const LIMIT_DATE = settings.lookup('REACT_APP_K3_OBS_CERT_ISSUE_LIMIT_DATE');

function compareByKeyAsc(a: string, b: string): number {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
}

function compareByDateDesc(a: string, b: string): number {
  const aDate = moment(a);
  const bDate = moment(b);

  if (aDate.isBefore(bDate)) {
    return 1;
  } else if (aDate.isAfter(bDate)) {
    return -1;
  } else {
    return 0;
  }
}

type CertificationsByGroup = {
  coach: CertificationCardBase[];
  observer: CertificationCardBase[];
  teacher: CertificationCardBase[];
  trainer: CertificationCardBase[];
  observer_trainer: CertificationCardBase[];
};

export function mapUserCertToCardProps(
  userCert: UserCertification
): CertificationCardBase {
  const dataSource = 'onelogin';
  const certificationID = userCert.certification_id;
  const certificationName = userCert.certification.name;

  return {
    dataSource,
    dataSourceID: userCert.id,
    deprecated: userCert.deprecated,
    name: certificationName,
    startDate: userCert.start_date || '',
    endDate: userCert.end_date || '',
    ageLevels: userCert.certification.age_level_group.age_levels,
    groupName: userCert.certification.group_name.toLowerCase(),
    expired: userCert.expired,
    renewable: userCert.renewable,
    renewableOn: userCert.renewable_on,
    renewableUntil: userCert.renewable_until,
    renewableDays: userCert.renewable_days,
    certificationKey: `${certificationName}-${certificationID}-${dataSource}`,
    enrollment: userCert.enrollment,
    testPreparationTemplateId: userCert.test_preparation_template_id,
    currentAttemptId: userCert.current_attempt?.id,
    currentAttemptNumber: userCert.current_attempt?.attempt,
    transitionId: userCert.transition_to_id,
    transitionText: userCert.transition_text,
    ageLevelGroupCode: userCert.certification.age_level_group_code,
    authorized2008AgeLevels: userCert.authorized_2008_age_levels,
    displayName: userCert.certification.age_level_group.display_name,
    tags: userCert.tags
  };
}

export function mapCoreCertToCardProps(
  certification: CoreCertification
): CertificationCardBase {
  const dataSource = 'core';

  let certificationName = `CLASS 2008 Observer ${certification.age_level.name}`;
  const ageLevel = getAgeLevel(certification.age_level.abbreviation);
  const certifiedAt = moment(certification.date_certified);
  const pk3rd = getAgeLevel('PK3');

  if (
    certifiedAt.isSameOrAfter(LIMIT_DATE) &&
    ageLevel.abbreviation === 'K-3'
  ) {
    certificationName = `CLASS 2nd Edition Observer ${pk3rd.name}`;
  }

  return {
    dataSource,
    dataSourceID: certification.id,
    name: certificationName,
    startDate: certification.date_certified,
    endDate: certification.date_expired,
    ageLevels: [certification.age_level],
    groupName: 'observer',
    testScore: certification.test_score,
    expired: certification.expired,
    renewable: certification.renewable,
    renewableOn: certification.renewable_on,
    renewableUntil: certification.renewable_until,
    renewableDays: certification.renewable_days,
    certificationKey: `${certificationName}-${dataSource}`,
    ageLevelGroupCode: undefined,
    transitionText: null
  };
}

export function groupCertifications(certifications: CertificationCardBase[]) {
  const defaultGroups: CertificationsByGroup = {
    observer: [],
    coach: [],
    trainer: [],
    teacher: [],
    observer_trainer: []
  };

  const certificationsByGroup = groupBy(
    certification => toSnakeCase(certification.groupName),
    certifications
  );

  const {
    french_observer = [],
    observer = [],
    ...otherGroups
  } = certificationsByGroup;
  return {
    ...defaultGroups,
    ...otherGroups,
    observer: [...observer, ...french_observer]
  };
}

export function sortCertifications(certifications: CertificationCardBase[]) {
  const sortedByKeyAndDate = sort((a, b) => {
    return (
      compareByKeyAsc(a.certificationKey, b.certificationKey) ||
      compareByDateDesc(a.endDate, b.endDate)
    );
  }, certifications);

  let groupedById: CertificationCardProps[] = [];

  forEach(certification => {
    const elementIndex = findIndex(
      propEq('certificationKey', certification.certificationKey),
      groupedById
    );

    if (elementIndex >= 0) {
      groupedById[elementIndex].history.push(certification);
    } else {
      groupedById.push({ ...certification, history: [certification] });
    }
  }, sortedByKeyAndDate);

  return groupedById;
}

export function getRenewCount(certifications: CertificationCardBase[]): number {
  return certifications.reduce((count, cert) => {
    return cert.renewable && !cert.deprecated ? count + 1 : count;
  }, 0);
}
