// TODO: Implement Custom Popup component. Use semantic for now.
import { Popup as SemanticPopup } from 'semantic-ui-react';

interface PopupProps {
  /** Popup label. */
  label?: React.ReactNode;

  /** Popup trigger content. */
  children: React.ReactNode;
}

export function Popup({ label, children }: PopupProps) {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <SemanticPopup
      position="top center"
      className="before:!bg-[#3C3F42] before:!border-0 before:!shadow-none"
      style={{
        padding: '4px 8px',
        border: 0,
        boxShadow: 'none',
        background: '#3C3F42'
      }}
      content={<span className="font-sans text-sm text-white">{label}</span>}
      trigger={children}
    />
  );
}
