const PATH = 'fe.pages.tokens.view';

const messages = {
  pleaseWait: {
    key: `${PATH}.please_wait`,
    defaultValue: 'Please wait while we process your request.'
  },
  expiredLink: {
    key: `${PATH}.expired_link`,
    defaultValue:
      'This link is no longer valid. If you need help, please reach out to our customer support team by email: <a target="_blank" href="mailto:contact@teachstone.com">contact@teachstone.com</a> or by text: <a target="_blank" href="tel:+18669988352">866.998.8352</a>'
  },
  goBack: {
    key: `${PATH}.go_back`,
    defaultValue: 'Go back to myTeachstone'
  },
  product: {
    key: `${PATH}.product`,
    defaultValue: 'Product'
  },
  confirmButton: {
    key: `${PATH}.confirm_button`,
    defaultValue: 'Confirm'
  },
  confirmationText: {
    key: `${PATH}.confirmation_text`,
    defaultValue:
      'Do you confirm the activation of this product for the following user: <strong>{{email}}</strong>?'
  }
};

export default messages;
