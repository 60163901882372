import i18n from 'helpers/i18n';
import messages from './messages';
import { toTitleCase } from 'helpers/text';
import { Table } from 'components/Organization/Table';
import {
  useGetCAUserTrainings,
  UserTrainingsFramework,
  UserTrainingsType
} from 'actions/trainings';

interface TrainingsTableProps {
  userId: number;
}

export function TrainingsTable({ userId }: TrainingsTableProps) {
  const { data, isLoading } = useGetCAUserTrainings(userId);
  const trainings = data ?? [];

  function getTrainingType(type: UserTrainingsType) {
    switch (type) {
      case 'cgc_transition_trainer':
        return i18n.ft(messages.typeMessage.cgcTransitionTainer);
      case 'ie_trainer':
        return i18n.ft(messages.typeMessage.ieTrainer);
      case 'observer':
        return i18n.ft(messages.typeMessage.observer);
      case 'observer_trainer':
        return i18n.ft(messages.typeMessage.observer_trainer);
      case 'transition_observer':
        return i18n.ft(messages.typeMessage.transition_observer);
      case 'transition_trainer':
        return i18n.ft(messages.typeMessage.transition_trainer);
      default:
        return i18n.ft(messages.typeMessage.undefined);
    }
  }

  function getFramework(framework: UserTrainingsFramework) {
    switch (framework) {
      case 'first_edition':
        return i18n.ft(messages.frameworkMessage.firstEdition);
      case 'second_edition':
        return i18n.ft(messages.frameworkMessage.secondEdition);
      case 'class_version_neutral':
        return i18n.ft(messages.frameworkMessage.classVersionNeutral);
      default:
        return i18n.ft(messages.frameworkMessage.undefined);
    }
  }

  const rows = trainings.map(training => ({
    title: toTitleCase(training.title),
    type: getTrainingType(training.type),
    age_level_group: training.age_level_group,
    framework: getFramework(training.framework),
    language: training.language
  }));

  return (
    <div className="font-sans bg-white p-6 rounded-2xl mt-6">
      <div className="font-bold text-xl md:text-2xl">
        {i18n.ft(messages.header)}
      </div>

      <div className="mt-10">
        <Table
          isLoading={isLoading}
          headers={[
            {
              accessor: 'title',
              content: i18n.ft(messages.title)
            },
            { accessor: 'type', content: i18n.ft(messages.type) },
            {
              accessor: 'age_level_group',
              content: i18n.ft(messages.ageLevelGroup)
            },
            { accessor: 'framework', content: i18n.ft(messages.framework) },
            { accessor: 'language', content: i18n.ft(messages.language) }
          ]}
          data={rows}
        />
      </div>
    </div>
  );
}
