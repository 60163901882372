import i18n from 'helpers/i18n';
import messages from './messages';
import { TextButton } from 'components/Organization/TextButton';
import { PrimaryButton } from 'components/Organization/PrimaryButton';

interface LeaveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const LeaveModal = ({ isOpen, onClose, onLeave }: LeaveModalProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white font-sans text-black shadow-lg rounded-lg p-4 w-64">
        <h2 className="font-sans font-bold text-xl">
          {i18n.ft(messages.title)}
        </h2>
        <p className="text-base mt-2 mb-8">{i18n.ft(messages.content)}</p>
        <div className="flex flex-col gap-3">
          <PrimaryButton onClick={onLeave}>
            {i18n.ft(messages.yes)}
          </PrimaryButton>

          <TextButton onClick={onClose}>{i18n.ft(messages.no)}</TextButton>
        </div>
      </div>
    </div>
  );
};

export default LeaveModal;
